import { useState } from "react";
import "./formulamethods-styles.scss";
import AddFormulaMethod from "../add-formula-method/AddFormulaMethod.component";
import FormulaMethodList from "../formula-methods-list/FormulaMethodList.component";
//Material Ui
import Avatar from "@mui/material/Avatar";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

export default function FormulaMethods({
	nextStep,
	prevStep,
	formula,
	setFormula,
}) {
	const [updt, setUpdt] = useState(0);

	return (
		<div className="method-wrapper">
			<div className="title">
				<Avatar className="avatar">3</Avatar>
				<div className="subtitle">Formulation Methods</div>
			</div>
			<AddFormulaMethod
				updt={updt}
				setUpdt={setUpdt}
				formula={formula}
				setFormula={setFormula}
			/>
			{formula.method.length > 0 ? (
				<FormulaMethodList
					updt={updt}
					setUpdt={setUpdt}
					formula={formula}
					setFormula={setFormula}
					edit={true}
				/>
			) : null}
			<div className="stepper-buttons">
				<button onClick={prevStep} className="submit">
					<ArrowBackIosIcon /> Back
				</button>
				<button onClick={nextStep} className="submit">
					Next <ArrowForwardIosIcon />
				</button>
			</div>
		</div>
	);
}
