import axios from "axios";
import FromulaActionTypes from "./formula.action.types";

const initialFormula = {
	id: 0,
	name: "",
	ndc: "COMP",
	productType: "",
	dosage: "",
	bud: "0",
	storage: "",
	labels: [],
	packing: "",
	final: "",
	quality: "",
	milling: "N",
	ingridients: [],
	method: [],
	consider: [],
	type: "",
	freq: "",
};

//POST Helper
const _AddToFormula = (procName, params) => {
	const url =
		"https://c99jtoge7d.execute-api.us-east-2.amazonaws.com/dev/formula";

	const data = { procName, params };
	// console.log("data==>", JSON.stringify(data));
	return new Promise((resolve, reject) => {
		axios
			.post(url, JSON.stringify(data))
			.then(function (resp) {
				// console.log("resp: ", resp);
				resolve(resp);
			})
			.catch(function (err) {
				console.error("Problem with Add: ", err);
				reject(err);
			});
	});
};

//GET Helper
const _GetFormula = (procName, params) => {
	const url = `https://c99jtoge7d.execute-api.us-east-2.amazonaws.com/dev/formula?procName=${procName}&params=${params}`;

	return new Promise((resolve, reject) => {
		axios
			.get(url)
			.then(function (resp) {
				// console.log("resp: ", resp);
				resolve(resp);
			})
			.catch(function (err) {
				console.error("Problem with Get: ", err);
				reject(err);
			});
	});
};

export const fetchFormulaStart = () => ({
	type: FromulaActionTypes.FETCH_FORMULA_START,
});

export const fetchFormulaSuccess = (formula) => ({
	type: FromulaActionTypes.FETCH_FORMULA_SUCCESS,
	payload: formula,
});

export const fetchFormula = (ndc) => {
	var formula = {};
	const url =
		"https://c99jtoge7d.execute-api.us-east-2.amazonaws.com/dev/formula?procName=GET_FORMULA&params=" +
		encodeURIComponent(ndc);
	return (dispatch) => {
		const request = axios({
			method: "GET",
			url,
		});
		dispatch(fetchFormulaStart());
		// console.log("Formula Name: ", url);
		request
			.then(async (res) => {
				// console.log("GET API: ", JSON.stringify(res.data.result));
				if (res.data.result.length === 0) {
					dispatch({
						type: FromulaActionTypes.FETCH_FORMULA_NOT_FOUND,
						payload: "NOT FOUND",
					});
				} else {
					const {
						ID,
						EFF_DATE,
						NAME,
						NDC,
						PRODUCT_TYPE,
						SIG,
						ESTIMATED_BUD,
						STORAGE_CONDITIONS,
						AUXILARY_LABELS,
						PACKAGING,
						DESCR_FINAL_PRODUCT,
						QUALITY_CONTROL,
						MILLING,
						TYPE,
						FREQUENCY,
					} = res.data.result[0];
					formula.id = ID;
					formula.name = NAME;
					formula.ndc = NDC;
					formula.productType = PRODUCT_TYPE;
					formula.dosage = SIG;
					formula.bud = ESTIMATED_BUD;
					formula.storage = STORAGE_CONDITIONS;
					formula.labels = AUXILARY_LABELS;
					formula.packing = PACKAGING;
					formula.final = DESCR_FINAL_PRODUCT;
					formula.quality = QUALITY_CONTROL;
					formula.milling = MILLING;
					formula.ingridients = [];
					formula.method = [];
					formula.type = TYPE;
					formula.freq = FREQUENCY;
					let procName = "GET_FORMULA_INGRIDS";
					let params = ID;
					const ingrids = await _GetFormula(procName, params);
					formula.ingridients = ingrids.data.result;
					procName = "GET_FORMULA_METHODS";
					const methods = await _GetFormula(procName, params);
					formula.method = methods.data.result;
					dispatch(fetchFormulaSuccess(formula));
				}
			})
			.catch((err) => {
				dispatch({
					type: FromulaActionTypes.FETCH_FORMULA_FAILURE,
					payload: err,
				});
			});
	};
};

export const deleteFromula = () => ({
	type: FromulaActionTypes.DELETE_FORMULA,
});

export const addFormula = (formula) => ({
	type: FromulaActionTypes.ADD_FORMULA,
	payload: formula,
});

export const formulaError = (error) => ({
	type: FromulaActionTypes.FORMULA_ERROR,
	payload: error,
});

export const formulaNotFound = (error) => ({
	type: FromulaActionTypes.FETCH_FORMULA_NOT_FOUND,
	payload: error,
});

export const FormulaDbAdd = (formula) => {
	return (dispatch) => {
		dispatch(fetchFormulaStart());
		const url =
			"https://c99jtoge7d.execute-api.us-east-2.amazonaws.com/dev/formula";

		const {
			name,
			ndc,
			productType,
			dosage,
			bud,
			storage,
			labels,
			packing,
			final,
			quality,
			milling,
			ingridients,
			method,
			type,
			freq,
		} = formula;

		var params = [
			name,
			ndc,
			productType,
			dosage,
			bud,
			storage,
			labels,
			packing,
			final,
			quality,
			milling,
			type,
			freq,
		];
		let procName = "ADD_MASTER_FORMULA";
		let data = { procName, params };
		console.log("API Data: ", data);
		axios
			.post(url, JSON.stringify(data))
			.then(function (resp) {
				// console.log("resp: ", resp.data.body);
				// console.log("result: ", resp.data.result);
				if (resp.data.body === '"Success"' && !resp.data.result) {
					// console.log("Success!!");
					ingridients.map((ingridient) => {
						var qsad = "";
						var apis = "";
						ingridient.qsad ? (qsad = "Y") : (qsad = "N");
						ingridient.apis ? (apis = "Y") : (apis = "N");
						let params = [
							name,
							ingridient.ndc,
							ingridient.amount,
							ingridient.unitOfMesure,
							apis,
							qsad,
							ingridient.consider,
						];
						let procName = "ADD_FORMULA_INGRIDS";
						_AddToFormula(procName, params);
					});

					method.map(async (meth) => {
						let params = [name, meth.method, meth.descr];
						let procName = "ADD_FORMULA_METHODS";
						_AddToFormula(procName, params);
					});
					alert(`Formula ${name} was added successfully !`);
					dispatch(fetchFormulaSuccess(initialFormula));

					return resp;
				} else console.log("Error");
				alert(JSON.stringify(resp.data.result));
			})
			.catch(function (err) {
				console.error("Problem with Add: ", err);
			});
	};
};
