import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ChangeUserPassword, getSession } from "../../utils/aws-cognito.js";
import { useSelector } from "react-redux";
//Material Ui
import { SaveOutlined, Visibility } from "@mui/icons-material";

import "./changepass.styles.scss";

export default function UserChangePassword() {
	const [password, setPassword] = useState("");
	const [newPassword, setNewPassword] = useState("");
	const [confirmPass, setConfirmPass] = useState("");
	const navigate = useNavigate();
	const user = useSelector((state) => state.user.user);
	const [passwordShown, setPasswordShown] = useState(false);

	const togglePassword = () => {
		setPasswordShown(!passwordShown);
	};

	const handelSubmit = (e) => {
		e.preventDefault();
		if (confirmPass !== newPassword) {
			alert("Invalid password - Passwords do not match!");
			return;
		}
		getSession()
			.then(({ user, email }) =>
				ChangeUserPassword(user, password, newPassword)
					.then((result) => {
						alert("Success! Your Password has been changed!");
						navigate("login", { replace: true });
					})
					.catch((err) => {
						console.error(err);
						alert(`${err.message} ${err.name}`);
					})
			)
			.catch((err) => {
				console.error(err);
				alert(`${err.message} ${err.name}`);
			});
	};

	return (
		<div className="password-wrapper">
			<div className="pass-title">
				<span className="subtitle">Cange password</span>
			</div>
			<form onSubmit={handelSubmit} className="password-form">
				<div className="form-input">
					<label htmlFor="curr-password">Current password: </label>
					<div className="item">
						<input
							required
							id="curr-password"
							autoFocus
							className="pass-input"
							type={passwordShown ? "text" : "password"}
							value={password}
							onChange={(e) => setPassword(e.target.value)}
						/>
						<button className="visIcon" onClick={togglePassword}>
							<Visibility />
						</button>
					</div>
				</div>
				<div className="form-input">
					<label htmlFor="new-password">New password:</label>
					<div className="item">
						<input
							required
							className="pass-input"
							id="new-password"
							type={passwordShown ? "text" : "password"}
							value={newPassword}
							onChange={(e) => setNewPassword(e.target.value)}
						/>
						<button className="visIcon" onClick={togglePassword}>
							<Visibility />
						</button>
					</div>
				</div>
				<div className="form-input">
					<label htmlFor="conf-password">Confirm password:</label>
					<div className="item">
						<input
							required
							className="pass-input"
							id="conf-password"
							type={passwordShown ? "text" : "password"}
							value={confirmPass}
							onChange={(e) => setConfirmPass(e.target.value)}
						/>
						<button className="visIcon" onClick={togglePassword}>
							<Visibility />
						</button>
					</div>
				</div>
				<div className="save">
					<button className="save-button" type="submit">
						<SaveOutlined className="saveIcon" /> Change password
					</button>
				</div>
			</form>
		</div>
	);
}
