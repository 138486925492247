import { useRef } from "react";
import ReactToPrint from "react-to-print";
import "./printbutton-styles.scss";
import Button from "@mui/material/Button";
import PrintIcon from "@mui/icons-material/Print";

export default function PrinterLabelWrapper({ children }) {
	const pageStyle =
		"@page { size: 2.5in 4in } @media all { .pagebreak {display: none;}} @media print {.pagebreak {page-break-before: always;}}";

	const linkToPrint = () => {
		return (
			<Button variant="outlined" className="printButton">
				<PrintIcon /> Print
			</Button>
		);
	};
	const componentRef = useRef();
	return (
		<>
			<div ref={componentRef}>{children}</div>
			<ReactToPrint
				trigger={linkToPrint}
				pageStyle={pageStyle}
				content={() => componentRef.current}
			/>
		</>
	);
}
