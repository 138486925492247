import { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "./listuser-styles.scss";
import TablePaginationActions from "../table-paginatoin/TablePagination.component";
import { Link } from "react-router-dom";
//Material Ui
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { TableFooter } from "@mui/material";
import TablePagination from "@mui/material/TablePagination";
import { IconButton } from "@mui/material";
import { Edit, Delete, Add } from "@mui/icons-material";

export default function ListUsers() {
	const navigate = useNavigate();
	const [data, setData] = useState([]);
	const [id, setId] = useState("");
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(5);
	const url = `https://c99jtoge7d.execute-api.us-east-2.amazonaws.com/dev?procName=GET_ALL_USERS&params=1`;

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const handleEdit = (e, id) => {
		e.preventDefault();
		setId(id);
		navigate(`../edit/${id}`, { replace: false });
	};

	const handleDelete = (e, id) => {
		e.preventDefault();
		setId(id);
		// setOpen(true);
		console.log("Delete");
	};

	useEffect(() => {
		const request = axios({
			method: "GET",
			url,
		});
		request
			.then((res) => {
				if (!res) {
					alert("No user data found!!!");
				} else {
					setData(res.data.result);
					console.log("user data => ", res.data.result);
				}
			})
			.catch((err) => {
				console.error(err);
			});
	}, []);

	return (
		<div className="datatable">
			<div className="datatableTitle">
				<Link to="/users/new" className="link">
					<Add /> Add New
				</Link>
			</div>
			<div className="table">
				<Table>
					<TableHead className="header">
						<TableRow className="headerRow">
							<TableCell className="headerCell" align="center">
								Email
							</TableCell>
							<TableCell align="center" className="headerCell">
								First Name
							</TableCell>
							<TableCell align="center" className="headerCell">
								Last Name
							</TableCell>
							<TableCell align="center" className="headerCell">
								Phone
							</TableCell>
							<TableCell align="center" className="headerCell">
								Role
							</TableCell>
							<TableCell align="center" className="headerCell">
								Action
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{(rowsPerPage > 0
							? data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
							: data
						).map((user, index) => (
							<TableRow key={index} className="tableRow">
								<TableCell
									component="th"
									align="center"
									scope="row"
									className="cell"
								>
									{user.EMAIL}
								</TableCell>
								<TableCell
									component="th"
									align="center"
									scope="row"
									className="cell"
								>
									{user.FIRST_NAME}
								</TableCell>
								<TableCell
									component="th"
									align="center"
									scope="row"
									className="cell"
								>
									{user.LAST_NAME}
								</TableCell>
								<TableCell
									component="th"
									align="center"
									scope="row"
									className="cell"
								>
									{user.PHONE}
								</TableCell>
								<TableCell
									component="th"
									align="center"
									scope="row"
									className="cell"
								>
									{user.ROLE_NAME}
								</TableCell>
								<TableCell
									component="th"
									align="center"
									scope="row"
									className="cell"
								>
									<IconButton
										aria-label="Edit"
										color="primary"
										onClick={(e) => handleEdit(e, user.ID)}
									>
										<Edit />
									</IconButton>

									<IconButton
										aria-label="Delete"
										className="delete"
										onClick={(e) => {
											handleDelete(e, user.ID);
										}}
									>
										<Delete />
									</IconButton>
								</TableCell>
							</TableRow>
						))}
					</TableBody>

					<TableFooter>
						<TableRow className="pagination">
							<TablePagination
								rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
								colSpan={3}
								count={data.length}
								rowsPerPage={rowsPerPage}
								page={page}
								SelectProps={{
									inputProps: {
										"aria-label": "rows per page",
									},
									native: true,
								}}
								onPageChange={handleChangePage}
								onRowsPerPageChange={handleChangeRowsPerPage}
								ActionsComponent={TablePaginationActions}
							/>
						</TableRow>
					</TableFooter>
				</Table>
			</div>
		</div>
	);
}
