import { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Loading from "../loading-component/Loading.component";
import "./batchreportdt-styles.scss"; //Style sheet
//Material UI
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import SearchIcon from "@mui/icons-material/Search";

export default function BatchReportByDt() {
	const navigate = useNavigate();
	const [batchArray, setBatchArray] = useState([]);
	const [rptDate, setRptDate] = useState("");
	const [loading, setLoading] = useState(false);
	const zeroPad = (num, places) => String(num).padStart(places, "0");

	const handleBatchSearch = () => {
		setLoading(true);
		const url = `https://c99jtoge7d.execute-api.us-east-2.amazonaws.com/dev/batch?procName=GET_BATCH_COMPLETE_BY_DT&params=${rptDate}`;
		const request = axios({
			method: "GET",
			url,
		});
		request
			.then(async (res) => {
				console.log("GET API: ", JSON.stringify(res.data.result));
				setBatchArray(res.data.result);
				if (res.data.result.length === 0) {
					console.log("No Batches found");
					setLoading(false);
				} else {
					setBatchArray(res.data.result);
					setLoading(false);
				}
			})
			.catch((err) => {
				alert(`Sever db error ${err}`);
				setLoading(false);
			});
	};

	const handleClick = (event, id) => {
		event.preventDefault();
		navigate(`../batch-cmplt/${id}`, {
			repalce: false,
			state: { fromDashboard: true },
		});
	};

	return (
		<div className="batchRptDtWraper">
			<div className="searchWrapper">
				<input
					type="date"
					className="srchDt"
					id="rptDate"
					required
					value={rptDate}
					onChange={(e) => {
						setRptDate(e.target.value);
					}}
				/>
				<button onClick={handleBatchSearch} className="srchBtn">
					<SearchIcon />
					Search
				</button>
			</div>
			{
				<Table className="batchRptTbl">
					<TableHead className="batchRptHdr">
						<TableRow>
							<TableCell className="batchHdrCell">Batch Lot</TableCell>
							<TableCell className="batchHdrCell">Formula</TableCell>
							<TableCell className="batchHdrCell">Quantity</TableCell>
							<TableCell className="batchHdrCell">
								Date/Time Completed
							</TableCell>
							<TableCell className="batchHdrCell">Minutes to Make</TableCell>
						</TableRow>
					</TableHead>

					<TableBody>
						{loading ? (
							<Loading />
						) : (
							batchArray.map((batch, index) => (
								<TableRow
									key={index}
									hover
									onClick={(event) => handleClick(event, batch.ID)}
									className="batchRptRow"
								>
									<TableCell>
										{`${batch.BATCH_DATE}@${zeroPad(batch.SEQ, 2)}`}
									</TableCell>
									<TableCell align="left">{batch.NAME}</TableCell>

									<TableCell component="th" align="left" scope="row">
										{batch.QTY}
									</TableCell>
									<TableCell component="th" align="left" scope="row">
										{batch.UPDT_TIMESTAMP}
									</TableCell>
									<TableCell component="th" align="left" scope="row">
										{batch.MINS_MAKE} minutes
									</TableCell>
								</TableRow>
							))
						)}
					</TableBody>
				</Table>
			}
		</div>
	);
}
