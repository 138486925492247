import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "./batchcompletequeue-styles.scss"; // Style sheet
import Loading from "../loading-component/Loading.component";
import "./batchcompletequeue-styles.scss"; //Style sheet
//Materila Ui
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";

export default function BatchCompleteQueue() {
	const navigate = useNavigate();
	const [loading, setLoading] = useState(false);
	const [batchArray, setBatchArray] = useState([]);

	const zeroPad = (num, places) => String(num).padStart(places, "0");

	const handleClick = (event, id) => {
		event.preventDefault();
		navigate(`../batch-cmplt/${id}`, { repalce: false });
	};

	useEffect(() => {
		setLoading(true);
		const url = `https://c99jtoge7d.execute-api.us-east-2.amazonaws.com/dev/rx?procName=GET_LABELED_BATCH`;
		const request = axios({
			method: "GET",
			url,
		});
		request.then(async (res) => {
			setBatchArray(res.data.result);
			if (res.data.result.length === 0) {
				console.log("No Batches found");
				setLoading(false);
			} else {
				setBatchArray(res.data.result);
				setLoading(false);
			}
		});
		return setLoading(false);
	}, []);

	return (
		<div className="completeQueueWrapper">
			<div className="title">
				<span className="subtitle">
					Please select a batch to complete by clicking on a corresponding row
					in the table below.
				</span>
			</div>
			{loading ? (
				<Loading />
			) : (
				<>
					<Table className="cmpltBatchTbl">
						<TableHead className="cmpltBatchHdr">
							<TableRow>
								<TableCell className="cmplHdrCell">Formula</TableCell>
								<TableCell className="cmplHdrCell">Batch Lot</TableCell>
								<TableCell className="cmplHdrCell">Quantity</TableCell>
								<TableCell className="cmplHdrCell">Date Created</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{batchArray.map((batch, index) => (
								<TableRow
									key={index}
									hover
									onClick={(event) => handleClick(event, batch.ID)}
									className="cmpltRow"
								>
									<TableCell component="th" align="left" scope="row">
										{batch.NAME}
									</TableCell>
									<TableCell component="th" scope="row">
										{`${batch.BATCH_DATE}@${zeroPad(batch.SEQ, 2)}`}
									</TableCell>

									<TableCell component="th" align="left" scope="row">
										{batch.QTY}
									</TableCell>
									<TableCell component="th" align="left" scope="row">
										{batch.TIMESTAMP}
									</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</>
			)}
		</div>
	);
}
