import {
	MarkunreadMailboxRounded,
	QueueOutlined,
	Storefront,
	LocalMall,
	AddShoppingCart,
	Assessment,
	Event,
	EventNote,
	AllInbox,
} from "@mui/icons-material";

const inventcategories = [
	{
		id: "Inventory managment",
		children: [
			{
				id: "Create Requisition",
				icon: <AddShoppingCart />,
				link: "../add-req",
				resource: 405,
				active: true,
			},
			{
				id: "Add Purchase Order",
				icon: <LocalMall />,
				link: "../add-items-po",
				resource: 406,
			},
			{
				id: "Receive Inventory",
				icon: <MarkunreadMailboxRounded />,
				link: "../rec-inventory",
				resource: 402,
			},
		],
	},
	{
		id: "Setup",
		children: [
			{
				id: "Inventory items",
				icon: <QueueOutlined />,
				link: "../items",
				resource: 401,
			},

			{
				id: "Vendor",
				icon: <Storefront />,
				link: "../vendors",
				resource: 407,
			},
			{
				id: "On Hand inventory",
				icon: <AllInbox />,
				link: "../items/qty",
				resource: 408,
			},
		],
	},
	{
		id: "Reports",
		children: [
			{
				id: "Purchase history",
				icon: <Assessment />,
				link: "../po-history",
				resource: 411,
			},

			{
				id: "Item purchase history",
				icon: <Event />,
				link: "../item-history",
				resource: 412,
			},
			{
				id: "Vendor purchase history",
				icon: <EventNote />,
				link: "../vendor-history",
				resource: 413,
			},
		],
	},
];

export default inventcategories;
