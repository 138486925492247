import { useEffect, useState } from "react";
import axios from "axios";
import BatchPackingRow from "../batch-packing-row/BatchPackingRow.component";
import "./batchpackingtbl-styles.scss"; //Style sheet
//Material UI
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";

export default function BatchPackingTbl({
	id,
	updCntr,
	setUpdtCntr,
	arrayPack,
	setArrayPack,
}) {
	const [data, setData] = useState([]);

	useEffect(() => {
		const url = `https://c99jtoge7d.execute-api.us-east-2.amazonaws.com/dev?procName=GET_RX_PACKING_BYID&params=${id}`;

		const request = axios({
			method: "GET",
			url,
		});
		request
			.then((res) => {
				if (!res) {
					alert("No item data found!!!");
				} else {
					setData(res.data.result);
					console.log("Packs: ", res.data.result);
				}
			})
			.catch((err) => {
				console.error(err);
			});
	}, [id]);

	return (
		<>
			<Table className="batchPackTbl">
				<TableHead className="batchPackTblHdr">
					<TableRow>
						<TableCell className="batchPackHdrCell">Pack No</TableCell>
						<TableCell className="batchPackHdrCell">Target Weight</TableCell>
						<TableCell className="batchPackHdrCell">Actual weight</TableCell>
						<TableCell className="batchPackHdrCell">Action</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{data.map((w, index) => (
						<BatchPackingRow
							key={index}
							index={index}
							row={w}
							length={data.length}
							updCntr={updCntr}
							setUpdtCntr={setUpdtCntr}
							arrayPack={arrayPack}
							setArrayPack={setArrayPack}
						/>
					))}
				</TableBody>
			</Table>
		</>
	);
}
