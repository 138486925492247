import { useState } from "react";
import "./batchrx-styles.scss"; //Stylesheet
//Material Ui
import AddCircleOutlinedIcon from "@mui/icons-material/AddCircleOutlined";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

export default function BatchRxRecords({ rxArr, setRxArr, setTotalAmount }) {
	const [rx, setRx] = useState("");
	const [targetWgt, setTargetWgt] = useState("");

	const handleTotal = (arr) => {
		let r = arr.reduce((accumulator, currentValue) => {
			return accumulator + Number(currentValue.weight);
		}, 0);
		return r;
	};

	const addPack = (e) => {
		e.preventDefault();
		let arr = rxArr;
		let seq = rxArr.length + 1;
		arr.push({ seq, rx, weight: targetWgt });
		setRxArr(arr);
		setTotalAmount(handleTotal(arr));
		setRx("");
		setTargetWgt("");
	};

	return (
		<div className="batch-rx-wrapper">
			<div className="title">
				<span className="header">Batch Rx records</span>
				<span className="subheader">
					To create a product for the future, please use Rx Number - 999999
				</span>

				<form onSubmit={addPack}>
					<div className="rxInfo">
						<label htmlFor="totalAmount">Rx Number: </label>
						<input
							type="text"
							id="totalAmount"
							className="rx"
							required
							autoFocus
							value={rx}
							onChange={(e) => setRx(e.target.value)}
						/>
					</div>
					<div className="rxInfo">
						<label htmlFor="totalAmount">Weight: </label>
						<input
							type="text"
							className="rx"
							id="weight"
							required
							value={targetWgt}
							onChange={(e) => setTargetWgt(e.target.value)}
						/>
					</div>
					<button type="submit" className="addRx">
						<AddCircleOutlinedIcon /> Add
					</button>
				</form>

				{rxArr.length > 0 ? (
					<Table className="rxTable" aria-label="Batch Rx">
						<TableHead>
							<TableRow>
								<TableCell className="rxHdr">Rx Number</TableCell>
								<TableCell className="rxHdr">Rx Weight</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{rxArr.map((rx, index) => (
								<TableRow key={index}>
									<TableCell className="rxNo">{rx.rx}</TableCell>
									<TableCell className="rxWght">{rx.weight}g</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				) : null}
			</div>
		</div>
	);
}
