import "./vendorform-styles.scss";
import { listOfStates } from "../list-of-states/list-of-states";
import InputMask from "react-input-mask";

export default function VendorForm(props) {
	const {
		edit,
		name,
		setName,
		address,
		setAddress,
		city,
		setCity,
		state,
		setState,
		zip,
		setZip,
		phone,
		setPhone,
		email,
		setEmail,
		contact,
		setContact,
		account,
		setAccount,
		terms,
		setTerms,
		handleSave,
	} = props;

	return (
		<>
			<form onSubmit={handleSave} className="form">
				<div className="formInput">
					<label>Vendor Name:</label>
					<input
						type="text"
						className="form-field"
						value={name}
						required
						disabled={edit}
						onChange={(e) => setName(e.target.value)}
					/>
				</div>
				<div className="formInput">
					<label>Vendor address:</label>
					<input
						type="text"
						className="form-field"
						value={address}
						onChange={(e) => setAddress(e.target.value)}
					/>
				</div>
				<div className="formInput">
					<label>Vendor city:</label>
					<input
						type="text"
						className="form-field"
						value={city}
						onChange={(e) => setCity(e.target.value)}
					/>
				</div>

				<div className="selectCntrl">
					<label htmlFor="state-select">Select state:</label>

					<select
						id="state-select"
						className="select"
						value={state}
						onChange={(e) => setState(e.target.value)}
					>
						<option value="">--Please choose state--</option>

						{listOfStates.map((state) => (
							<option key={state.code} value={state.code}>
								{state.name}
							</option>
						))}
					</select>
				</div>

				<div className="formInput">
					<label>Vendor zip:</label>
					<input
						type="text"
						className="form-field-zip"
						value={zip}
						onChange={(e) => setZip(e.target.value)}
					/>
				</div>
				<div className="formInput">
					<label>Vendor contact:</label>
					<input
						type="text"
						className="form-field"
						value={contact}
						onChange={(e) => setContact(e.target.value)}
					/>
				</div>
				<div className="formInput">
					<label>Vendor phone:</label>
					<InputMask
						mask="(999)999-9999"
						value={phone}
						onChange={(e) => setPhone(e.target.value)}
					>
						<input type="text" className="form-field-zip" />
					</InputMask>
				</div>
				<div className="formInput">
					<label>Email:</label>
					<input
						type="text"
						className="form-field"
						value={email}
						onChange={(e) => setEmail(e.target.value)}
					/>
				</div>

				<div className="formInput">
					<label>Vendor account:</label>
					<input
						type="text"
						className="form-field"
						value={account}
						onChange={(e) => setAccount(e.target.value)}
					/>
				</div>
				<div className="selectCntrl">
					<label htmlFor="term-select">Payment terms:</label>

					<select
						id="term-select"
						className="select"
						value={terms}
						required
						onChange={(e) => setTerms(e.target.value)}
					>
						<option value="">--Please choose terms--</option>

						<option value={"COD"}>COD</option>
						<option value={"15 Days"}>15 Days</option>
						<option value={"30 Days"}>30 Days</option>
						<option value={"45 Days"}>45 Days</option>
						<option value={"90 Days"}>90 Days</option>
					</select>
				</div>
				<div className="save">
					<button type="submit" className="save-button">
						Save
					</button>
				</div>
			</form>
		</>
	);
}
