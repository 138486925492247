import "./batchlabel-styles.scss";
import Barcode from "react-barcode";

export default function BatchLabel({ batch }) {
	let weight = Number(batch.pumps) * Number(batch.JAR);
	return (
		<div className="print">
			<Barcode value={batch.lot} width={1} height={30} fontSize={14} />
			<p className="lblName">{batch.NAME}</p>
			<p>Lot: {batch.lot}</p>
			<p># of Pumps: {batch.pumps}</p>
			<p>Weight of compound + jar(50g/70g) = {weight} g</p>
			<p>Mix Time: {batch.MIX_SETTINGS}</p>
		</div>
	);
}
