import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import InventoryItemDetail from "../inventory-item-details/InventoryItemDetail.component";

import "./edit-invnetory-styles.scss";

export default function EditInventoryItem() {
	const { id } = useParams();
	const navigate = useNavigate();
	const [ndc, setNdc] = useState("");
	const [brand, setBrand] = useState("");
	const [generic, setGeneric] = useState("");
	const [form, setForm] = useState("");
	const [manufacture, setManufacture] = useState("");
	const [setConsidirations] = useState([]);
	const [activeIngrids, setActiveIngrids] = useState("");
	const [strength, setStrength] = useState("");
	const [unitWght, setUnitWght] = useState("");
	const [pacSize, setPacSize] = useState("");
	const [uom, setUom] = useState("");
	const [descr, setDescr] = useState("");
	const [location, setLocation] = useState("");
	const [light, setLight] = useState(false);
	const [heat, setHeat] = useState(false);
	const [moist, setMoist] = useState(false);
	const [hygro, setHygro] = useState(false);
	const [flammable, setFlammable] = useState(false);
	const [hazardous, setHazardous] = useState(false);

	var tempArray = [];

	const handleSave = async (e) => {
		e.preventDefault();

		if (light) {
			tempArray.push("Light sensitive");
		}
		if (heat) {
			tempArray.push("Heat sensitive");
		}
		if (moist) {
			tempArray.push("Moisture sensitive");
		}
		if (hygro) {
			tempArray.push("Hygroscopic");
		}
		if (flammable) {
			tempArray.push("Flammable");
		}
		if (hazardous) {
			tempArray.push("Hazardous");
		}

		var params = [
			id,
			ndc,
			brand,
			generic,
			form,
			manufacture,
			tempArray,
			activeIngrids,
			strength,
			unitWght,
			pacSize,
			uom,
			descr,
			location,
		];
		let procName = "UPDATE_INVENTORY_ITEMS";
		const url =
			"https://c99jtoge7d.execute-api.us-east-2.amazonaws.com/dev/batch";
		const data = { procName, params };
		axios
			.put(url, JSON.stringify(data))
			.then((resp) => {
				console.log("Resp: ", resp);
				if (resp.data.errorType) {
					alert(`DB Error occured ${resp.data.errorMessage}`);
				} else {
					alert(`Item ${ndc} was updated successfully!`);
					navigate("../items", { replace: true });
				}
			})
			.catch((err) => {
				alert(`Error Add: ${err}`);
			});
	};

	useEffect(() => {
		const url = `https://c99jtoge7d.execute-api.us-east-2.amazonaws.com/dev?procName=GET_ITEMS_BY_ID&params=${id}`;

		const request = axios({
			method: "GET",
			url,
		});
		request
			.then((res) => {
				if (!res) {
					alert("No item data found!!!");
				} else {
					console.log("Data: ", res.data.result[0]);
					setNdc(res.data.result[0].NDC);
					setBrand(res.data.result[0].BRAND);
					setGeneric(res.data.result[0].GENERIC);
					setForm(res.data.result[0].FORM);
					setManufacture(res.data.result[0].MANUFACTURE);
					setActiveIngrids(res.data.result[0].ACTIVE_INGRIDIENT);
					setStrength(res.data.result[0].STRENGTH);
					setUnitWght(res.data.result[0].UNIT_WGHT);
					setPacSize(res.data.result[0].PACKAGE_SIZE);
					setUom(res.data.result[0].UNIT_OF_MESURE);
					setDescr(res.data.result[0].DESCR);
					setLocation(res.data.result[0].LOCATION);
					let tempArr = res.data.result[0].CONSIDIRATIONS.split(",");
					tempArr.map((a) => {
						if (a === "Light sensitive") {
							setLight(true);
						} else if (a === "Heat sensitive") {
							setHeat(true);
						} else if (a === "Moisture sensitive") {
							setMoist(true);
						} else if (a === "Hygroscopic") {
							setHygro(true);
						}
						if (a === "Flammable") {
							setFlammable(true);
						}
						if (a === "Hazardous") {
							setHazardous(true);
						}
					});
					setConsidirations(res.data.result[0].CONSIDIRATIONS);
				}
			})
			.catch((err) => {
				console.error(err);
			});
	}, [id]);

	return (
		<div className="inventory-wrapper">
			<InventoryItemDetail
				handleSave={handleSave}
				edit={true}
				ndc={ndc}
				setNdc={setNdc}
				brand={brand}
				setBrand={setBrand}
				generic={generic}
				setGeneric={setGeneric}
				form={form}
				setForm={setForm}
				manufacture={manufacture}
				setManufacture={setManufacture}
				activeIngrids={activeIngrids}
				setActiveIngrids={setActiveIngrids}
				strength={strength}
				setStrength={setStrength}
				unitWght={unitWght}
				setUnitWght={setUnitWght}
				pacSize={pacSize}
				setPacSize={setPacSize}
				uom={uom}
				setUom={setUom}
				descr={descr}
				setDescr={setDescr}
				location={location}
				setLocation={setLocation}
				light={light}
				setLight={setLight}
				heat={heat}
				setHeat={setHeat}
				moist={moist}
				setMoist={setMoist}
				hygro={hygro}
				setHygro={setHygro}
				flammable={flammable}
				setFlammable={setFlammable}
				hazardous={hazardous}
				setHazardous={setHazardous}
			/>
		</div>
	);
}
