import { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "./batchingirdriw-styles.scss"; //Style sheet
//Material Ui
import { TableRow, TableCell, IconButton } from "@mui/material";
import { Save, RotateLeft } from "@mui/icons-material";

export const BatchIngridRow = (params) => {
	const {
		ingridient,
		index,
		ingrids,
		setIngrids,
		updCntr,
		setUpdCntr,
		setErr,
	} = params;
	const [weight, setWeight] = useState("");
	const [lotArr, setLotArray] = useState([]);
	const [lot, setLot] = useState("");
	const [error, setError] = useState(false);
	const [lotErr, setLotErr] = useState(false);
	const [saved, setSaved] = useState(false);
	const navigate = useNavigate();

	const handleSave = (e) => {
		e.preventDefault();
		let moe = Number(ingridient.TARGET_WEIGHT) * 0.1;
		let wgt = Number(weight);

		if (
			(wgt > ingridient.TARGET_WEIGHT &&
				wgt - ingridient.TARGET_WEIGHT > moe) ||
			(wgt < ingridient.TARGET_WEIGHT && ingridient.TARGET_WEIGHT - wgt > moe)
		) {
			alert(`Margin of error is more than 10%`);
			setError(true);
			return;
		} else {
			setError(false);
		}

		if (lot.length === 0) {
			alert("Lot Number is required!");
			setLotErr(true);
			return;
		} else {
			setLotErr(false);
			setSaved(true);
		}

		var temp = ingrids;
		var u = updCntr + 1;
		temp[index].WEIGHT = weight;
		temp[index].LOT = lot;
		setIngrids(temp);
		setUpdCntr(u);
	};

	const handleReset = (e) => {
		e.preventDefault();

		let u = updCntr - 1;
		setSaved(false);
		setUpdCntr(u);
	};

	const getLotData = () => {
		const params = `${ingridient.NDC}'%2C'${weight}`;
		const url = `https://c99jtoge7d.execute-api.us-east-2.amazonaws.com/dev/rx?procName=GET_BATCH_INRIDLOT_BYNDC&params=${params}`;
		const request = axios({
			method: "GET",
			url,
		});

		return new Promise((resolve, reject) => {
			request
				.then((resp) => {
					if (resp.data.result.length === 0) {
						reject(
							"No LOT data is found! Ingridient is not a part of inventory!"
						);
						navigate(`../compound-queue`, { replace: true });
					} else {
						resolve(resp.data.result);
					}
				})
				.catch((err) => {
					reject(err);
				});
		});
	};

	useEffect(() => {
		getLotData()
			.then((resp) => {
				setLotArray(resp);
				// console.log("Lot Data", resp);
			})
			.catch((err) => {
				alert(`Severe error fatching lot for ${ingridient.NDC}! ${err}`);
				setErr(true);
			});
	}, [ingridient.NDC]);

	return (
		<>
			<TableRow key={index} className="batchRow">
				<TableCell className="batchCell">{ingridient.NDC}</TableCell>
				<TableCell className="batchCell" align="left">
					{ingridient.GENERIC}
				</TableCell>

				<TableCell align="left" scope="row" style={{ background: "yellow" }}>
					{ingridient.CONSIDERATIONS}
				</TableCell>
				<TableCell align="left" className="batchCell">
					{ingridient.TARGET_WEIGHT}g
				</TableCell>
				<TableCell className="batchEditCell" align="left">
					<input
						className="weight"
						id="weight"
						name={weight}
						onChange={(e) => setWeight(e.target.value)}
						value={weight}
						error={error}
						disabled={saved}
					/>
					g
				</TableCell>
				<TableCell className="batchEditCell">
					<select
						className="selectLot"
						native
						required
						error={lotErr}
						disabled={saved}
						id="lot"
						value={lot}
						onChange={(e) => setLot(e.target.value)}
					>
						<option aria-label="None" value="" />
						{lotArr.map((row) => (
							<option key={row.ORDR_ID} value={row.ORDR_ID}>
								{row.LOT
									? `Lot: ${row.LOT} Expiry: ${row.EXP_DATE} Qty: ${row.QTY_ONHAND}`
									: `Qty: ${row.QTY_ONHAND}`}
							</option>
						))}
					</select>
				</TableCell>
				<TableCell className="batchCell" align="left">
					{ingridient.APIS}
				</TableCell>
				<TableCell className="batchCell" align="left">
					{ingridient.QSAD}
				</TableCell>
				<TableCell className="batchCell" align="left">
					<IconButton
						aria-label="save"
						color="primary"
						onClick={handleSave}
						disabled={saved}
					>
						<Save />
					</IconButton>
					/
					<IconButton
						aria-label="save"
						color="primary"
						onClick={handleReset}
						disabled={!saved}
					>
						<RotateLeft />
					</IconButton>
				</TableCell>
			</TableRow>
		</>
	);
};
