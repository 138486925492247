import { useState } from "react";
import "./login.scss";
import { ConfirmAwsUser } from "../../utils/aws-cognito.js";
import { Link } from "react-router-dom";
import Logo from "../../assets/img/logo-white.png";
//Material Ui
import { PersonOutlined, FifteenMpOutlined } from "@mui/icons-material";

export default function ConfirmEmail() {
	const [email, setEmail] = useState("");
	const [code, setCode] = useState("");
	const [setConfirmed] = useState(false);

	const handleSubmit = async (event) => {
		event.preventDefault();

		await ConfirmAwsUser(email, code)
			.then((data) => {
				setConfirmed(true);
				window.location.reload(true);
			})
			.catch((err) => {
				console.error("Confirm", err);
				alert(`${err.message} ${err.name}`);
			});
	};

	return (
		<>
			<form onSubmit={handleSubmit}>
				<div className="container">
					<div className="title">
						<img src={Logo} alt="" className="logo" />
						<h4 className="titleText">Confrim email with code</h4>
					</div>
					<div className="row">
						<label className="label" htmlFor="username">
							<PersonOutlined />
						</label>
						<input
							type="email"
							className="input"
							id="email"
							placeholder="email"
							value={email}
							onChange={(e) => setEmail(e.target.value)}
							required
						/>
					</div>
					<div className="row">
						<label className="label" htmlFor="password">
							<FifteenMpOutlined />
						</label>
						<input
							type="text"
							className="input"
							id="code"
							placeholder="code"
							value={code}
							onChange={(e) => setCode(e.target.value)}
							required
						/>
					</div>
					<div className="row">
						<button type="submit">
							<span class="shadow"></span>
							<span class="edge"></span>
							<span class="front text"> LOGIN</span>
						</button>
					</div>

					<div className="passLink">
						<Link to={"/forgot-password"} className="link">
							Forgot password
						</Link>
					</div>
				</div>
			</form>
		</>
	);
}
