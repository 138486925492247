import {
	BubbleChart,
	ConfirmationNumber,
	BuildOutlined,
	QueueOutlined,
	EventNote,
	// List,
	ListAlt,
	FormatListNumbered,
	Spellcheck,
	ThumbUp,
	GroupWork,
	Collections,
	VideoLabel,
	FiberNew,
} from "@mui/icons-material";

const labcategories = [
	{
		id: "Lab Dashboard",
		children: [
			{
				id: "Rx Verification",
				icon: <ThumbUp />,
				link: "../verify",
				active: true,
				resource: 220,
			},
			{
				id: "Compounding Queue",
				icon: <GroupWork />,
				link: "/workque",
				resource: 221,
			},

			{
				id: "Create Batch",
				icon: <FiberNew />,
				link: "../add-batch",
				resource: 201,
			},
			{
				id: "Compound Batch",
				icon: <QueueOutlined />,
				link: "../compound-queue",
				resource: 206,
			},
			{
				id: "Mill Batch",
				icon: <BubbleChart />,
				link: "../mill-batch",
				resource: 202,
			},
			{
				id: "Pack Batch queue",
				icon: <Collections />,
				link: "../batch-pack",
				resource: 203,
			},
			{
				id: "Label Batch Rx's",
				icon: <VideoLabel />,
				link: "../batch-label",
				resource: 205,
			},
			{
				id: "Complete Batch",
				icon: <ConfirmationNumber />,
				link: "../batch-complete",
				resource: 204,
			},
			{
				id: "Batch Inventory",
				icon: <FormatListNumbered />,
				link: "../batch-inventory",
				resource: 207,
			},
		],
	},
	{
		id: "Master Formulation",
		children: [
			{
				id: "Master Formulary Setup",
				icon: <BuildOutlined />,
				link: "../add-formula",
				resource: 211,
			},
			{
				id: "Deactivate Formula",
				icon: <Spellcheck />,
				link: "../edit-formula",
				resource: 212,
			},
			{
				id: "List of formulas",
				icon: <ListAlt />,
				link: "../list-formula",
				resource: 213,
			},
		],
	},
	{
		id: "Lab Reports",
		resource: 250,
		children: [
			{
				id: "Daily Batch Report",
				icon: <EventNote />,
				link: "../batch-rptdate",
				resource: 251,
			},
			// {
			// 	id: "Search Batch by Rx",
			// 	icon: <List />,
			// 	link: "../batch-rx",
			// 	resource: 252,
			// },
		],
	},
];

export default labcategories;
