import { useState } from "react";
import "./addmethod-styles.scss";
//Material Ui
import AddToQueueIcon from "@mui/icons-material/AddToQueue";

export default function AddFormulaMethod({
	updt,
	setUpdt,
	formula,
	setFormula,
}) {
	const [step, setStep] = useState(formula.method.length + 1);
	const [descr, setDescr] = useState("");

	const stepNo = () => {
		return formula.method.length + 1;
	};

	const handleChange = (e) => {
		e.preventDefault();
		setDescr(e.target.value);
		setStep(stepNo());
	};

	const onSubmit = (e) => {
		e.preventDefault();
		addMethod();
		setDescr("");
	};

	const addMethod = () => {
		var temp = formula;
		const newMethod = {
			method: step,
			descr: descr,
		};
		temp.method.push(newMethod);
		var cntr = updt + 1;
		setUpdt(cntr);
		setFormula(temp);
	};

	return (
		<>
			<form onSubmit={onSubmit} className="method-form">
				<div className="method-subheader">
					<span className="step">Step - {stepNo()}</span>
				</div>
				<div className="input-descr">
					<textarea
						type="text"
						className="descr"
						cols="95"
						rows="4"
						required
						id="descr"
						value={descr}
						name="descr"
						autoFocus
						onChange={handleChange}
					/>
				</div>
				<button type="submit" className="saveBtn">
					<AddToQueueIcon /> Add step
				</button>
			</form>
		</>
	);
}
