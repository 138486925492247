import { useState, useEffect } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import "./editVendor-styles.scss";
import VendorForm from "../vendor-form/VendorForm.component";

export default function EditVendor() {
	const navigate = useNavigate();
	const { id } = useParams();
	const [name, setName] = useState("");
	const [address, setAddress] = useState("");
	const [city, setCity] = useState("");
	const [state, setState] = useState("");
	const [zip, setZip] = useState("");
	const [phone, setPhone] = useState("");
	const [email, setEmail] = useState("");
	const [contact, setContact] = useState("");
	const [account, setAccount] = useState("");
	const [terms, setTerms] = useState("");

	const handleSave = async (e) => {
		e.preventDefault();
		const url =
			"https://c99jtoge7d.execute-api.us-east-2.amazonaws.com/dev/batch";
		const procName = "UPDATE_VENDOR";
		const params = [
			id,
			name,
			address,
			city,
			state,
			zip,
			phone,
			email,
			contact,
			account,
			terms,
		];

		let data = { procName, params };
		axios
			.put(url, JSON.stringify(data))
			.then((resp) => {
				console.log("Resp: ", resp);
				if (resp.data.errorType) {
					alert(`DB Error occured ${resp.data.errorMessage}`);
				} else {
					alert(`Vendor ${name} was updated successfully!`);
					navigate("../vendors", { replace: true });
				}
			})
			.catch((err) => {
				console.error("Problem with Update: ", err);
				alert(`Error ${err} update was not successful!`);
			});
	};

	useEffect(() => {
		const url = `https://c99jtoge7d.execute-api.us-east-2.amazonaws.com/dev?procName=GET_VENDOR_BY_ID&params=${id}`;

		const request = axios({
			method: "GET",
			url,
		});
		request
			.then((res) => {
				if (!res) {
					alert("No vendor data found!!!");
				} else {
					setName(res.data.result[0].VENDOR_NAME);
					setAddress(res.data.result[0].VENDOR_ADDR);
					setCity(res.data.result[0].VENDOR_CITY);
					setState(res.data.result[0].VENDOR_STATE);
					setZip(res.data.result[0].VENDOR_ZIP);
					setPhone(res.data.result[0].VENDOR_PHONE);
					setEmail(res.data.result[0].VENDOR_EMAIL);
					setContact(res.data.result[0].VENDOR_CONTACT);
					setAccount(res.data.result[0].VENDOR_CUST_ACCOUNT);
					setTerms(res.data.result[0].VENDOR_TERMS);
				}
			})
			.catch((err) => {
				console.error(err);
			});
	}, [id]);

	return (
		<div className="vendor-wrapper">
			<VendorForm
				edit={true}
				name={name}
				setName={setName}
				address={address}
				setAddress={setAddress}
				city={city}
				setCity={setCity}
				state={state}
				setState={setState}
				zip={zip}
				setZip={setZip}
				phone={phone}
				setPhone={setPhone}
				email={email}
				setEmail={setEmail}
				contact={contact}
				setContact={setContact}
				account={account}
				setAccount={setAccount}
				terms={terms}
				setTerms={setTerms}
				handleSave={handleSave}
			/>
		</div>
	);
}
