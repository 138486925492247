import { useState, useEffect } from "react";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import DialogComponent from "../dialog/DialogComponent";
import {
	stableSort,
	getComparator,
	EnhancedTableHead,
	EnhancedTableToolbar,
} from "../table-sort/TableSort.component";
import TablePaginationActions from "../table-paginatoin/TablePagination.component";

import {
	addItem,
	deleteItem as deleteStoreItem,
} from "../../store/actions/item.actions";
import "./addpoitmes-styles.scss";
import {
	Table,
	TableBody,
	TableCell,
	TableRow,
	Checkbox,
	TableFooter,
	TablePagination,
} from "@mui/material";

const headCells = [
	{ id: "NDC", numeric: false, disablePadding: false, label: "NDC" },
	{
		id: "GENERIC",
		numeric: false,
		disablePadding: false,
		label: "Generic Name",
	},
	{
		id: "PACKAGE_SIZE",
		numeric: false,
		disablePadding: false,
		label: "Package Size",
	},
	{ id: "QTY", numeric: false, disablePadding: false, label: "On Order" },

	{
		id: "STATUS",
		numeric: false,
		disablePadding: false,
		label: "Status",
	},
	{ id: "ORDR_DT", numeric: false, disablePadding: false, label: "Order Date" },
];

export default function AddItemsToPo() {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	var item = useSelector((state) => state.item);
	const [data, setData] = useState([]);
	const [open, setOpen] = useState(false);
	const [deleteSw, setDeleteSw] = useState(false);
	const dlgTitle = "Delete Order Item";
	const [order, setOrder] = useState("asc");
	const [orderBy, setOrderBy] = useState("GENERIC");
	const [selected, setSelected] = useState([]);
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(-1);

	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === "asc";
		setOrder(isAsc ? "desc" : "asc");
		setOrderBy(property);
	};

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		// console.log("Rows per page: ", parseInt(event.target.value, 10));
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const handleClick = (event, name, status) => {
		const selectedIndex = selected.indexOf(name);
		let newSelected = [];

		if (selectedIndex === -1) {
			newSelected = newSelected.concat(selected, name);
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(selected.slice(1));
		} else if (selectedIndex === selected.length - 1) {
			newSelected = newSelected.concat(selected.slice(0, -1));
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(
				selected.slice(0, selectedIndex),
				selected.slice(selectedIndex + 1)
			);
		}

		setSelected(newSelected);
		// }
	};

	const isSelected = (id) => selected.indexOf(id) !== -1;

	const handleDelete = (e) => {
		e.preventDefault();
		setOpen(true);
	};

	const handleAddPo = (e) => {
		e.preventDefault();
		// console.log("Selected: ", selected);
		item.itemIds = selected;
		dispatch(addItem(item));
		navigate("../add-po", { replace: false });
	};

	const deleteItem = (e) => {
		e.preventDefault();

		setOpen(false);
		selected.map((d) => {
			deleteItemDB(d)
				.then((resp) => {
					setDeleteSw(true);
					alert(`Order Item was ${d} deleted successfully`);
				})
				.catch((err) => {
					alert(`Error! During DB delete ${err} `);
				});
		});
	};
	const deleteItemDB = (id) => {
		const url =
			"https://c99jtoge7d.execute-api.us-east-2.amazonaws.com/dev/item";
		let procName = "DELETE_ORDER_ITEM";
		let params = [id];
		var ItemData = {
			procName,
			params,
		};

		return new Promise((resolve, reject) => {
			const request = axios({
				method: "POST",
				url,
				data: ItemData,
			});
			request
				.then((res) => {
					resolve(res);
				})
				.catch((err) => {
					reject(err);
				});
		});
	};

	useEffect(() => {
		const url = `https://c99jtoge7d.execute-api.us-east-2.amazonaws.com/dev?procName=GET_REQUESTED_ITEMS&params=1`;
		const request = axios({
			method: "GET",
			url,
		});
		request
			.then((res) => {
				if (!res) {
					alert("No user data found!!!");
				} else {
					setData(res.data.result);
					item.itemIds = [];
					dispatch(deleteStoreItem(item));
				}
			})
			.catch((err) => {
				console.error(err);
			});
	}, [deleteSw]);

	return (
		<div className="po-item-wrapper">
			<div className="title">
				<span>Select items to order</span>
			</div>
			<div className="po-table-wrapper">
				<EnhancedTableToolbar
					numSelected={selected.length}
					title="Selected Items"
					handleDelete={handleDelete}
					handleAddPo={handleAddPo}
					po={"PO"}
				/>

				<Table className="table">
					<EnhancedTableHead
						checkbox={true}
						headCells={headCells}
						order={order}
						orderBy={orderBy}
						onRequestSort={handleRequestSort}
						className="headerRow"
					/>
					<TableBody>
						{stableSort(data, getComparator(order, orderBy))
							.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
							.map((row, index) => {
								const isItemSelected = isSelected(row.ORDR_ID);
								const labelId = `enhanced-table-checkbox-${index}`;

								let color = "#2a4e9d";

								if (row.STATUS === "ORDERED") {
									color = "green";
								}

								return (
									<TableRow
										hover
										onClick={(event) =>
											handleClick(event, row.ORDR_ID, row.STATUS)
										}
										role="checkbox"
										aria-checked={isItemSelected}
										tabIndex={-1}
										key={row.ORDR_ID}
										selected={isItemSelected}
										className="table-row"
									>
										<TableCell padding="checkbox">
											<Checkbox
												checked={isItemSelected}
												inputProps={{ "aria-labelledby": labelId }}
											/>
										</TableCell>

										<TableCell className="ndc" style={{ color: color }}>
											{row.NDC}
										</TableCell>
										<TableCell className="generic" style={{ color: color }}>
											{row.GENERIC}
										</TableCell>
										<TableCell
											className="cell"
											style={{ color: color }}
											align="center"
										>
											{row.PACKAGE_SIZE} {row.UNIT_OF_MESURE}
										</TableCell>

										<TableCell
											className="cell"
											style={{ color: color }}
											align="center"
										>
											{row.QTY}
										</TableCell>

										<TableCell
											className="cell"
											style={{ color: color }}
											align="center"
										>
											{row.STATUS}
										</TableCell>
										<TableCell className="cell" style={{ color: color }}>
											{row.ORDR_DT}
										</TableCell>
									</TableRow>
								);
							})}
					</TableBody>
					<TableFooter>
						<TableRow className="pagination">
							<TablePagination
								rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
								colSpan={3}
								count={data.length}
								rowsPerPage={rowsPerPage}
								page={page}
								SelectProps={{
									inputProps: {
										"aria-label": "rows per page",
									},
									native: true,
								}}
								onPageChange={handleChangePage}
								onRowsPerPageChange={handleChangeRowsPerPage}
								ActionsComponent={TablePaginationActions}
							/>
						</TableRow>
					</TableFooter>
				</Table>
				<DialogComponent
					open={open}
					setOpen={setOpen}
					dlgTitle={dlgTitle}
					dlgAction={"Delete"}
					id={selected}
					handleClickOk={deleteItem}
				/>
			</div>
		</div>
	);
}
