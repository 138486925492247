import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { IdleTimerProvider } from "react-idle-timer";
import SessionTimeoutDialog from "../session-timeout-dialog/SessionTimeoutDialog";
import { UserSignOut } from "../../utils/aws-cognito.js";
import { deleteUser } from "../../store/actions/user.actions.js";
import { useDispatch, useSelector } from "react-redux";

export function IdleTimerContainer({ children }) {
	const user = useSelector((state) => state.user.user);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [timeoutModalOpen, setTimeoutModalOpen] = useState(false);
	const [timeoutCountdown] = useState(0);

	const onIdle = () => {
		// Close Modal Prompt
		// Do some idle action like log out your user
		console.log("User Timeout");
		setTimeoutModalOpen(true);
		handleLogout();
	};

	const handleLogout = async () => {
		await UserSignOut(user.EMAIL)
			.then((data) => {
				console.log(data);
				setTimeoutModalOpen(false);
				dispatch(deleteUser());
				navigate("/");
			})
			.catch((err) => {
				alert(`Sever error ${err}`);
				console.log(err);
			});
	};

	return (
		<>
			<IdleTimerProvider timeout={1000 * 15 * 60} onIdle={onIdle}>
				{children}
			</IdleTimerProvider>
			<SessionTimeoutDialog
				countdown={timeoutCountdown}
				onLogout={() => handleLogout()}
				open={timeoutModalOpen}
			/>
		</>
	);
}
