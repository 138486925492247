/* eslint-disable array-callback-return */
import { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import BatchLabelTable from "../batch-label-table/BatchLabelTable.component";
import "./batchrxlabel.scss"; //Style sheet

export default function BatchRxLabel() {
	const { id } = useParams();
	const [data, setData] = useState([]);
	const [hdrData, setHdrData] = useState([]);
	const [lot, setLot] = useState("");
	const [bDate, setBdate] = useState("");
	const [labelArr, setLabelArr] = useState([]);
	const zeroPad = (num, places) => String(num).padStart(places, "0");

	useEffect(() => {
		const url = `https://c99jtoge7d.execute-api.us-east-2.amazonaws.com/dev?procName=GET_BATCH_LABEL_BY_ID&params=${id}`;

		const request = axios({
			method: "GET",
			url,
		});
		request
			.then((res) => {
				if (!res) {
					alert("No item data found!!!");
				} else {
					console.log("Data: ", res.data.result);
					setData(res.data.result);
					setHdrData(res.data.result[0]);
					setLot(
						`${res.data.result[0].BATCH_DATE}@${zeroPad(
							res.data.result[0].SEQ,
							2
						)}`
					);
					setBdate(res.data.result[0].TIMESTAMP.split(" ")[0]);
					let arr = [];
					res.data.result.map((row) => {
						let newPack = {
							id: row.PACKID,
							seq: row.BATCH_SEQ,
							weight: row.WEIGHT,
						};
						arr.push(newPack);
					});
					console.log("Labels: ", arr);
					setLabelArr(arr);
				}
			})
			.catch((err) => {
				console.error(err);
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id]);

	return (
		<div className="batchRxLbl-wrapper">
			<div className="title">
				<span className="subtitle">Label batch Rx's </span>
				<span className="subtitle">Batch: {lot}</span>
			</div>
			<BatchLabelTable labelArr={labelArr} batchId={id} />
		</div>
	);
}
