import { useState } from "react";
import axios from "axios";
import "./addInventoryItem-styles.scss";
import { useNavigate } from "react-router-dom";
import InventoryItemDetail from "../inventory-item-details/InventoryItemDetail.component";

export default function AddInventoryItem() {
	let navigate = useNavigate();
	const [ndc, setNdc] = useState("");
	const [brand, setBrand] = useState("");
	const [generic, setGeneric] = useState("");
	const [form, setForm] = useState("");
	const [manufacture, setManufacture] = useState("");
	const [activeIngrids, setActiveIngrids] = useState("");
	const [strength, setStrength] = useState("");
	const [unitWght, setUnitWght] = useState("");
	const [pacSize, setPacSize] = useState("");
	const [uom, setUom] = useState("");
	const [descr, setDescr] = useState("");
	const [location, setLocation] = useState("");
	const [light, setLight] = useState(false);
	const [heat, setHeat] = useState(false);
	const [moist, setMoist] = useState(false);
	const [hygro, setHygro] = useState(false);
	const [flammable, setFlammable] = useState(false);
	const [hazardous, setHazardous] = useState(false);

	var tempArray = [];

	const handleSave = async (e) => {
		e.preventDefault();

		if (light) {
			tempArray.push("Light sensitive");
		}
		if (heat) {
			tempArray.push("Heat sensitive");
		}
		if (moist) {
			tempArray.push("Moisture sensitive");
		}
		if (hygro) {
			tempArray.push("Hygroscopic");
		}
		if (flammable) {
			tempArray.push("Flammable");
		}
		if (hazardous) {
			tempArray.push("Hazardous");
		}

		var params = [
			ndc,
			brand,
			generic,
			form,
			manufacture,
			tempArray,
			activeIngrids,
			strength,
			unitWght,
			pacSize,
			uom,
			descr,
			location,
		];
		let procName = "ADD_INVENTORY_ITEMS";
		const url = "https://c99jtoge7d.execute-api.us-east-2.amazonaws.com/dev";
		const data = { procName, params };
		axios
			.post(url, JSON.stringify(data))
			.then((resp) => {
				if (
					resp.data.result[0].ROWS_ADDED &&
					resp.data.result[0].ROWS_ADDED === 1
				) {
					alert("Item was added successfully!");
					navigate("../items", { replace: true });
				} else {
					console.log("Error ", JSON.stringify(resp.data.result[0]));
					alert(`Error! ${JSON.stringify(resp.data.result[0])}`);
				}
			})
			.catch((err) => {
				alert(`Error Add: ${err}`);
			});
	};

	return (
		<div className="itemContainer">
			<InventoryItemDetail
				handleSave={handleSave}
				edit={false}
				ndc={ndc}
				setNdc={setNdc}
				brand={brand}
				setBrand={setBrand}
				generic={generic}
				setGeneric={setGeneric}
				form={form}
				setForm={setForm}
				manufacture={manufacture}
				setManufacture={setManufacture}
				activeIngrids={activeIngrids}
				setActiveIngrids={setActiveIngrids}
				strength={strength}
				setStrength={setStrength}
				unitWght={unitWght}
				setUnitWght={setUnitWght}
				pacSize={pacSize}
				setPacSize={setPacSize}
				setUom={setUom}
				descr={descr}
				setDescr={setDescr}
				location={location}
				setLocation={setLocation}
				light={light}
				setLight={setLight}
				heat={heat}
				setHeat={setHeat}
				moist={moist}
				setMoist={setMoist}
				hygro={hygro}
				setHygro={setHygro}
				flammable={flammable}
				setFlammable={setFlammable}
				hazardous={hazardous}
				setHazardous={setHazardous}
			/>
		</div>
	);
}
