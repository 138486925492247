import "./tablesort-styles.scss";
import PropTypes from "prop-types";
import { visuallyHidden } from "@mui/utils";
import {
	TableCell,
	TableHead,
	TableRow,
	Box,
	TableSortLabel,
	Typography,
	Tooltip,
	IconButton,
	Toolbar,
} from "@mui/material";
import { Delete, AddShoppingCart, FilterList } from "@mui/icons-material";

function descendingComparator(a, b, orderBy) {
	if (b[orderBy] < a[orderBy]) {
		return -1;
	}
	if (b[orderBy] > a[orderBy]) {
		return 1;
	}
	return 0;
}

export function getComparator(order, orderBy) {
	return order === "desc"
		? (a, b) => descendingComparator(a, b, orderBy)
		: (a, b) => -descendingComparator(a, b, orderBy);
}

export function stableSort(array, comparator) {
	const stabilizedThis = array.map((el, index) => [el, index]);
	stabilizedThis.sort((a, b) => {
		const order = comparator(a[0], b[0]);
		if (order !== 0) return order;
		return a[1] - b[1];
	});
	return stabilizedThis.map((el) => el[0]);
}

export function EnhancedTableHead(props) {
	const { checkbox, headCells, order, orderBy, onRequestSort } = props;
	const createSortHandler = (property) => (event) => {
		onRequestSort(event, property);
	};

	return (
		<TableHead className="header">
			<TableRow className="headerRow">
				{checkbox ? <TableCell padding="checkbox"></TableCell> : null}
				{headCells.map((headCell) => (
					<TableCell
						key={headCell.id}
						align={headCell.numeric ? "right" : "left"}
						padding={headCell.disablePadding ? "none" : "normal"}
						sortDirection={orderBy === headCell.id ? order : false}
						className="headerCell"
					>
						<TableSortLabel
							active={orderBy === headCell.id}
							direction={orderBy === headCell.id ? order : "asc"}
							onClick={createSortHandler(headCell.id)}
							className="searchLabel"
						>
							{headCell.label}
							{orderBy === headCell.id ? (
								<Box component="span" sx={visuallyHidden}>
									{order === "desc" ? "sorted descending" : "sorted ascending"}
								</Box>
							) : null}
						</TableSortLabel>
					</TableCell>
				))}
			</TableRow>
		</TableHead>
	);
}

EnhancedTableHead.propTypes = {
	headCells: PropTypes.array.isRequired,
	onRequestSort: PropTypes.func.isRequired,
	order: PropTypes.oneOf(["asc", "desc"]).isRequired,
	orderBy: PropTypes.string.isRequired,
};

export const EnhancedTableToolbar = (props) => {
	// const classes = useToolbarStyles();
	const { numSelected, title, handleDelete, handleAddPo, po = false } = props;

	return (
		<Toolbar className="toolbar-wrapper">
			{numSelected > 0 ? (
				<Typography
					className="title"
					color="inherit"
					variant="subtitle1"
					component="div"
				>
					{numSelected} selected
				</Typography>
			) : (
				<Typography
					className="title"
					variant="h6"
					id="tableTitle"
					component="div"
				>
					{title}
				</Typography>
			)}

			{numSelected > 0 ? (
				<div>
					{po ? (
						<Tooltip title="Add to PO">
							<IconButton aria-label="add" onClick={handleAddPo}>
								<AddShoppingCart className="add" />
							</IconButton>
						</Tooltip>
					) : null}

					<Tooltip title="Delete">
						<IconButton aria-label="delete" onClick={handleDelete}>
							<Delete className="delete" />
						</IconButton>
					</Tooltip>
				</div>
			) : (
				<IconButton aria-label="filter list">
					<FilterList />
				</IconButton>
			)}
		</Toolbar>
	);
};

EnhancedTableToolbar.propTypes = {
	numSelected: PropTypes.number.isRequired,
	title: PropTypes.string.isRequired,
	handleDelete: PropTypes.func.isRequired,
};
