import "./dialog-styles.scss";
import {
	Dialog,
	Button,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

export default function DialogComponent(props) {
	const { open, setOpen, dlgTitle, dlgAction, id, handleClickOk } = props;
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

	const handleClose = () => {
		setOpen(false);
	};

	const dlgTxt = `You have selected to ${dlgAction}. If this was the action you wanted to do, please confirm your choice, or cancel and return to page`;

	return (
		<>
			<Dialog
				fullScreen={fullScreen}
				open={open}
				onClose={handleClose}
				aria-labelledby="responsive-dialog-title"
			>
				<DialogTitle id="responsive-dialog-title">{dlgTitle}</DialogTitle>
				<DialogContent>
					<DialogContentText>{dlgTxt}</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button autoFocus onClick={handleClose} color="primary">
						Cancel
					</Button>
					<Button
						onClick={(e) => handleClickOk(e, id)}
						variant="contained"
						color="primary"
						autoFocus
					>
						{dlgAction}
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
}
