/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams, useLocation } from "react-router-dom";
import "./completebatch-styles.scss"; //Style sheet
import ConfirmBatchDialog from "../confirm-batch-dialog/ConfirmBatchDialog.component";
import BatchIngridSummaryTbl from "../batch-ingirid-summary-tbl/BatchIngridSummaryTbl.component";
import BatchLinkedRx from "../batch-linked-rx/BatchLinkedRx.component";
import Loading from "../loading-component/Loading.component";

export default function CompleteBatch() {
	const { id } = useParams();
	const location = useLocation();
	const [fromRpt] = useState(location.state);
	const [batch, setBatch] = useState({});
	const [users, setUsers] = useState([]);

	const [loading, setLoading] = useState(false);

	const zeroPad = (num, places) => String(num).padStart(places, "0");

	const getUsers = () => {
		const url = `https://c99jtoge7d.execute-api.us-east-2.amazonaws.com/dev?procName=GET_BATCH_UID_BYID&params=${id}`;
		const request = axios({
			method: "GET",
			url,
		});
		return new Promise((resolve, reject) => {
			request
				.then((res) => {
					resolve(res.data.result);
				})
				.catch((err) => {
					reject(err);
				});
		});
	};

	useEffect(() => {
		setLoading(true);
		const url = `https://c99jtoge7d.execute-api.us-east-2.amazonaws.com/dev?procName=GET_BATCH_HDR&params=${id}`;

		const request = axios({
			method: "GET",
			url,
		});
		request
			.then((res) => {
				setLoading(false);

				if (!res) {
					alert("No batch data found!!!");
				} else {
					// console.log("Batch Data: ", res.data.result[0]);
					var f = {};
					setBatch(res.data.result[0]);
					f.ID = res.data.result[0].FORMULA_ID;
					getUsers()
						.then((resp) => {
							setUsers(resp);
						})
						.catch((err) => {
							alert(`Severe DB Error - users ${err}`);
							setLoading(false);
						});
				}
			})
			.catch((err) => {
				setLoading(false);

				console.error(err);
			});
	}, [id]);

	return (
		<>
			{loading ? (
				<Loading />
			) : (
				<div className="completeBatch">
					<div className="title">
						<span className="ndc">{batch.NDC}</span>
						<span className="hdrField">{batch.NAME}</span>
						<span className="hdrField">
							Batch: {`${batch.BATCH_DATE}@${zeroPad(batch.SEQ, 2)}`}
						</span>
						<span className="hdrField">{batch.DESCR_FINAL_PRODUCT}</span>
						<span className="hdrField">Batch: Qty: {batch.QTY} g</span>
						<span className="hdrField">BUD: {batch.BUD}</span>
					</div>

					<BatchIngridSummaryTbl id={id} />
					<BatchLinkedRx id={id} />
					<div className="usersWrapper">
						<span className="userTitle">Pharmacist and technician roles</span>
						{users.map((u) => {
							return (
								<div className="user" key={u.BATCH_ROLE}>
									<span>
										{u.BATCH_ROLE}: {u.FIRST_NAME} {u.LAST_NAME}
									</span>
								</div>
							);
						})}
					</div>
					{fromRpt ? null : <ConfirmBatchDialog id={id} />}
				</div>
			)}
		</>
	);
}
