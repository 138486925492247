import "./itemdetail.scss";
import InputMask from "react-input-mask";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

export default function InventoryItemDetail(params) {
	const {
		handleSave,
		edit,
		ndc,
		setNdc,
		brand,
		setBrand,
		generic,
		setGeneric,
		form,
		setForm,
		manufacture,
		setManufacture,
		activeIngrids,
		setActiveIngrids,
		strength,
		setStrength,
		unitWght,
		setUnitWght,
		pacSize,
		setPacSize,
		uom,
		setUom,
		descr,
		setDescr,
		location,
		setLocation,
		light,
		setLight,
		heat,
		setHeat,
		moist,
		setMoist,
		hygro,
		setHygro,
		flammable,
		setFlammable,
		hazardous,
		setHazardous,
	} = params;

	return (
		<>
			<form className="form" onSubmit={handleSave}>
				<span className="controlLabel">Inventory Item</span>
				<div className="inputWrapper">
					<div className="form-line">
						<div className="formInput">
							<label>NDC:</label>
							<InputMask
								mask="99999-9999-99"
								value={ndc}
								disabled={edit}
								onChange={(e) => setNdc(e.target.value)}
							>
								<input type="text" required className="form-field-ndc" />
							</InputMask>
						</div>

						<div className="formInput">
							<label>Generic Name:</label>
							<input
								type="text"
								className="form-field"
								value={generic}
								required
								style={{ textTransform: "uppercase" }}
								onChange={(e) => setGeneric(e.target.value)}
							/>
						</div>
					</div>
					<div className="form-line">
						<div className="formInput">
							<label>Manufacturer</label>
							<input
								type="text"
								className="form-field"
								value={manufacture}
								required
								onChange={(e) => setManufacture(e.target.value)}
							/>
						</div>
						<div className="formInput">
							<label>Brand</label>
							<input
								type="text"
								className="form-field"
								value={brand}
								required
								onChange={(e) => setBrand(e.target.value)}
							/>
						</div>
					</div>
					<div className="form-line">
						<div className="formInput">
							<label>Ingridients</label>
							<input
								type="text"
								className="form-field"
								value={activeIngrids}
								required
								onChange={(e) => setActiveIngrids(e.target.value)}
							/>
						</div>

						<div className="formInput">
							<label>Location</label>
							<input
								type="text"
								className="form-field"
								value={location}
								onChange={(e) => setLocation(e.target.value)}
							/>
						</div>
					</div>
					<div className="form-line">
						<div className="formInput">
							<label>Package Size</label>
							<input
								type="text"
								className="form-field"
								value={pacSize}
								required
								onChange={(e) => setPacSize(e.target.value)}
							/>
						</div>

						<div className="selectCntrl">
							<label htmlFor="uom-select">Unit of Mesure:</label>

							<select
								id="uom-select"
								className="select"
								value={uom}
								required
								onChange={(e) => setUom(e.target.value)}
							>
								<option value="">--Choose unit of mesure--</option>
								<option value={"kg"}>Kilogram</option>
								<option value={"g"}>Gram</option>
								<option value={"mg"}>Milligram</option>
								<option value={"mcg"}>Microgram</option>
								<option value={"L"}>Litre</option>
								<option value={"ml"}>Millilitre</option>
								<option value={"cc"}>Cubic Centimetre</option>
								<option value={"tab"}>Tablet</option>
								<option value={"cap"}>Capsule</option>
							</select>
						</div>
					</div>
					<div className="formInput">
						<label>Description</label>
						<textarea
							rows="4"
							cols="50"
							type="text"
							value={descr}
							onChange={(e) => setDescr(e.target.value)}
							className="form-field-descr"
						/>
					</div>

					<div className="selectCntrl">
						<label htmlFor="form-select">Form:</label>

						<select
							id="form-select"
							className="select"
							value={form}
							required
							onChange={(e) => setForm(e.target.value)}
						>
							<option value="">--Please choose form--</option>
							<option value={"CREAM"}>CREAM</option>
							<option value={"LIQUID"}>LIQUID</option>
							<option value={"SOLUTION"}>SOLUTION</option>
							<option value={"STOCK SOLUTION"}>STOCK SOLUTION</option>
							<option value={"OINTMENT"}>OINTMENT</option>
							<option value={"GEL"}>GEL</option>
							<option value={"EMULSION"}>EMULSION</option>
							<option value={"POWDER"}>POWDER</option>
							<option value={"TABLET"}>TABLET</option>
							<option value={"CAPSULE"}>CAPSULE</option>
							<option value={"SPRAY"}>SPRAY</option>
						</select>
					</div>

					{form === "TABLET" || form === "CAPSULE" ? (
						<div>
							<div className="formInput">
								<label>Strength</label>
								<input
									type="number"
									className="form-field"
									required
									value={strength}
									onChange={(e) => setStrength(e.target.value)}
								/>
							</div>

							<div className="formInput">
								<label>Weight</label>
								<input
									type="number"
									className="form-field"
									required
									value={unitWght}
									onChange={(e) => setUnitWght(e.target.value)}
								/>
								<span class="units">g</span>
							</div>
						</div>
					) : null}
				</div>

				<span className="controlLabel">
					Compatibility and stability Considerations
				</span>
				<div className="checkboxes">
					<FormControlLabel
						className="checkLabel"
						control={
							<Checkbox
								checked={light}
								className="controlCheck"
								onChange={(e) => setLight(e.target.checked)}
							/>
						}
						label="Light sensitive"
					/>
					<FormControlLabel
						className="checkLabel"
						control={
							<Checkbox
								checked={heat}
								onChange={(e) => setHeat(e.target.checked)}
								className="controlCheck"
							/>
						}
						label="Heat sensitive"
					/>
					<FormControlLabel
						className="checkLabel"
						control={
							<Checkbox
								checked={moist}
								onChange={(e) => setMoist(e.target.checked)}
								className="controlCheck"
							/>
						}
						label="Moisture sensitive"
					/>
					<FormControlLabel
						className="checkLabel"
						control={
							<Checkbox
								checked={hygro}
								onChange={(e) => setHygro(e.target.checked)}
								className="controlCheck"
							/>
						}
						label="Hygroscopic"
					/>
					<FormControlLabel
						className="checkLabel"
						control={
							<Checkbox
								checked={flammable}
								onChange={(e) => setFlammable(e.target.checked)}
								className="controlCheck"
							/>
						}
						label="Flammable"
					/>
					<FormControlLabel
						className="checkLabel"
						control={
							<Checkbox
								checked={hazardous}
								onChange={(e) => setHazardous(e.target.checked)}
								className="controlCheck"
							/>
						}
						label="Hazardous"
					/>
				</div>

				<div className="save">
					<button type="submit" className="save-button">
						Save
					</button>
				</div>
			</form>
		</>
	);
}
