import UserActionTypes from "../actions/user.action.types";

const initialState = {
	user: null,
	session: null,
	errorMsg: null,
	isFetching: false,
};

const userReducer = (state = initialState, action) => {
	switch (action.type) {
		case UserActionTypes.FETCH_USER_START:
			return {
				...state,
				isFatching: true,
			};
		case UserActionTypes.FETCH_USER_SUCCESS:
			return {
				...state,
				user: action.payload,
				isFatching: false,
			};
		case UserActionTypes.FETCH_USER_FAILURE:
			return {
				...state,
				errorMsg: action.payload,
				isFatching: false,
			};
		case UserActionTypes.DELETE_USER:
			return {
				...state,
				user: null,
			};
		case UserActionTypes.ADD_SESSION:
			return {
				...state,
				session: action.payload,
			};
		case UserActionTypes.DELETE_SESSION:
			return {
				...state,
				session: null,
			};
		case UserActionTypes.SESSION_ERROR:
			return {
				...state,
				error: action.payload,
			};
		default:
			return state;
	}
};

export default userReducer;
