import React from "react";
import "./session-dilog.scss";
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Button,
	Typography,
	Slide,
} from "@mui/material";

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});
const SessionTimeoutDialog = ({ open, countdown, onLogout, onContinue }) => {
	return (
		<Dialog open={open} className="dialog" TransitionComponent={Transition}>
			<DialogTitle>Session Timeout</DialogTitle>
			<DialogContent>
				<Typography variant="body2">
					Your session is expired. Please login again
					<span className="countdown">{countdown}</span> seconds.
				</Typography>
			</DialogContent>
			<DialogActions>
				<Button
					onClick={onLogout}
					variant="contained"
					className="logout button"
					style={{ backgroundColor: "red", color: "#fff" }}
				>
					OK
				</Button>
			</DialogActions>
		</Dialog>
	);
};
export default SessionTimeoutDialog;
