import { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { UpdateBatch } from "../../utils/BatchHelper";
import BatchHeader from "../batch-header/BatchHeader.component";
import BatchMethodTable from "../batch-method-table/BatchMethodTable.component";
import BatchIngirds from "../batch-ingrids/BatchIngrids.component";
import "./compoundbatch-styles.scss"; //Style sheet
import SaveIcon from "@mui/icons-material/Save";

export default function CompoundBatch() {
	const navigate = useNavigate();
	const user = useSelector((state) => state.user.user);
	const { id } = useParams();
	const [batch, setBatch] = useState({});
	const [formula, setFormula] = useState({});
	const [pumps, setPumps] = useState();
	const [ingrids, setIngrids] = useState([]);
	const [updCntr, setUpdCntr] = useState(0);
	const [err, setErr] = useState(false);

	const handleSave = (e) => {
		e.preventDefault();

		if (updCntr < ingrids.lenght) {
			alert("Please Save each ingridient by clicking on a button on a right");
			return;
		}
		ingrids.map((ingrid) => {
			const { NDC, WEIGHT, LOT } = ingrid;
			let params = [id, NDC, WEIGHT, LOT];
			let procName = "UPDATE_BATCH_INGRIDS";
			UpdateBatch(procName, params)
				.then((resp) => {
					if (resp.data.errorMessage) {
						alert(`Severe error ${resp.data.errorMessage}`);
					}
				})
				.catch((err) => {
					alert(`Severe Db error ${err}`);
				});
		});

		let procName = "ADD_BATCH_USERID";
		let params = [id, "COMPOUNDED", user.EMAIL];
		UpdateBatch(procName, params)
			.then((resp) => {
				if (!resp.data.errorMessage) {
					UpdateBatchStatus();
				}
			})
			.catch((err) => {
				alert(`Severe Db error ${err}`);
			});
	};

	const UpdateBatchStatus = () => {
		let procName = "UPDATE_BATCH_STATUS";
		let params = [id, "COMPOUNDED"];
		UpdateBatch(procName, params)
			.then((resp) => {
				console.log("Resp: ", resp.data.result[0].sql_error);
				if (!resp.data.errorMessage) {
					alert("Batch compounding record is save in the DB");
					navigate(`../compound-queue`, { replace: true });
				}
			})
			.catch((err) => {
				alert(`Severe Db error ${err}`);
			});
	};

	const getNumberOfPumps = () => {
		const url = `https://c99jtoge7d.execute-api.us-east-2.amazonaws.com/dev?procName=GET_BATCHRX_COUNT&params=${id}`;
		const request = axios({
			method: "GET",
			url,
		});
		return new Promise((resolve, reject) => {
			request
				.then((res) => {
					resolve(res.data.result[0].PUMP_COUNT);
				})
				.catch((err) => {
					reject(err);
				});
		});
	};

	useEffect(() => {
		const url = `https://c99jtoge7d.execute-api.us-east-2.amazonaws.com/dev?procName=GET_BATCH_HDR&params=${id}`;

		const request = axios({
			method: "GET",
			url,
		});
		request
			.then((res) => {
				if (!res) {
					alert("No batch data found!!!");
				} else {
					console.log("Batch Data: ", res.data.result[0]);
					var f = {};
					setBatch(res.data.result[0]);
					f.ID = res.data.result[0].FORMULA_ID;
					setFormula(f);
					getNumberOfPumps()
						.then((resp) => {
							setPumps(resp);
						})
						.catch((err) => {
							alert(`Severe DB Error - pumps ${err}`);
						});
				}
			})
			.catch((err) => {
				console.error(err);
			});
	}, [id]);

	return (
		<div className="batchWrapper">
			<BatchHeader batch={batch} pumps={pumps} />
			<BatchMethodTable formula={formula} />
			<BatchIngirds
				id={id}
				ingrids={ingrids}
				setIngrids={setIngrids}
				updCntr={updCntr}
				setUpdCntr={setUpdCntr}
				setErr={setErr}
			/>
			<div className="buttonSave">
				<button className="saveBtn" onClick={handleSave}>
					<SaveIcon /> Save Batch
				</button>
			</div>
		</div>
	);
}
