import React from "react";
import { TableRow, TableCell, Typography } from "@mui/material";

export function ccyFormat(num) {
	return `$${Number(num).toFixed(2)}`;
}

export function handleTotal(arr) {
	// console.log("Array: ", arr);

	const total = (arr) => {
		return arr.QTY_RECIVED * arr.COST;
	};
	const sum = (prev, next) => {
		return prev + next;
	};
	return ccyFormat(arr.map(total).reduce(sum));
}

export const GrandTotal = (params) => {
	const { total } = params;
	// console.log("grand total: ", params);

	return (
		<>
			<TableRow>
				<TableCell />
				<TableCell />
				<TableCell />
				<TableCell>
					<Typography
						style={{
							fontSize: 18,
							fontWeight: 800,
							color: "#2286A9",
							fontFamily: ['"Segoe UI Symbol"'].join(","),
						}}
					>
						Grand Total:
					</Typography>
				</TableCell>

				<TableCell
					align="left"
					style={{
						fontSize: 18,
						fontWeight: 800,
						color: "#2286A9",
						fontFamily: ['"Segoe UI Symbol"'].join(","),
					}}
				>
					{handleTotal(total)}
				</TableCell>
			</TableRow>
		</>
	);
};
