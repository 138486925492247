import { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import "./editrole-styles.scss";
import CreatePermsList from "./CreatePermsList.component";
import { Save } from "@mui/icons-material";

function CreatePersmissionObject(data, menuData, setPerms) {
	const permObject = data.reduce(
		(id, cur) => ({ ...id, [cur.ID]: cur.RESOURCE }),
		{}
	);
	//Current DB permissions
	setPerms(permObject);

	var t = [];
	menuData.map((v) => {
		var obj = {};
		obj.isChecked = Object.hasOwn(permObject, v.ID);
		obj.id = v.ID;
		obj.resource = v.RESOURCE;
		return t.push(obj);
	});

	return t;
}

export default function EditRole() {
	const { id } = useParams();
	const [data, setData] = useState([]);
	const [perms, setPerms] = useState([]);
	const [loader, setLoader] = useState(false);
	const [roleName, setRoleName] = useState("");
	const [roleDescr, setRoleDescr] = useState("");

	const handleAddPermission = (perm_id) => {
		const url = "https://c99jtoge7d.execute-api.us-east-2.amazonaws.com/dev";
		let procName = "ADD_ROLE_PERMISSIONS";
		let params = [id, perm_id];
		let data = { procName, params };
		return new Promise((resolve, reject) => {
			axios
				.post(url, JSON.stringify(data))
				.then((resp) => {
					if (resp.data.body === '"Success"') {
						resolve("Success");
					} else {
						reject(resp.data.body);
					}
				})
				.catch((err) => {
					reject(err);
				});
		});
	};

	const handleDeletePermission = (perm_id) => {
		// alert(`Permission to delete ${perm_id}`);

		const url = "https://c99jtoge7d.execute-api.us-east-2.amazonaws.com/dev";
		let procName = "DELETE_ROLE_PERMISSION";
		let params = [id, perm_id];
		let data = { procName, params };
		return new Promise((resolve, reject) => {
			axios
				.post(url, JSON.stringify(data))
				.then((resp) => {
					if (resp.data.body === '"Success"') {
						resolve("Success");
					} else {
						reject(resp.data.body);
					}
				})
				.catch((err) => {
					reject(err);
				});
		});
	};

	const handleSave = (e) => {
		e.preventDefault();

		function processDB() {
			return new Promise((res, rej) => {
				data.map((a) => {
					if (a.isChecked & !(a.id in perms)) {
						handleAddPermission(a.id).catch((err) => {
							alert(`Add Failed with db error ${err}`);
						});
					} else if (!a.isChecked) {
						handleDeletePermission(a.id).catch((err) => {
							alert(`Add Failed with db error ${err}`);
						});
					}
				});
			});
		}

		processDB().then(alert("Changes to role permission are saved"));
	};

	const permissionList = async () => {
		const url = `https://c99jtoge7d.execute-api.us-east-2.amazonaws.com/dev?procName=GET_ROLE_PERMSISSIONS_BYID&params=${id}`;

		return new Promise((resolve, reject) => {
			axios
				.get(url)
				.then((resp) => {
					if (resp.data.result.length === 0) {
						reject("No Data Found!");
					}
					resolve(resp.data.result);
				})
				.catch((err) => {
					console.error("Problem with Select: ", err);
					reject(err);
				});
		});
	};

	useEffect(() => {
		const url = `https://c99jtoge7d.execute-api.us-east-2.amazonaws.com/dev?procName=GET_ALL_SYS_RESOURCES&params=${id}`;

		//
		const request = axios({
			method: "GET",
			url,
		});
		request
			.then((res) => {
				if (!res) {
					alert("No Menu data found!!!");
				} else {
					permissionList()
						.then((resp) => {
							setData(CreatePersmissionObject(resp, res.data.result, setPerms));
							setRoleName(resp[0].ROLE_NAME);
							setRoleDescr(resp[0].ROLE_DESCR);
							setLoader(true);
						})
						.catch((err) => {
							alert(`Error selecting a role ${err}`);
						});
				}
			})
			.catch((err) => {
				console.error(err);
			});
	}, [id]);

	return (
		<div className="container">
			<div className="header">
				<h2 className="title">
					Grant access to system resources to : {roleName}
				</h2>
				<span className="subtitle">{roleDescr}</span>
			</div>
			<h2 className="title">Menus</h2>
			<div className="list">
				<CreatePermsList data={data} setData={setData} />
			</div>
			<div className="save">
				<button
					variant="contained"
					color="secondary"
					className="saveBtn"
					onClick={handleSave}
				>
					<Save /> Save Changes
				</button>
			</div>
		</div>
	);
}
