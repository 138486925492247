import {
	CognitoUserPool,
	CognitoUser,
	AuthenticationDetails,
} from "amazon-cognito-identity-js";
// import ForgotPassword from "../pages/forgotpassword/ForgotPassword";

const poolData = {
	UserPoolId: "us-east-2_BRa9a60kI",
	ClientId: "4vv8a1scfjvfqk9k72uklhpmcm",
};

const UserPool = new CognitoUserPool(poolData);

export const CreateAwsUser = (email, password) => {
	return new Promise((resolve, reject) => {
		UserPool.signUp(email, password, [], null, (err, data) => {
			if (err) {
				alert(err.message || JSON.stringify(err));
				reject(err);
				return;
			}
			let cognitoUser = data.user;
			console.log(cognitoUser);
			resolve(cognitoUser);
		});
	});
};

export const LoginAws = (Username, Password) => {
	const user = new CognitoUser({ Username, Pool: UserPool });
	const authDetails = new AuthenticationDetails({
		Username,
		Password,
	});

	return new Promise((resolve, reject) => {
		user.authenticateUser(authDetails, {
			onSuccess: (data) => {
				console.log("onSuccess: ", data);
				resolve(data);
			},
			onFailure: (err) => {
				console.error("onError: ", err);
				// alert(err.message || JSON.stringify(err));
				reject(err);
				return;
			},
			newPasswordRequired: (data) => {
				console.log("newPasswordRequired: ", data);
			},
		});
	});
};

export const ConfirmAwsUser = (Username, code) => {
	const user = new CognitoUser({ Username, Pool: UserPool });
	return new Promise((resolve, reject) =>
		user.confirmRegistration(code, true, (err, data) => {
			if (err) {
				alert(err.message || JSON.stringify(err));
				reject(err);
				return;
			}
			console.log("call result: " + data);
			resolve(data);
		})
	);
};

export const ResendCode = (Username) => {
	const user = new CognitoUser({ Username, Pool: UserPool });
	return new Promise((resolve, reject) => {
		user.resendConfirmationCode((err, data) => {
			if (err) {
				alert(err.message || JSON.stringify(err));
				reject(err);
				return;
			}
			console.log("call result: " + data);
			resolve(data);
		});
	});
};

export const ChangeUserPassword = (user, password, newPassword) => {
	// const user = new CognitoUser({ Username, Pool: UserPool });
	// var user = UserPool.getCurrentUser();

	return new Promise((resolve, reject) => {
		user.changePassword(password, newPassword, (err, result) => {
			if (err) {
				alert(err.message || JSON.stringify(err));
				reject(err);
				return;
			}
			resolve(result);
		});
	});
};

export const UserSignOut = (Username) => {
	return new Promise((resolve, reject) => {
		const user = new CognitoUser({ Username, Pool: UserPool });
		user.signOut();
		resolve("Success");
	});
};

export const DeletingAwsUser = (Username) => {
	return new Promise((resolve, reject) => {
		const user = new CognitoUser({ Username, Pool: UserPool });
		user.DeleteUser();
		resolve("Success");
	});
};

export const ForgottenPassword = (Username) => {
	const user = new CognitoUser({
		Username,
		Pool: UserPool,
	});

	return new Promise((resolve, reject) => {
		user.forgotPassword({
			onSuccess: (data) => {
				console.log("CodeDeliveryData from forgotPassword: " + data);
				resolve(data);
			},
			onFailure: (err) => {
				alert(err.message || JSON.stringify(err));
				reject(err);
				return;
			},
		});
	});
};

export const ForgotPasswordVerify = (Username, code, password) => {
	const user = new CognitoUser({
		Username,
		Pool: UserPool,
	});
	return new Promise((resolve, reject) => {
		user.confirmPassword(code, password, {
			onSuccess() {
				console.log("Password confirmed!");
				resolve("success");
			},
			onFailure(err) {
				console.log("Password not confirmed!");
				reject(err);
				return;
			},
		});
	});
};

export const getSession = async () =>
	await new Promise((resolve, reject) => {
		const user = UserPool.getCurrentUser();
		if (user) {
			user.getSession(async (err, session) => {
				if (err) {
					console.error(err);
					alert(err.message || JSON.stringify(err));
					reject(err);
				} else {
					const attributes = await new Promise((resolve, reject) => {
						user.getUserAttributes((err, attributes) => {
							if (err) {
								console.log(err);
								alert(err.message || JSON.stringify(err));
								reject(err);
							} else {
								const results = {};
								for (let attribute of attributes) {
									const { Name, Value } = attribute;
									results[Name] = Value;
								}
								resolve(results);
							}
						});
					});
					resolve({ user, ...session, ...attributes });
				}
			});
		} else {
			alert("No User" || "No User");
			reject("No User");
		}
	});
