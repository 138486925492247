import { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import {
	stableSort,
	getComparator,
	EnhancedTableHead,
} from "../table-sort/TableSort.component";
import TablePaginationActions from "../table-paginatoin/TablePagination.component";
import SearchNdc from "../search-ndc/SearchNdc.component";
import "./onhand-styles.scss"; //Style sheet
// Material Ui
import {
	Table,
	TableBody,
	TableCell,
	TableRow,
	TableFooter,
	TablePagination,
} from "@mui/material";

const headCells = [
	{
		id: "NDC",
		numeric: false,
		disablePadding: false,
		label: "NDC",
	},
	{
		id: "GENERIC",
		numeric: false,
		disablePadding: false,
		label: "Generic",
	},
	{
		id: "DATE_RECEIVED",
		numeric: false,
		disablePadding: false,
		label: "Received",
	},
	{
		id: "QTY_ONHAND",
		numeric: false,
		disablePadding: false,
		label: "Quantity on Hand",
	},
	{
		id: "UNIT_OF_MESURE",
		numeric: false,
		disablePadding: false,
		label: "Unit of Mesurment",
	},
];

export default function OnHandInventory() {
	const navigate = useNavigate();
	const [data, setData] = useState([]);
	const [dataAll, setDataAll] = useState([]);
	const [searchInput, setSearchInput] = useState("");
	const [orderBy, setOrderBy] = useState("NDC");
	const [order, setOrder] = useState("asc");
	const [id, setId] = useState("");
	const url = `https://c99jtoge7d.execute-api.us-east-2.amazonaws.com/dev?procName=GET_INVENTORY_ONHAND&params=1`;
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(5);

	const handleClick = (e, id) => {
		e.preventDefault();
		setId(id);
		navigate(`../items/qty/edit/${id}`, { replace: false });
	};

	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === "asc";
		setOrder(isAsc ? "desc" : "asc");
		setOrderBy(property);
	};

	const handleSearchNdc = (e) => {
		e.preventDefault();
		setSearchInput(e.target.value);
		setData(dataAll);
	};
	const searchItems = (e) => {
		e.preventDefault();
		if (searchInput.length === 13) {
			const filteredData = data.filter((item) => {
				return item.NDC.includes(searchInput);
			});
			console.log("Res: ", filteredData);
			setData(filteredData);
		}
	};

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	useEffect(() => {
		const request = axios({
			method: "GET",
			url,
		});
		request
			.then((res) => {
				if (!res) {
					alert("No item data found!!!");
				} else {
					setData(res.data.result);
					setDataAll(res.data.result);
					console.log("Item data => ", res.data.result);
				}
			})
			.catch((err) => {
				console.error(err);
			});
	}, []);

	return (
		<div className="listContainer">
			<div className="listTitle">
				<SearchNdc
					value={searchInput}
					onChange={handleSearchNdc}
					srchAction={searchItems}
				/>
			</div>
			<Table className="table">
				<EnhancedTableHead
					headCells={headCells}
					order={order}
					orderBy={orderBy}
					onRequestSort={handleRequestSort}
				/>
				<TableBody>
					{stableSort(data, getComparator(order, orderBy))
						.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
						.map((i) => (
							<TableRow
								key={i.ORDR_ID}
								onClick={(event) => handleClick(event, i.ORDR_ID)}
								hover
								className="tableRow"
							>
								<TableCell className="cell">{i.NDC}</TableCell>

								<TableCell className="cell">{i.GENERIC}</TableCell>
								<TableCell className="cell" align="center">
									{i.DATE_RECEIVED}
								</TableCell>
								<TableCell className="cell" align="center">
									{i.QTY_ONHAND}
								</TableCell>

								<TableCell className="cell" align="center">
									{i.UNIT_OF_MESURE}
								</TableCell>
							</TableRow>
						))}
				</TableBody>
				<TableFooter>
					<TableRow className="pagination">
						<TablePagination
							rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
							colSpan={3}
							count={data.length}
							rowsPerPage={rowsPerPage}
							page={page}
							SelectProps={{
								inputProps: {
									"aria-label": "rows per page",
								},
								native: true,
							}}
							onPageChange={handleChangePage}
							onRowsPerPageChange={handleChangeRowsPerPage}
							ActionsComponent={TablePaginationActions}
						/>
					</TableRow>
				</TableFooter>
			</Table>
		</div>
	);
}
