import "./chart-styles.scss";
import {
	AreaChart,
	Area,
	XAxis,
	Tooltip,
	ResponsiveContainer,
	LineChart,
	Line,
	YAxis,
	CartesianGrid,
	Legend,
	BarChart,
	Bar,
	Cell,
} from "recharts";

export function Chart({ aspect, title, data, dataKeyX, dataKeyY }) {
	return (
		<div className="chart">
			<div className="title">{title}</div>
			<ResponsiveContainer width="100%" aspect={aspect}>
				<AreaChart
					width={730}
					height={250}
					data={data}
					margin={{
						top: 20,
						right: 20,
						bottom: 20,
						left: 20,
					}}
				>
					<XAxis dataKey={dataKeyX} stroke="gray" />
					<Area dataKey={dataKeyY} stroke="#8884d8" fill="#8884d8" />
					<Tooltip />
				</AreaChart>
			</ResponsiveContainer>
		</div>
	);
}

export function LinerChrt({ data, dataKeyX, dataKeyY, aspect }) {
	return (
		<ResponsiveContainer aspect={aspect}>
			<LineChart width={500} height={300} data={data}>
				<XAxis dataKey={dataKeyX} />
				<YAxis dataKey={dataKeyY} />
				<CartesianGrid stroke="#eee" />
				<Line type="monotone" dataKey={dataKeyY} stroke="#8884d8" />
			</LineChart>
		</ResponsiveContainer>
	);
}

export const BarChrt = ({ data, dataKeyX, dataKeyY, aspect, title }) => {
	return (
		<div className="chart">
			<div className="title">{title}</div>
			<ResponsiveContainer aspect={aspect}>
				<BarChart width={500} height={300} data={data}>
					<Bar dataKey={dataKeyY} fill="#8884d8" />
					<YAxis dataKey={dataKeyY} />
					<XAxis dataKey={dataKeyX} />
					<CartesianGrid stroke="#eee" />
				</BarChart>
			</ResponsiveContainer>
		</div>
	);
};
