import React, { useState, useEffect } from "react";
import axios from "axios";
import { useSelector } from "react-redux";

import { UpdateBatchUid, UpdateBatch } from "../../utils/BatchHelper";
import "./millbatch-styles.scss"; //Style sheet
//Material Ui
import {
	Table,
	TableBody,
	TableHead,
	TableRow,
	TableCell,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

export default function MillBatch() {
	const [batchArray, setBatchArray] = useState([]);
	const [count, setCount] = useState(0);
	const zeroPad = (num, places) => String(num).padStart(places, "0");
	const user = useSelector((state) => state.user.user);

	useEffect(() => {
		const url = `https://c99jtoge7d.execute-api.us-east-2.amazonaws.com/dev/batch?procName=GET_BATCH_MILLING`;
		const request = axios({
			method: "GET",
			url,
		});
		request.then(async (res) => {
			console.log("GET API: ", JSON.stringify(res.data.result));
			setBatchArray(res.data.result);
			if (res.data.result.length === 0) {
				console.log("No batches");
			} else {
				setBatchArray(res.data.result);
			}
		});
	}, [count]);

	const handleSave = async (e, id, index) => {
		e.preventDefault();

		let status = "MILLED";
		var params = [id, status];
		let procName = "UPDATE_BATCH_STATUS";
		UpdateBatch(procName, params)
			.then(() => {
				UpdateBatchUid(id, user.EMAIL, "MILL_ID")
					.then((resp) => {
						console.log("Resp: ", resp);
						alert("Batch Milling is recorded");
						let updateArray = batchArray.splice(index, 1);
						setBatchArray(updateArray);
						setCount(count + 1);
					})
					.catch((err) => {
						alert(`Severe DB Error! ${err}`);
					});
			})
			.catch((err) => {
				alert(`Severe DB Error ${err}`);
				return;
			});
	};

	return (
		<div className="millWrapper">
			<div className="title">
				<span className="subtitle">
					Please select a batch to mill by clicking on a corresponding row in
					the table below.
				</span>
			</div>
			<Table className="millTable">
				<TableHead className="millHdr">
					<TableRow>
						<TableCell className="millHdrCell">Action</TableCell>
						<TableCell className="millHdrCell">Batch ID</TableCell>
						<TableCell className="millHdrCell">Formula</TableCell>
						<TableCell className="millHdrCell">Quantity</TableCell>
						<TableCell className="millHdrCell">Date Created</TableCell>
					</TableRow>
				</TableHead>

				<TableBody>
					{batchArray.map((batch, index) => (
						<TableRow key={index} hover className="millingRow">
							<TableCell align="left">
								<button onClick={(e) => handleSave(e, batch.ID, index)}>
									<CheckCircleIcon />
									DONE
								</button>
							</TableCell>
							<TableCell>
								{`${batch.BATCH_DATE}@${zeroPad(batch.SEQ, 2)}`}
							</TableCell>
							<TableCell align="left">{batch.NAME}</TableCell>

							<TableCell align="left">{batch.QTY}</TableCell>
							<TableCell align="left">{batch.TIMESTAMP}</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
		</div>
	);
}
