import { useEffect, useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import BatchPackingTbl from "../batch-packing-tbl/BatchPackingTbl.component";
import { UpdateBatchUid, UpdateBatch } from "../../utils/BatchHelper";
import Loading from "../loading-component/Loading.component";
import "./packbatch-styles.scss";
import SaveIcon from "@mui/icons-material/Save";

export default function PackBatch() {
	const navigate = useNavigate();
	const user = useSelector((state) => state.user.user);
	const { id } = useParams();
	const [batch, setBatch] = useState({});
	const [arrayPack, setArrayPack] = useState([]);
	const [lot, setLot] = useState("");
	const [bDate, setBdate] = useState("");
	const [wgtArr, setWgtArr] = useState([]);
	const [updCntr, setUpdtCntr] = useState(0);
	const [printLabel, setPrintLabel] = useState({});
	const [loading, setLoading] = useState(true);

	const zeroPad = (num, places) => String(num).padStart(places, "0");

	const handleSave = (e) => {
		e.preventDefault();

		if (arrayPack.length === 0) {
			alert("Error: At least one package is required for this batch!");
			return;
		}

		arrayPack.map((pack) => {
			const { batchSeq, weight } = pack;
			let params = [id, batchSeq, weight];
			let procName = "ADD_BATCH_PACKS";
			UpdateBatch(procName, params)
				// .then((resp) => {})
				.catch((err) => {
					alert(`DB error: ${err}`);
					return;
				});
		});

		let status = "PACKED";
		var params = [id, status];
		let procName = "UPDATE_BATCH_STATUS";
		UpdateBatch(procName, params)
			.then(() => {
				UpdateBatchUid(id, user.EMAIL, "PACK_ID")
					.then((resp) => {
						alert("Batch Packaging is recorded");
						navigate("../batch-pack");
					})
					.catch((err) => {
						alert(`Severe DB Error! ${err}`);
					});
			})
			.catch((err) => {
				alert(`Severe DB Error ${err}`);
				return;
			});
	};

	useEffect(() => {
		const url = `https://c99jtoge7d.execute-api.us-east-2.amazonaws.com/dev?procName=GET_BATCH_HDR&params=${id}`;

		const request = axios({
			method: "GET",
			url,
		});
		request
			.then((res) => {
				if (!res) {
					alert("No item data found!!!");
					setLoading(false);
				} else {
					// console.log("Batch Data: ", res.data.result[0]);
					setLoading(false);
					setBatch(res.data.result[0]);
					setLot(
						`${res.data.result[0].BATCH_DATE}@${zeroPad(
							res.data.result[0].SEQ,
							2
						)}`
					);
					setBdate(res.data.result[0].TIMESTAMP.split(" ")[0]);
					var lot = `${res.data.result[0].BATCH_DATE}@${zeroPad(
						res.data.result[0].SEQ,
						2
					)}`;
					let { NAME, BUD } = res.data.result[0];
					setPrintLabel({ NAME, BUD, lot });
				}
			})
			.catch((err) => {
				console.error(err);
				setLoading(false);
			});
	}, [id]);

	return (
		<>
			{loading ? (
				<Loading />
			) : (
				<div className="packBatchWrapper">
					<div className="title">
						<span className="subtitle">Package batch </span>
						<span className="subtitle">{printLabel.lot}</span>
						<span className="subtitle">{printLabel.NAME}</span>
					</div>

					<BatchPackingTbl
						id={id}
						wgtArr={wgtArr}
						setWgtArr={setWgtArr}
						updCntr={updCntr}
						setUpdtCntr={setUpdtCntr}
						arrayPack={arrayPack}
						setArrayPack={setArrayPack}
					/>
					<div className="buttonSave">
						<button className="saveBtn" onClick={handleSave}>
							<SaveIcon />
							Save batch
						</button>
					</div>
				</div>
			)}
		</>
	);
}
