import { useState } from "react";
import { UpdateBatch } from "../../utils/BatchHelper";
import DialogComponent from "../dialog/DialogComponent";
import "./batchinventory-styles.scss"; //Style sheet
//Material Ui
import { IconButton, TextField, TableRow, TableCell } from "@mui/material";
import { Save, DeleteForever } from "@mui/icons-material";

export default function BatchInventoryRow({ batch, index, upd, setUpd }) {
	const [rx, setRx] = useState(batch.RX);
	const zeroPad = (num, places) => String(num).padStart(places, "0");
	const [open, setOpen] = useState(false);
	const [dlgTitle, setDlgTitle] = useState("");
	const [action, setAction] = useState("");
	const lot = `${batch.BATCH_DATE}@${zeroPad(batch.SEQ, 2)}`;

	const handleUpdate = () => {
		setAction("Update");
		setDlgTitle("Update Rx Number");
		setOpen(true);
	};
	const handleDelete = () => {
		setAction("Delete");
		setDlgTitle("Delete Rx Number");
		setOpen(true);
	};

	const handleClickConfirm = () => {
		let procName;
		let params;
		if (action === "Update") {
			procName = "UPDATE_RX_PACKAGE";
			params = [batch.ID, rx];
		} else {
			procName = "DELETE_RX_PACKAGE";
			params = [batch.ID];
		}
		UpdateBatch(procName, params)
			.then((resp) => {
				if (!resp.data.errorMessage) {
					alert(`Successful ${action} for ${rx} record`);
					setOpen(false);
					setUpd(upd + 1);
				}
			})
			.catch((err) => {
				alert(`Severe Db error ${err}`);
				setOpen(false);
			});
	};

	return (
		<>
			<TableRow key={index} hover className="invTblRow">
				<TableCell className="invTblCell">
					<IconButton style={{ color: "green" }} onClick={handleUpdate}>
						<Save />
					</IconButton>
					/
					<IconButton style={{ color: "red" }} onClick={handleDelete}>
						<DeleteForever />
					</IconButton>
				</TableCell>
				<TableCell align="left" className="invTblCell">
					{lot}
				</TableCell>
				<TableCell align="left" className="invTblCell">
					{batch.NDC}
				</TableCell>
				<TableCell align="left" className="invTblCell">
					{batch.NAME}
				</TableCell>
				<TableCell align="left" className="invTblCell">
					<TextField
						variant="outlined"
						size="small"
						sx={{
							width: "15ch",
							backgroundColor: "#FFFFFF",
							borderRadius: "4px",
						}}
						value={rx}
						onChange={(e) => setRx(e.target.value)}
					/>
				</TableCell>
				<TableCell align="left" className="invTblCell">
					{batch.WEIGHT} g
				</TableCell>
				<TableCell align="left" className="invTblCell">
					{batch.BUD}
				</TableCell>
			</TableRow>
			<DialogComponent
				open={open}
				setOpen={setOpen}
				dlgTitle={dlgTitle}
				dlgAction={action}
				handleClickOk={handleClickConfirm}
			/>
		</>
	);
}
