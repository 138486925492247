import PrinterLabelWrapper from "../print-label/PrintLabelWrapper.component";
import BatchLabel from "../batch-label/BatchLabel.component";
import "./batchheader-styles.scss";

export default function BatchHeader({ batch, pumps }) {
	const zeroPad = (num, places) => String(num).padStart(places, "0");
	const { NAME, BATCH_DATE, SEQ, NDC, QTY, BUD, MIX_SETTINGS, JAR } = batch;
	const lot = `${BATCH_DATE}@${zeroPad(SEQ, 2)}`;
	const batch_lbl = { NAME, lot, NDC, QTY, BUD, MIX_SETTINGS, JAR, pumps };

	return (
		<div className="batchHdrWrapper">
			<div className="hdrTitle">
				<span className="ndc">Product NDC: {NDC}</span>

				<span className="timeStamp">Time stamp: {batch.TIMESTAMP}</span>
			</div>
			<div className="printWrapper">
				<PrinterLabelWrapper>
					<BatchLabel batch={batch_lbl} />
				</PrinterLabelWrapper>
			</div>
		</div>
	);
}
