import { useEffect, useState } from "react";
import axios from "axios";
import "./system-styles.scss";
import TablePaginationActions from "../table-paginatoin/TablePagination.component";
import DialogComponent from "../dialog/DialogComponent";
import { Link } from "react-router-dom";
//Material Ui
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { TableFooter } from "@mui/material";
import TablePagination from "@mui/material/TablePagination";
import { IconButton } from "@mui/material";
import { Delete, Add } from "@mui/icons-material";

export default function SystemResources() {
	const [data, setData] = useState([]);
	const [id, setId] = useState("");
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(5);
	const url = `https://c99jtoge7d.execute-api.us-east-2.amazonaws.com/dev?procName=GET_ALL_SYS_RESOURCES&params=1`;
	const [open, setOpen] = useState(false);
	const dlgTitle = "Delete Sys Resource";
	const [deleteSw, setDeleteSw] = useState(false);

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const handleDelete = (e, id) => {
		e.preventDefault();
		setId(id);
		setOpen(true);
		// console.log("Delete");
	};

	const deleteResource = (e, id) => {
		console.log(" Delete Resource ");
		e.preventDefault();

		setOpen(false);
		const url = "https://c99jtoge7d.execute-api.us-east-2.amazonaws.com/dev";
		let procName = "DELETE_SYS_RESOURCE";
		let params = [id];
		var resourceData = {
			procName,
			params,
		};
		const request = axios({
			method: "POST",
			url,
			data: resourceData,
		});
		request
			.then((res) => {
				alert(`Resource ${id} delted succesfully`);
				setDeleteSw(true);
			})
			.catch((err) => {
				alert(`Severe error ${err}`);
			});
	};

	useEffect(() => {
		const request = axios({
			method: "GET",
			url,
		});
		request
			.then((res) => {
				if (!res) {
					alert("No user data found!!!");
				} else {
					setData(res.data.result);
					// console.log("user data => ", res.data.result);
				}
			})
			.catch((err) => {
				console.error(err);
			});
	}, [deleteSw]);

	return (
		<div className="container">
			<div className="datatableTitle">
				<Link to="../system/new" className="link">
					<Add /> Add New
				</Link>
			</div>

			{/* <Table className="table">
				<TableHead className="header">
					<TableRow className="headerRow">
						<TableCell className="headerCell">Resouce Id</TableCell>
						<TableCell className="headerCell">Resource</TableCell>
						<TableCell className="headerCell">Resource Type</TableCell>
						<TableCell className="headerCell">Action</TableCell>
					</TableRow>
				</TableHead>

				<TableBody>
					{(rowsPerPage > 0
						? data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
						: data
					).map((user, index) => (
						<TableRow key={index} className="tableRow">
							<TableCell
								component="th"
								align="center"
								scope="row"
								className="cell"
							>
								{user.ID}
							</TableCell>
							<TableCell
								component="th"
								align="center"
								scope="row"
								className="cell"
							>
								{user.RESOURCE}
							</TableCell>
							<TableCell
								component="th"
								align="center"
								scope="row"
								className="cell"
							>
								{user.RSOURCE_TYPE}
							</TableCell>

							<TableCell
								component="th"
								align="center"
								scope="row"
								className="cell"
							>
								<IconButton
									aria-label="Delete"
									className="delete"
									onClick={(e) => {
										handleDelete(e, user.ID);
									}}
								>
									<Delete />
								</IconButton>
							</TableCell>
						</TableRow>
					))}
				</TableBody>

				<TableFooter>
					<TableRow className="pagination">
						<TablePagination
							rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
							colSpan={3}
							count={data.length}
							rowsPerPage={rowsPerPage}
							page={page}
							SelectProps={{
								inputProps: {
									"aria-label": "rows per page",
								},
								native: true,
							}}
							onPageChange={handleChangePage}
							onRowsPerPageChange={handleChangeRowsPerPage}
							ActionsComponent={TablePaginationActions}
						/>
					</TableRow>
				</TableFooter>
			</Table> */}

			<Table className="table">
				<TableHead>
					<TableRow className="headerRow">
						<TableCell className="headerCell">Resource ID</TableCell>
						<TableCell className="headerCell">Resource</TableCell>
						<TableCell className="headerCell">Resource Type</TableCell>
						<TableCell className="headerCell">Action</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{(rowsPerPage > 0
						? data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
						: data
					).map((user, index) => (
						<TableRow key={index} className="tableRow">
							<TableCell
								component="th"
								align="center"
								scope="row"
								className="cell"
							>
								{user.ID}
							</TableCell>
							<TableCell
								component="th"
								align="center"
								scope="row"
								className="cell"
							>
								{user.RESOURCE}
							</TableCell>
							<TableCell
								component="th"
								align="center"
								scope="row"
								className="cell"
							>
								{user.RSOURCE_TYPE}
							</TableCell>

							<TableCell
								component="th"
								align="center"
								scope="row"
								className="cell"
							>
								<IconButton
									aria-label="Delete"
									className="delete"
									onClick={(e) => {
										handleDelete(e, user.ID);
									}}
								>
									<Delete />
								</IconButton>
							</TableCell>
						</TableRow>
					))}
				</TableBody>
				<TableFooter>
					<TableRow className="pagination">
						<TablePagination
							rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
							colSpan={3}
							count={data.length}
							rowsPerPage={rowsPerPage}
							page={page}
							SelectProps={{
								inputProps: {
									"aria-label": "rows per page",
								},
								native: true,
							}}
							onPageChange={handleChangePage}
							onRowsPerPageChange={handleChangeRowsPerPage}
							ActionsComponent={TablePaginationActions}
						/>
					</TableRow>
				</TableFooter>
			</Table>

			<DialogComponent
				open={open}
				setOpen={setOpen}
				dlgTitle={dlgTitle}
				dlgAction={"Delete"}
				id={id}
				handleClickOk={deleteResource}
			/>
		</div>
	);
}
