import { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import "./user-styles.scss";
import imgUrl from "../../assets/img/computer-users-transparent-icon-computer-and-user-icon.png";
import { Save } from "@mui/icons-material";

export default function UserDetail() {
	const { userid } = useParams();
	const [data, setData] = useState([]);
	const [roleArray, setRoleArray] = useState([]);
	const [fName, setFname] = useState("");
	const [lName, setLname] = useState("");
	const [uname, setUname] = useState("");
	const [phone, setPhone] = useState("");
	const [role, setRole] = useState("");

	const handleSave = async (e) => {
		e.preventDefault();
		console.log("Save");
		const url =
			"https://c99jtoge7d.execute-api.us-east-2.amazonaws.com/dev/batch";
		const procName = "UPDATE_USER";
		const params = [userid, fName, lName, phone, role];
		let data = { procName, params };
		axios
			.put(url, JSON.stringify(data))
			.then((resp) => {
				console.log("Resp: ", resp);
				if (resp.data.errorType) {
					alert(`DB Error occured ${resp.data.errorMessage}`);
				} else {
					alert(`User ${data.EMAIL} was updated successfully!`);
				}
			})
			.catch((err) => {
				console.error("Problem with Update: ", err);
				alert(`Error ${err} update was not successful!`);
			});
	};

	useEffect(() => {
		const url = `https://c99jtoge7d.execute-api.us-east-2.amazonaws.com/dev?procName=GET_USER_INFO_BY_ID&params=${userid}`;

		console.log("URL: ", url);

		const request = axios({
			method: "GET",
			url,
		});

		request
			.then((res) => {
				if (!res) {
					alert("No User data is found!!!");
				} else {
					setData(res.data.result[0]);
					console.log("User Data: ", res.data.result[0]);
					console.log("Params: ", userid);
					setFname(res.data.result[0].FIRST_NAME);
					setLname(res.data.result[0].LAST_NAME);

					setUname(
						`${res.data.result[0].LAST_NAME},${res.data.result[0].FIRST_NAME}`
					);
					setPhone(res.data.result[0].PHONE);
					setRole(res.data.result[0].ROLEID);

					// setLoader(true);
				}
			})
			.catch((err) => {
				console.error(err);
			});
	}, [userid]);

	useEffect(() => {
		const url = `https://c99jtoge7d.execute-api.us-east-2.amazonaws.com/dev?procName=GET_ALL_ROLES&params=1`;

		const request = axios({
			method: "GET",
			url,
		});
		request
			.then((res) => {
				if (!res) {
					alert("No User data is found!!!");
				} else {
					setRoleArray(res.data.result);
					// console.log("ROLES : ", res.data.result);
					// setLoader(true);
				}
			})
			.catch((err) => {
				console.error(err);
			});
	}, [userid]);

	return (
		<div className="userContainer">
			<div className="left">
				<div className="item">
					<img src={imgUrl} alt="" className="itemImg" />
					<div className="details">
						<h1 className="itemTitle">{uname}</h1>
						<div className="itemDetail">
							<span className="itemKey">Email:</span>
							<span className="itemValue">{data.EMAIL}</span>
						</div>
						<div className="itemDetail">
							<span className="itemKey">Phone:</span>
							<span className="itemValue">{data.PHONE}</span>
						</div>
						<div className="roleSelect">
							<label htmlFor="role-select">Choose a role:</label>

							<select
								id="role-select"
								className="select"
								value={role}
								onChange={(e) => setRole(e.target.value)}
							>
								<option value="">--Please choose a role--</option>
								{roleArray.map((r) => (
									<option key={r.ROLE_ID} value={r.ROLE_ID}>
										{r.ROLE_NAME}
									</option>
								))}
							</select>
						</div>
						<button className="save" onClick={handleSave}>
							<span className="shadow"></span>
							<span className="edge"></span>
							<span className="front text">
								<Save />
								Save
							</span>
						</button>
					</div>
				</div>
			</div>
		</div>
	);
}
