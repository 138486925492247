import { useState } from "react";
import "./navbar-styles.scss";
import Logo from "../../assets/img/logo-white.png";
import { UserSignOut } from "../../utils/aws-cognito.js";
import { deleteUser } from "../../store/actions/user.actions.js";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
//Material Ui
import { IconButton, MenuItem, Menu } from "@mui/material";
import {
	AccountCircle,
	LanguageOutlined,
	ExitToAppOutlined,
} from "@mui/icons-material";

export default function NavBar() {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const user = useSelector((state) => state.user.user);
	const [anchorEl, setAnchorEl] = useState(null);

	const handleChangePassword = () => {
		navigate("/edit/user-password", { replace: false });
	};

	const handleMenuClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleProfileMenu = () => {
		setAnchorEl(!anchorEl);
	};

	const handleLogout = async () => {
		await UserSignOut(user.EMAIL)
			.then((data) => {
				console.log(data);
				dispatch(deleteUser());
				navigate("../login", { replace: true });
			})
			.catch((err) => {
				alert(`Sever error ${err}`);
				console.log(err);
			});
	};

	return (
		<div className="navbar">
			<div className="wrapper">
				<div className="logoContainer">
					<img src={Logo} alt="logo" className="logo" />
				</div>
				<div className="titleContainer">
					<div className="title">COMPOUNDING SOLUTIONS</div>
				</div>
				<div className="items">
					<div className="item">
						<LanguageOutlined className="icon" />
						English
					</div>

					{user ? (
						<div className="item">
							<IconButton disableRipple onClick={handleMenuClick}>
								<AccountCircle className="icon" />
							</IconButton>
							<div className="tooltip">
								{user.FIRST_NAME} {user.LAST_NAME}
							</div>
							<Menu
								id="menu-appbar"
								anchorEl={anchorEl}
								keepMounted
								open={Boolean(anchorEl)}
								onClose={handleProfileMenu}
							>
								<MenuItem onClick={handleChangePassword}>
									Change password
								</MenuItem>
							</Menu>
						</div>
					) : null}
					<div className="item">
						<IconButton disableRipple onClick={handleLogout}>
							<div className="tooltip">
								<ExitToAppOutlined className="icon" />
								<span className="tooltiptext">Logout</span>
							</div>
						</IconButton>
					</div>
				</div>
			</div>
		</div>
	);
}
