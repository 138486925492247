import { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "./createrole-styles.scss";
import imgUrl from "../../assets/img/user-roles.png";

export default function CreateRole() {
	const navigate = useNavigate();
	const [role, setRole] = useState("");
	const [descr, setDescr] = useState("");

	const handleSave = async (e) => {
		e.preventDefault();
		var params = [role, descr];
		let procName = "CREATE_USER_ROLE";
		const url = "https://c99jtoge7d.execute-api.us-east-2.amazonaws.com/dev";
		const data = { procName, params };
		axios
			.post(url, JSON.stringify(data))
			.then((resp) => {
				if (
					resp.data.result[0].ROWS_ADDED &&
					resp.data.result[0].ROWS_ADDED === 1
				) {
					// console.log("Resp Data", resp.data.result[0]);
					alert("Role is added successfully!");
					navigate("/users/roles", { replace: true });
				} else {
					alert(`Error! ${resp.data.result[0]}`);
				}
			})
			.catch((err) => {
				alert(`Error Add: ${err}`);
			});
	};

	return (
		<div className="roleContainer">
			<div className="left">
				<img src={imgUrl} alt="roles" />
			</div>
			<div className="right">
				<form className="form" onSubmit={handleSave}>
					<div className="formInput">
						<label>Role</label>
						<input
							type="text"
							value={role}
							onChange={(e) => setRole(e.target.value)}
						/>
					</div>
					<div className="formInput">
						<label>Descrition</label>
						<input
							type="text"
							value={descr}
							onChange={(e) => setDescr(e.target.value)}
						/>
					</div>
					<div className="save">
						<button type="submit">Save</button>
					</div>
				</form>
			</div>
		</div>
	);
}
