import { useState, useEffect } from "react";
import axios from "axios";
import InputMask from "react-input-mask";
import "./liftformula-styles.scss";
import FromulaIngridRow from "../formula-ingrid-row/FormulaIngridRow.component";
import {
	stableSort,
	getComparator,
	EnhancedTableHead,
} from "../table-sort/TableSort.component";
//Material Ui
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";

const headCells = [
	{
		id: "NAME",
		numeric: false,
		disablePadding: false,
		label: "Product Name",
	},
	{
		id: "PROD_NDC",
		numeric: false,
		disablePadding: false,
		label: "Product NDC",
	},
	{
		id: "PRODUCT_TYPE",
		numeric: false,
		disablePadding: false,
		label: "Product Form",
	},
];

function createData(data) {
	var fromList = [];
	var id = "";

	data.map((product, i) => {
		const {
			ID,
			PROD_NDC,
			NAME,
			PRODUCT_TYPE,
			NDC,
			GENERIC,
			FORM,
			AMOUNT,
			UNIT_MESURE,
		} = product;

		if (id !== product.ID) {
			let ingrids = [{ NDC, GENERIC, FORM, AMOUNT, UNIT_MESURE }];
			let prod = { ID, PROD_NDC, NAME, PRODUCT_TYPE, ingrids };
			fromList.push(prod);
			id = product.ID;
		} else {
			let ingrids = { NDC, GENERIC, FORM, AMOUNT, UNIT_MESURE };
			fromList[fromList.length - 1].ingrids.push(ingrids);
		}
	});

	return fromList;
}

export default function ListFormula() {
	const [data, setData] = useState([]);
	const [dataAll, setDataAll] = useState([]);
	const [searchInput, setSearchInput] = useState("");
	const [searchAmnt, setSearchAmnt] = useState("");
	const [srchName, setSrchName] = useState("");
	const [order, setOrder] = useState("asc");
	const [orderBy, setOrderBy] = useState("GENERIC_NAME");

	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === "asc";
		setOrder(isAsc ? "desc" : "asc");
		setOrderBy(property);
	};

	const handleSearchNdc = (e) => {
		e.preventDefault();
		setSearchInput(e.target.value);
		setSearchAmnt("");
		setData(dataAll);
	};

	const handleSearchName = (e) => {
		e.preventDefault();
		setSrchName(e.target.value);
		setSearchInput("");
		setSearchAmnt("");
		setData(dataAll);
	};

	const searchItems = (e) => {
		e.preventDefault();
		if (searchInput.length === 13) {
			if (searchAmnt.length === 0) {
				const filteredData = data.filter((item) => {
					return item.ingrids.find((ingrid) => ingrid.NDC === searchInput);
				});
				// console.log("Res: ", filteredData);
				setData(filteredData);
			} else {
				const filteredData = data.filter((item) => {
					return item.ingrids.find(
						(ingrid) =>
							ingrid.NDC === searchInput &&
							Number(ingrid.AMOUNT) === Number(searchAmnt)
					);
				});
				// console.log("Res: ", filteredData);
				setData(filteredData);
			}
		} else {
			if (srchName.length > 0) {
				const filteredData = data.filter((item) => {
					return item.ingrids.find(
						(ingrid) =>
							ingrid.GENERIC.trim().toLowerCase() ==
							srchName.trim().toLowerCase()
					);
				});
				// console.log("Res: ", filteredData);
				setData(filteredData);
			}
		}
	};

	useEffect(() => {
		const request = axios({
			method: "GET",
			url: `https://c99jtoge7d.execute-api.us-east-2.amazonaws.com/dev/formula?procName=GET_ALL_FORMULA&params=1`,
		});
		request
			.then((res) => {
				if (!res) {
					alert("No Formula data found!!!");
				} else {
					console.log("Data: ", res.data.result);
					setData(createData(res.data.result));
					setDataAll(createData(res.data.result));
				}
			})
			.catch((err) => {
				console.error(err);
			});
	}, []);

	return (
		<div className="list-formula-wrapper">
			<div className="search-title">
				<span className="subtitle">
					Filter list - Search by Ingridient NDC or NAME
				</span>
			</div>
			<div className="srch-box">
				<div className="srch-dtl">
					<div className="srchItem">
						<label htmlFor="ndc" className="srch_date_lbl">
							Search NDC:
						</label>
						<InputMask
							mask="****9-9999-99"
							value={searchInput}
							onChange={handleSearchNdc}
							className="ndc"
						>
							<input type="text" id="ndc" className="srch-ndc" autoFocus />
						</InputMask>
					</div>

					<div className="srchItem">
						<label htmlFor="amount"> %</label>
						<input
							type="text"
							id="amount"
							className="percent"
							value={searchAmnt}
							onChange={(e) => setSearchAmnt(e.target.value)}
						/>
					</div>
					{!searchInput ? (
						<div className="srchItem">
							<label htmlFor="srchName">Name:</label>
							<input
								type="text"
								className="name"
								id="srchName"
								value={srchName}
								onChange={handleSearchName}
							/>
						</div>
					) : null}
				</div>
				<div className="search-box">
					<button className="srchBtn" onClick={searchItems}>
						<SearchOutlinedIcon className="searchIcon" /> Filter list
					</button>
				</div>
			</div>

			<div className="list-fomrula-container">
				{data.length ? (
					<div>
						<Table aria-label="formula table" className="list-table">
							<EnhancedTableHead
								checkbox={true}
								headCells={headCells}
								order={order}
								orderBy={orderBy}
								onRequestSort={handleRequestSort}
							/>
							<TableBody className="tBody">
								{stableSort(data, getComparator(order, orderBy)).map(
									(row, i) => (
										<FromulaIngridRow key={i} row={row} />
									)
								)}
							</TableBody>
						</Table>
					</div>
				) : (
					<span className="empty-message"> No Formulations Availible</span>
				)}
			</div>
		</div>
	);
}
