import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import "./sidebar-styles.scss";
// Navigation components
import {
	homecategories,
	seccategories,
	inventorycategories,
	labcategories,
} from "./categories";
import { Link, useNavigate } from "react-router-dom";
//Material Ui
import {
	List,
	ListItem,
	ListItemText,
	ListItemIcon,
	Divider,
} from "@mui/material";

export default function SideBar({ source }) {
	const navigate = useNavigate();
	const [perms, setPerms] = useState({});
	const user = useSelector((state) => state.user.user);
	const role = user.ROLEID;

	const handleNavigate = (link) => {
		navigate(link, { replace: false });
	};

	let categories = "";
	switch (source) {
		case "HOME":
			categories = homecategories;
			break;
		case "SEC":
			categories = seccategories;
			break;
		// case "RX":
		// 	categories = rxcategories;
		// 	break;
		case "LAB":
			categories = labcategories;
			break;
		case "IMS":
			categories = inventorycategories;
			break;
		default:
			categories = homecategories;
			break;
	}

	useEffect(() => {
		const url = `https://c99jtoge7d.execute-api.us-east-2.amazonaws.com/dev?procName=GET_ROLE_PERMISSION_BY_ID&params=${role}`;

		//
		const request = axios({
			method: "GET",
			url,
		});
		request
			.then((res) => {
				if (!res) {
					alert("No Menu data found!!!");
				} else {
					// console.log("Data: ", res.data.result);

					const permObject = res.data.result.reduce(
						(id, cur) => ({ ...id, [cur.RESOURCE_ID]: cur.RESOURCE_ID }),
						{}
					);

					setPerms(permObject);

					// console.log("RESOURCE Object: ", permObject);
				}
			})
			.catch((err) => {
				console.error(err);
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className="sidebar">
			<div className="top">
				<Link to="/" className="link">
					<span className="title">Levin's Drugs</span>
				</Link>
			</div>
			<List>
				{categories.map(({ id, children }) => (
					<div key={id}>
						<ListItem className="categoryHeader">
							<ListItemText className="categoryHeaderText">{id}</ListItemText>
						</ListItem>
						{children.map(({ id: childId, icon, active, link, resource }) => (
							<div key={childId}>
								{perms.hasOwnProperty(resource) ? (
									<ListItem
										button
										component="a"
										// href={link}
										onClick={(e) => handleNavigate(link)}
										dense
										className="item"
									>
										<ListItemIcon className="itemIcon">{icon}</ListItemIcon>
										<ListItemText className="itemText">{childId}</ListItemText>
									</ListItem>
								) : null}
							</div>
						))}
						<Divider className="divider" />
					</div>
				))}
			</List>
		</div>
	);
}
