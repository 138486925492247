import { useState } from "react";
import axios from "axios";
import {
	stableSort,
	getComparator,
	EnhancedTableHead,
} from "../table-sort/TableSort.component";
import { GrandTotal } from "../grand-total/GrandTotal.component";
import Loader from "../loader/Loader.component";
import TablePaginationActions from "../table-paginatoin/TablePagination.component";
import PoHistoryRow from "../po-hisotry-row/PoHistoryRow.component";

import "./pohistory-styles.scss"; // Style sheet

import {
	Table,
	TableBody,
	TableRow,
	TableFooter,
	TablePagination,
} from "@mui/material";
import { SearchOutlined } from "@mui/icons-material";

const headCells = [
	{
		id: "DATE_RECEIVED",
		numeric: false,
		disablePadding: false,
		label: "Date Received",
	},
	{
		id: "PO_ID",
		numeric: false,
		disablePadding: false,
		label: "Purchase Order",
	},
	{
		id: "VENDOR_NAME",
		numeric: false,
		disablePadding: false,
		label: "Vendor Name",
	},

	{
		id: "",
		numeric: false,
		disablePadding: false,
		label: "PO Total",
	},
];

function createData(data) {
	var poList = [];
	var id = "";

	// console.log("Data: ", data);
	data.map((r) => {
		const {
			ORDR_ID,
			QTY,
			QTY_RECIVED,
			DATE_RECEIVED,
			ORDR_DT,
			COST,
			PO_ID,
			NDC,
			GENERIC,
			VENDOR_NAME,
		} = r;

		if (id !== PO_ID) {
			let itemRow = [
				{
					ORDR_ID,
					DATE_RECEIVED,
					ORDR_DT,
					NDC,
					GENERIC,
					QTY,
					QTY_RECIVED,
					COST,
				},
			];
			let vendorRow = { DATE_RECEIVED, PO_ID, VENDOR_NAME, itemRow };
			poList.push(vendorRow);
			id = PO_ID;
		} else {
			let itemRow = {
				ORDR_ID,
				DATE_RECEIVED,
				ORDR_DT,
				NDC,
				GENERIC,
				QTY,
				QTY_RECIVED,
				COST,
			};
			poList[poList.length - 1].itemRow.push(itemRow);
		}
	});

	return poList;
}

export default function PoHistory() {
	const [startDt, setStartDt] = useState("");
	const [endDt, setEndDt] = useState("");
	const [data, setData] = useState([]);
	const [loader, setLoader] = useState(true);
	const [totalData, setTotalData] = useState(0);
	const [order, setOrder] = useState("asc");
	const [orderBy, setOrderBy] = useState("DATE_RECEIVED");
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(5);

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const handleSearch = (e) => {
		e.preventDefault();
		setLoader(false);
		if (!startDt || !endDt) {
			alert("Date Range is missing");
			return;
		}
		if (startDt > endDt) {
			alert("From date can not be less than To date");
			return;
		}
		getReportData()
			.then((resp) => {
				setData(createData(resp));
				setTotalData(resp);
				setLoader(true);
			})
			.catch((err) => {
				alert(`Problem with data: ${err}`);
			});
	};

	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === "asc";
		setOrder(isAsc ? "desc" : "asc");
		setOrderBy(property);
	};

	const getReportData = () => {
		const params = `${startDt}'%2C'${endDt}`;
		const url = `https://c99jtoge7d.execute-api.us-east-2.amazonaws.com/dev/rx?procName=GET_ORDERS_BY_DATE_RANGE&params=${params}`;
		const request = axios({
			method: "GET",
			url,
		});

		return new Promise((resolve, reject) => {
			request
				.then((resp) => {
					if (resp.data.result.length === 0) {
						alert("No order data is found!!!");
					} else {
						resolve(resp.data.result);
					}
				})
				.catch((err) => {
					reject(err);
				});
		});
	};

	return (
		<div className="po-wrapper">
			<form onSubmit={handleSearch} className="search-form">
				<div className="search-title">
					<span className="subtitle">
						Please choose a date range to run this report
					</span>
				</div>
				<div className="srch-dtl">
					<div className="srch-box">
						<div className="srchItem">
							<label htmlFor="startDate" className="srch_date_lbl">
								Start date:
							</label>
							<input
								type="date"
								className="date-in"
								value={startDt}
								id="startDate"
								required
								onChange={(e) => setStartDt(e.target.value)}
							/>
						</div>

						<div className="srchItem">
							<label htmlFor="endDate" className="srch_date_lbl">
								End date:
							</label>
							<input
								type="date"
								id="endDate"
								className="date-in"
								value={endDt}
								required
								onChange={(e) => setEndDt(e.target.value)}
							/>
						</div>
					</div>
					<div className="search-box">
						<button className="srchBtn" type="submit">
							<SearchOutlined className="searchIcon" /> Search
						</button>
					</div>
				</div>
			</form>

			{loader ? (
				<div>
					{data.length ? (
						<div className="table-wrapper">
							<Table className="table">
								<EnhancedTableHead
									checkbox={true}
									headCells={headCells}
									order={order}
									orderBy={orderBy}
									onRequestSort={handleRequestSort}
									className="headerRow"
								/>
								<TableBody className="tBody">
									{stableSort(data, getComparator(order, orderBy))
										.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
										.map((row, i) => (
											<PoHistoryRow key={i} row={row} className="tableRow" />
										))}
									<GrandTotal total={totalData} />
								</TableBody>
								<TableFooter>
									<TableRow className="pagination">
										<TablePagination
											rowsPerPageOptions={[
												5,
												10,
												25,
												{ label: "All", value: -1 },
											]}
											colSpan={3}
											count={data.length}
											rowsPerPage={rowsPerPage}
											page={page}
											SelectProps={{
												inputProps: {
													"aria-label": "rows per page",
												},
												native: true,
											}}
											onPageChange={handleChangePage}
											onRowsPerPageChange={handleChangeRowsPerPage}
											ActionsComponent={TablePaginationActions}
										/>
									</TableRow>
								</TableFooter>
							</Table>
						</div>
					) : (
						<span className="empty-message">NO Data Availible</span>
					)}
				</div>
			) : (
				<Loader />
			)}
		</div>
	);
}
