import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Loading from "../loading-component/Loading.component";
import "./batchlabellist-styles.scss"; // Style sheet
//Materila Ui
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";

export default function BatchLabelList() {
	const navigate = useNavigate();
	const [batchArray, setBatchArray] = useState([]);
	const [loading, setLoading] = useState(false);

	const zeroPad = (num, places) => String(num).padStart(places, "0");

	const handleClick = (event, id) => {
		event.preventDefault();
		navigate(`../label-batch/${id}`, { raplace: false });
	};

	useEffect(() => {
		setLoading(true);
		const url = `https://c99jtoge7d.execute-api.us-east-2.amazonaws.com/dev/rx?procName=GET_BATCH_LABELS`;
		const request = axios({
			method: "GET",
			url,
		});
		request.then(async (res) => {
			setBatchArray(res.data.result);
			if (res.data.result.length === 0) {
				console.log("No Batches found");
				setLoading(false);
			} else {
				// console.log("Data: ", res.data.result);
				setBatchArray(res.data.result);
				setLoading(false);
			}
		});
	}, []);

	return (
		<div className="lblQueue">
			<div className="title">
				<span className="subtitle">
					Please select a batch to label by clicking on a corresponding row in
					the table below.
				</span>
			</div>
			{loading ? (
				<Loading />
			) : (
				<div>
					<Table className="lblTbl">
						<TableHead className="lblHdr">
							<TableRow>
								<TableCell className="lblHdrCell">BatchID</TableCell>
								<TableCell className="lblHdrCell">Compound</TableCell>
								<TableCell className="lblHdrCell">Quantity</TableCell>
								<TableCell className="lblHdrCell">BUD</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{batchArray.map((batch, index) => (
								<TableRow
									key={index}
									hover
									onClick={(event) => handleClick(event, batch.ID)}
									className="lblRow"
								>
									<TableCell scope="row">
										{`${batch.BATCH_DATE}@${zeroPad(batch.SEQ, 2)}`}
									</TableCell>
									<TableCell align="left" scope="row">
										{batch.NAME}
									</TableCell>

									<TableCell align="left" scope="row">
										{batch.QTY}
									</TableCell>
									<TableCell align="left" scope="row">
										{batch.BUD}
									</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</div>
			)}
		</div>
	);
}
