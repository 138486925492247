import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./porow-styles.scss"; // Style Sheet
import { ccyFormat } from "../grand-total/GrandTotal.component";

//Material Ui
import {
	Table,
	TableBody,
	TableCell,
	IconButton,
	Collapse,
	TableHead,
	TableRow,
	Box,
} from "@mui/material";
import {
	DateRangeOutlined,
	KeyboardArrowUp,
	KeyboardArrowDown,
} from "@mui/icons-material";

export default function PoHistoryRow(props) {
	const { row } = props;
	const navigate = useNavigate();
	const [open, setOpen] = useState(false);
	const [poTotal, setPoTotal] = useState();

	const handleEdit = (e, p) => {
		e.preventDefault();
		console.log("P", p);
		navigate(`../edit-history/${p.ORDR_ID}`, { replace: false });
	};

	const handlePoTotal = (arr) => {
		const total = (item) => {
			return item.QTY_RECIVED * item.COST;
		};
		const sum = (prev, next) => {
			return prev + next;
		};

		return arr.map(total).reduce(sum);
	};

	useEffect(() => {
		setPoTotal(handlePoTotal(row.itemRow));
	}, [row]);

	return (
		<>
			<TableRow className="tableRow" hover>
				<TableCell className="cell">
					<IconButton
						aria-label="expand row"
						size="small"
						onClick={() => setOpen(!open)}
					>
						{open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
					</IconButton>
				</TableCell>
				<TableCell className="cell" align="left">
					{row.DATE_RECEIVED}
				</TableCell>
				<TableCell className="cell" align="left">
					{row.PO_ID}
				</TableCell>
				<TableCell className="cell" align="left">
					{row.VENDOR_NAME}
				</TableCell>
				<TableCell className="cell">{ccyFormat(poTotal)}</TableCell>
			</TableRow>
			<TableRow>
				<TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
					<Collapse in={open} timeout="auto" unmountOnExit>
						<Box margin={2} className="nested">
							<div className="nested-title">Received Items</div>
							<Table size="small" aria-label="permissions">
								<TableHead>
									<TableRow>
										<TableCell>Date Received</TableCell>
										<TableCell>Date Ordered</TableCell>
										<TableCell>NDC</TableCell>
										<TableCell>Item</TableCell>
										<TableCell>Ordered</TableCell>
										<TableCell>Received</TableCell>
										<TableCell>Cost</TableCell>
										<TableCell>Total</TableCell>
										<TableCell>Edit</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{row.itemRow.map((p, i) => (
										<TableRow key={p.ORDR_ID}>
											<TableCell className="cell">{p.DATE_RECEIVED}</TableCell>
											<TableCell>{p.ORDR_DT}</TableCell>
											<TableCell>{p.NDC}</TableCell>
											<TableCell>{p.GENERIC}</TableCell>
											<TableCell>{p.QTY}</TableCell>
											<TableCell>{p.QTY_RECIVED}</TableCell>
											<TableCell>{ccyFormat(Number(p.COST))}</TableCell>
											<TableCell>{ccyFormat(p.COST * p.QTY_RECIVED)}</TableCell>
											<TableCell>
												<IconButton
													size="small"
													onClick={(e) => handleEdit(e, p)}
												>
													<DateRangeOutlined />
												</IconButton>
											</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
						</Box>
					</Collapse>
				</TableCell>
			</TableRow>
		</>
	);
}
