import ItemActionTypes from "../actions/item.action.types";

const initialState = {
	itemIds: [],
	ndc: "",
	brand: "",
	generic: "",
	mnufacture: "",
	descr: "",
	form: "none",
	activeIngridient: "",
	isFetching: false,
};

const itemReducer = (state = initialState, action) => {
	// console.log("Reducer Action: ", action.type);

	switch (action.type) {
		case ItemActionTypes.FETCH_ITEM_START:
			return {
				...state,
				isFatching: true,
			};
		case ItemActionTypes.FETCH_ITEM_SUCCESS:
			return {
				...state,
				item: action.payload,
				isFatching: false,
			};
		case ItemActionTypes.FETCH_ITEM_FAILURE:
			return {
				...state,
				errorMsg: action.payload,
				isFatching: false,
			};
		case ItemActionTypes.DELETE_ITEM:
			return {
				...state,
				item: initialState,
			};
		case ItemActionTypes.ADD_ITEM:
			return {
				...state,
				item: action.payload,
			};
		default:
			return state;
	}
};

export default itemReducer;
