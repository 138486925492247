import { useState } from "react";
import axios from "axios";
import "./addingridient-styles.scss";
import InputMask from "react-input-mask";
//Material Ui
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import AddTaskIcon from "@mui/icons-material/AddTask";

export default function AddFormulaIngridient({
	updt,
	setUpdt,
	formula,
	setFormula,
}) {
	const getUnit = () => {
		console.log("Formula: ", formula);
		var unit = "";
		formula.productType === "CAPSULE" || formula.productType === "TABLET"
			? (unit = "g")
			: (unit = "%");
		return unit;
	};

	const [ndc, setNdc] = useState("");
	const [brand, setBrand] = useState("");
	const [generic, setGeneric] = useState("");
	const [descr, setDescr] = useState("");
	const [amount, setAmount] = useState(0);
	const [unit, setUnit] = useState(getUnit());
	const [apis, setApis] = useState(false);
	const [qsad, setQsad] = useState(false);

	const [consideration, setConsidiration] = useState("");

	const onSubmit = (e) => {
		e.preventDefault();

		if (ndc.length === 0) {
			return alert("Please use valid NDC");
		}

		if (amount === 0) {
			return alert("Amount can not be 0");
		}
		addIngridient();

		var cntr = updt + 1;
		setUpdt(cntr);
		setNdc("");
		setBrand("");
		setGeneric("");
		setDescr("");
		setAmount(0);
		setUnit(getUnit());
		setApis(false);
		setQsad(false);
		setConsidiration("");
	};

	const handleNdcSearch = (e) => {
		e.preventDefault();
		const url = `https://c99jtoge7d.execute-api.us-east-2.amazonaws.com/dev/item?procName=SRCH_ITEM_NDC&params=${ndc}`;
		const request = axios({
			method: "GET",
			url,
		});

		request
			.then((res) => {
				if (res.data.result[0]) {
					console.log("Res: ", res.data.result[0]);
					setGeneric(res.data.result[0].GENERIC);
					setDescr(res.data.result[0].DESCR);
					setConsidiration(res.data.result[0].CONSIDIRATIONS);
				} else {
					return alert(`Item data not found for NDC ${ndc}`);
				}
			})
			.catch((err) => {
				console.error(err);
			});
	};

	const addIngridient = () => {
		const ingritidient = {
			ndc: ndc,
			brand: brand,
			generic: generic,
			descr: descr,
			amount: amount,
			unitOfMesure: unit,
			apis: apis,
			qsad: qsad,
			consider: consideration,
		};
		var temp = formula;

		temp.ingridients.push(ingritidient);
		setFormula(temp);
	};

	return (
		<div className="ingridient-wrapper">
			<div className="srch-box">
				<label htmlFor="ndc" className="srchLbl">
					Ingridient NDC:
				</label>
				<div className="ndc-wrapper">
					<InputMask
						mask="****9-9999-99"
						value={ndc}
						onChange={(e) => setNdc(e.target.value)}
					>
						<input type="text" id="ndc" className="searchNdc" autoFocus />
					</InputMask>
					<button className="srchIcon" onClick={handleNdcSearch}>
						<SearchOutlinedIcon />
					</button>
				</div>
			</div>
			<div className="ingrid-info">
				<div className="info-field">
					<span id="generic">{generic}</span>
				</div>
				<div className="info-field">
					<label htmlFor="amount">Amount:</label>
					<input
						type="number"
						id="amount"
						className="amount"
						value={amount}
						onChange={(e) => setAmount(e.target.value)}
					/>
					<spam>{unit}</spam>

					<div className="check-boxes">
						<FormControl>
							<FormControlLabel
								value="start"
								control={
									<Checkbox
										checked={apis}
										className="checkbox-root"
										onChange={(e) => setApis(e.target.checked)}
										sx={{ color: "#e0ffff" }}
									/>
								}
								label="APIs"
								labelPlacement="start"
							/>
						</FormControl>
						<FormControl>
							<FormControlLabel
								value="start"
								control={
									<Checkbox
										checked={qsad}
										onChange={(e) => setQsad(e.target.checked)}
										sx={{ color: "#e0ffff" }}
										className="check"
									/>
								}
								label="QSAD"
								labelPlacement="start"
							/>
						</FormControl>
					</div>
					<span className="consideration">{consideration}</span>
				</div>
				<div className="save-wrapper">
					<button className="saveBtn" onClick={onSubmit}>
						<AddTaskIcon />
						Add Ingridient
					</button>
				</div>
			</div>
		</div>
	);
}
