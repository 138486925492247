import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchUserStartAsync } from "../../store/actions/user.actions";
import { LoginAws } from "../../utils/aws-cognito.js";
import { useNavigate, Link } from "react-router-dom";
import "./login.scss";
import ConfirmEmail from "./ConfirmEmail.component";
import Logo from "../../assets/img/logo-white.png";
import Loading from "../../components/loading-component/Loading.component";
//Material Ui
import { PersonOutlined, KeyRounded } from "@mui/icons-material";

function LoginPage() {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const user = useSelector((state) => state.user.user);
	const [userid, setUserid] = useState("");
	const [password, setPassword] = useState("");
	const [stage, setStage] = useState(1); // 1=confirmed 2=not
	const [fetching, setFetching] = useState(false);

	const handleLogin = async (e) => {
		e.preventDefault();
		console.log("Login");
		setFetching(true);
		await LoginAws(userid, password)
			.then((data) => {
				console.log(data);
				dispatch(fetchUserStartAsync(userid));
				setFetching(false);
				navigate("/home", { replace: true });
			})
			.catch((err) => {
				alert(`${err.message}, ${err.name}`);
				setFetching(false);
				console.log("error: ", err);
				if (err.code === "UserNotConfirmedException") {
					setStage(2);
				}
			});
	};

	useEffect(() => {
		if (user !== null) {
			navigate("/home", { replace: true });
		}
	});

	return (
		<React.Fragment>
			{fetching ? (
				<Loading />
			) : (
				<>
					<div className="login">
						{stage === 1 && (
							<form onSubmit={handleLogin}>
								<div className="container">
									<div className="title">
										<img src={Logo} alt="" className="logo" />
										<h4 className="titleText">
											Login here using your email and password
										</h4>
									</div>
									<div className="row">
										<label className="label" htmlFor="username">
											<PersonOutlined />
										</label>
										<input
											type="email"
											className="input"
											id="username"
											placeholder="email"
											value={userid}
											onChange={(e) => setUserid(e.target.value)}
											required
										/>
									</div>
									<div className="row">
										<label className="label" htmlFor="password">
											<KeyRounded />
										</label>
										<input
											type="password"
											className="input"
											id="password"
											placeholder="password"
											value={password}
											onChange={(e) => setPassword(e.target.value)}
											required
										/>
									</div>
									<div className="row">
										<button type="submit">
											<span className="shadow"></span>
											<span className="edge"></span>
											<span className="front text"> LOGIN</span>
										</button>
									</div>

									<div className="passLink">
										<Link to={"/forgot-password"} className="link">
											Forgot password
										</Link>
									</div>
								</div>
							</form>
						)}
						{stage === 2 && <ConfirmEmail />}
					</div>
				</>
			)}
		</React.Fragment>
	);
}

export default LoginPage;
