/* eslint-disable array-callback-return */
import React, { useState, useEffect } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { UpdateBatch, UpdateBatchUid } from "../../utils/BatchHelper";
import BatchLabelRow from "../batch-label-row/BatchLableRow.component";
import Loading from "../loading-component/Loading.component";
import "./batchlabletbl-styles.scss"; //Style sheet
//Material UI
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import SaveIcon from "@mui/icons-material/Save";

export default function BatchLabelTable({ labelArr, batchId }) {
	const navigate = useNavigate();
	const user = useSelector((state) => state.user.user);
	const [rxData, setRxData] = useState([]);
	const [loading, setLoading] = useState(false);

	const handleUpdateBatchRx = () => {
		return new Promise((resolve, reject) => {
			labelArr.map((l) => {
				if (!l.rx) {
					reject(`Missing Rx Number for ${l.id}`);
				}
				let params = [l.id, l.rx];
				let procName = "UPDATE_BATCH_RXNO";
				UpdateBatch(procName, params)
					.then((resp) => {
						// console.log("Update Batch Rx");

						return resp;
					})
					.catch((err) => {
						// setLoading(false);
						reject(`DB error: ${err}`);
					});
			});
			resolve("Success");
		});
	};

	const handleSave = (e) => {
		e.preventDefault();
		setLoading(true);
		// console.log("label Arr", labelArr);
		if (labelArr.length === 0) {
			alert(`Please select Rx number for all packages!`);
			setLoading(false);
			return;
		}

		handleUpdateBatchRx()
			.then(() => {
				console.log("Labeled");
				let status = "LABELED";
				var params = [batchId, status];
				let procName = "UPDATE_BATCH_STATUS";
				UpdateBatch(procName, params)
					.then(() => {
						UpdateBatchUid(batchId, user.EMAIL, "LABEL_ID")
							.then((resp) => {
								alert(`Batch Packaging is recorded with ${resp}`);
								setLoading(false);
								navigate("../batch-label");
							})
							.catch((err) => {
								alert(`Severe DB Error! ${err}`);
								setLoading(false);
							});
					})
					.catch((err) => {
						alert(`Severe DB Error ${err}`);
						setLoading(false);
					});
			})
			.catch((err) => {
				alert(`Severe Error ${err}`);
			});
	};

	useEffect(() => {
		const url = `https://c99jtoge7d.execute-api.us-east-2.amazonaws.com/dev?procName=GET_RX_PACKING_BYID&params=${batchId}`;

		const request = axios({
			method: "GET",
			url,
		});
		request
			.then((res) => {
				if (!res) {
					alert("No item data found!!!");
				} else {
					// console.log("Rx Data: ", res.data.result);
					setRxData(res.data.result);
				}
			})
			.catch((err) => {
				console.error(err);
			});
	}, [batchId]);

	return (
		<React.Fragment>
			{loading ? (
				<Loading />
			) : (
				<>
					<Table className="lblBatchTbl">
						<TableHead className="lblHdr">
							<TableRow>
								<TableCell className="lblHdrCell">Pack No</TableCell>
								<TableCell className="lblHdrCell">Actual Weight</TableCell>
								<TableCell className="lblHdrCell">RX NUMBER</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{labelArr.map((row, index) => (
								<BatchLabelRow
									key={row.id}
									row={row}
									labelArr={labelArr}
									index={index}
									rxData={rxData}
								/>
							))}
						</TableBody>
					</Table>
					<div className="saveBtn">
						<button className="saveButton" onClick={handleSave}>
							<SaveIcon /> Save
						</button>
					</div>
				</>
			)}
		</React.Fragment>
	);
}
