import "./single.scss";
import NavBar from "../../components/navbar/NavBar.component";
import SideBar from "../../components/sidebar/SideBar.component";
import Footer from "../../components/footer/Footer.component";
import { IdleTimerContainer } from "../../components/idle-time/IdleTimerContainer.js";

function SinglePage({ title, children, source }) {
	return (
		<div className="single">
			<IdleTimerContainer>
				<SideBar source={source} />
				<div className="singleContainer">
					<NavBar />
					<div className="top">
						<h1 className="title">{title}</h1>
					</div>
					{children}
					<Footer />
				</div>
			</IdleTimerContainer>
		</div>
	);
}

export default SinglePage;
