import { useState } from "react";
import "./createresource-style.scss";
import axios from "axios";
import { Save } from "@mui/icons-material";

export default function CreateSystemResource() {
	const [sysId, setSysId] = useState("");
	const [name, setName] = useState("");
	const [type, setType] = useState("");

	const handleSave = async (e) => {
		e.preventDefault();
		var params = [sysId, name, type];
		let procName = "ADD_SYSTEM_RESOURCE";
		const url = "https://c99jtoge7d.execute-api.us-east-2.amazonaws.com/dev";
		const data = { procName, params };
		axios
			.post(url, JSON.stringify(data))
			.then((resp) => {
				if (
					resp.data.result[0].ROWS_ADDED &&
					resp.data.result[0].ROWS_ADDED === 1
				) {
					// console.log("Resp Data", resp.data.result[0]);
					alert("Resource was added successfully!");
					setSysId("");
					setName("");
					setType("");
				} else {
					alert(`Error! ${resp.data.result[0]}`);
				}
			})
			.catch((err) => {
				alert(`Error Add: ${err}`);
			});
	};

	return (
		<div className="container">
			<div className="title">
				<span>System control cesource</span>
			</div>
			<div className="sysresource">
				<form onSubmit={handleSave}>
					<div className="formInput">
						<label htmlFor="sysId">System Id:</label>
						<input
							type="text"
							value={sysId}
							onChange={(e) => setSysId(e.target.value)}
							required
							className="input"
						/>
					</div>
					<div className="formInput" htmlFor="sysId">
						<label>Resource name:</label>
						<input
							type="text"
							value={name}
							onChange={(e) => setName(e.target.value)}
							required
							className="input"
						/>
					</div>

					<div className="formInput">
						<label htmlFor="type">Resource type:</label>

						<select
							id="type"
							className="select"
							value={type}
							onChange={(e) => setType(e.target.value)}
							required
						>
							<option value="">--Please choose type--</option>
							<option value="MENU">Menu</option>
							<option value="DATA">Data</option>
						</select>
					</div>

					<div className="formInput">
						<button className="button">
							<Save />
							Save
						</button>
					</div>
				</form>
			</div>
		</div>
	);
}
