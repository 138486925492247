import "./formulaingridlist.styles.scss";
// Material Ui
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import {
	Table,
	TableHead,
	TableBody,
	TableCell,
	TableRow,
	IconButton,
} from "@mui/material";

export default function Formulaingirdlist({
	updt,
	setUpdt,
	formula,
	setFormula,
	edit,
}) {
	const deleteIngridient = (key) => {
		var temp = formula;
		temp.ingridients.splice(key, 1);
		setFormula(temp);
		var cntr = updt + 1;
		setUpdt(cntr);
	};

	const trueFalse = (value) => {
		if (value) {
			return "Y";
		} else {
			return "N";
		}
	};

	console.log("Update: ", updt);

	return (
		<div className="ingrid-list-wrapper">
			<Table className="ingrid-table">
				<TableHead className="tblHeader">
					<TableRow>
						<TableCell className={edit ? "action-ingrid" : "action-hidden"}>
							Action
						</TableCell>
						<TableCell className="hdrCell">NDC</TableCell>
						<TableCell className="hdrCell">Generic Name</TableCell>
						<TableCell className="hdrCell">Amount</TableCell>
						<TableCell className="hdrCell">Unit of Mesure</TableCell>
						<TableCell className="hdrCell">APIs</TableCell>
						<TableCell className="hdrCell">QSAD</TableCell>
						<TableCell className="hdrCell">Considerations</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{formula.ingridients
						? formula.ingridients.map((ingridient, index) => (
								<TableRow key={index} className="ingirid-row">
									<TableCell
										component="th"
										scope="row"
										className={edit ? "action" : "action-hidden"}
									>
										<IconButton
											sx={{ color: "#ff0000" }}
											onClick={() => deleteIngridient(index)}
										>
											<DeleteForeverIcon />
										</IconButton>
									</TableCell>
									<TableCell className="ingirid-column">
										{ingridient.ndc}
									</TableCell>
									<TableCell className="ingirid-column" align="left">
										{ingridient.generic}
									</TableCell>
									<TableCell align="left" className="ingirid-column">
										{ingridient.amount}
									</TableCell>
									<TableCell align="left" className="ingirid-column">
										{ingridient.unitOfMesure}
									</TableCell>
									<TableCell align="left" className="ingirid-column">
										{trueFalse(ingridient.apis)}
									</TableCell>

									<TableCell align="left" className="ingirid-column">
										{trueFalse(ingridient.qsad)}
									</TableCell>
									<TableCell
										align="left"
										className="ingirid-column"
										style={{ background: "yellow" }}
									>
										{ingridient.consider}
									</TableCell>
								</TableRow>
						  ))
						: null}
				</TableBody>
			</Table>
		</div>
	);
}
