const UserActionTypes = {
	DELETE_USER: "DELETE_USER",
	ADD_SESSION: "ADD_SESSION",
	DELETE_SESSION: "DELETE_SESSION",
	SESSION_ERROR: "SESSION_ERROR",
	FETCH_USER_START: "FETCH_USER_START",
	FETCH_USER_SUCCESS: "FETCH_USER_SUCCESS",
	FETCH_USER_FAILURE: "FETCH_USER_FAILURE",
};

export default UserActionTypes;
