import "./page404-styles.scss";
import Img404 from "../../assets/img/error404.jpeg";

export default function Page404() {
	return (
		<div className="container">
			<div className="image">
				<img src={Img404} alt="" className="error" />
			</div>
		</div>
	);
}
