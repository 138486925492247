import "./listroles-styles.scss";
import { useEffect, useState } from "react";
import axios from "axios";
import DialogComponent from "../dialog/DialogComponent";
import { useNavigate, Link } from "react-router-dom";
import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Collapse,
	IconButton,
	Box,
	Typography,
} from "@mui/material";
import {
	Add,
	Edit,
	KeyboardArrowUp,
	KeyboardArrowDown,
	Delete,
} from "@mui/icons-material";
import { red } from "@mui/material/colors";

function createData(data) {
	var roleList = [];
	var id = "";

	data.map((r) => {
		const { ROLEID, ROLE_NAME, ROLE_DESCR, ID, RESOURCE, RSOURCE_TYPE } = r;

		if (id !== r.ROLEID) {
			let permissions = [{ ID, RESOURCE, RSOURCE_TYPE }];
			let prod = { ROLEID, ROLE_NAME, ROLE_DESCR, permissions };
			roleList.push(prod);
			id = r.ROLEID;
		} else {
			let permissions = { ID, RESOURCE, RSOURCE_TYPE };
			roleList[roleList.length - 1].permissions.push(permissions);
		}
	});

	return roleList;
}

function Row(props) {
	const { row, handleEdit, handleDelete } = props;
	const [open, setOpen] = useState(false);

	return (
		<>
			<TableRow className="tableRow">
				<TableCell className="tableCell">
					<IconButton
						aria-label="expand row"
						size="small"
						onClick={() => setOpen(!open)}
					>
						{open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
					</IconButton>
				</TableCell>
				<TableCell align="left" className="tableCell">
					<Typography variant="subtitle1" gutterBottom>
						{row.ROLE_NAME}
					</Typography>
				</TableCell>
				<TableCell align="left" className="tableCell">
					<Typography variant="subtitle1" gutterBottom>
						{row.ROLE_DESCR}
					</Typography>
				</TableCell>
				<TableCell
					component="th"
					align="left"
					scope="row"
					className="tableCell"
				>
					<IconButton
						aria-label="Edit"
						color="primary"
						onClick={(e) => handleEdit(e, row.ROLEID)}
					>
						<Edit />
					</IconButton>

					<IconButton
						className="delete"
						onClick={(e) => {
							handleDelete(e, row.ROLEID);
						}}
					>
						<Delete sx={{ color: red }} />
					</IconButton>
				</TableCell>
			</TableRow>
			<TableRow className="tableRow">
				<TableCell
					style={{ paddingBottom: 0, paddingTop: 0 }}
					colSpan={6}
					className="tableCell"
				>
					<Collapse in={open} timeout="auto" unmountOnExit>
						<Box margin={1} className="nested">
							<Typography variant="h6" gutterBottom component="div">
								System Access
							</Typography>
							<Table size="small" aria-label="permissions" className="table">
								<TableHead className="header">
									<TableRow className="headerRow">
										<TableCell className="headerCell">Resource</TableCell>
										<TableCell className="headerCell">Type</TableCell>
									</TableRow>
								</TableHead>
								<TableBody className="tBodyInner">
									{row.permissions.map((p) => (
										<TableRow key={p.ID} className="tableRow">
											<TableCell className="tableCell">{p.RESOURCE}</TableCell>
											<TableCell className="tableCell">
												{p.RSOURCE_TYPE}
											</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
						</Box>
					</Collapse>
				</TableCell>
			</TableRow>
		</>
	);
}

export default function ListRoles() {
	const navigate = useNavigate();
	const url = `https://c99jtoge7d.execute-api.us-east-2.amazonaws.com/dev?procName=GET_ROLES_PREMISSIONS&params=1`;
	const [data, setData] = useState([]);
	const [setLoader] = useState(false);
	const [open, setOpen] = useState(false);
	const dlgTitle = "Delete Role";
	const [id, setId] = useState("");
	const [deleteSw, setDeleteSw] = useState(false);

	const handleEdit = (e, id) => {
		e.preventDefault();
		setId(id);
		navigate(`../roles/edit/${id}`, { replace: false });
	};

	const handleDelete = (e, id) => {
		e.preventDefault();
		setId(id);
		setOpen(true);
	};

	const deleteRole = (e, id) => {
		console.log(" Delete Role ");
		e.preventDefault();

		setOpen(false);
		const url = "https://c99jtoge7d.execute-api.us-east-2.amazonaws.com/dev";
		let procName = "DELETE_ROLE";
		let params = [id];
		var roleData = {
			procName,
			params,
		};
		const request = axios({
			method: "POST",
			url,
			data: roleData,
		});
		request
			.then((res) => {
				alert(`Role ${id} delted succesfully`);
				setDeleteSw(true);
			})
			.catch((err) => {
				alert(`Severe error ${err}`);
			});
	};

	useEffect(() => {
		const request = axios({
			method: "GET",
			url,
		});
		request
			.then((res) => {
				if (!res) {
					alert("No user data found!!!");
				} else {
					setData(createData(res.data.result));
					setLoader(true);
				}
			})
			.catch((err) => {
				console.error(err);
			});
	}, [deleteSw]);

	return (
		<div className="tableContainer">
			<div className="datatableTitle">
				<Link to="../roles/new" className="link">
					<Add /> Add New
				</Link>
			</div>
			<Table className="table">
				<TableHead className="header">
					<TableRow className="headerRow">
						<TableCell className="headerCell"></TableCell>
						<TableCell className="headerCell">Role</TableCell>
						<TableCell className="headerCell">Descrition</TableCell>
						<TableCell className="headerCell">Action</TableCell>
					</TableRow>
				</TableHead>
				<TableBody calssName="tBody">
					{data.map((row, index) => (
						<Row
							key={index}
							row={row}
							handleEdit={handleEdit}
							handleDelete={handleDelete}
						/>
					))}
				</TableBody>
			</Table>
			<DialogComponent
				open={open}
				setOpen={setOpen}
				dlgTitle={dlgTitle}
				dlgAction={"Delete"}
				id={id}
				handleClickOk={deleteRole}
			/>
		</div>
	);
}
