import { useEffect, useState } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import "./adjustInvenotry-styles.scss"; //Style sheet

export default function AdjustInventory() {
	const navigate = useNavigate();
	const { id } = useParams();
	const [data, setData] = useState({});
	const [qty, setQty] = useState(0);

	const handleSave = (e) => {
		e.preventDefault();
		let params = [id, qty];
		let procName = "UPDATE_INVENTORY_ONHAND";
		handleDBSave(procName, params)
			.then(() => {
				alert(`Inventory on hand was adjusted successfully`);
				navigate(`../items/qty`, { replace: true });
			})
			.catch((err) => {
				alert(`Severe error saving ${err}`);
			});
	};

	const handleDBSave = (procName, params) => {
		const url =
			"https://c99jtoge7d.execute-api.us-east-2.amazonaws.com/dev/batch";

		const data = { procName, params };
		// console.log("data==>", JSON.stringify(data));
		return new Promise((resolve, reject) => {
			axios
				.post(url, JSON.stringify(data))
				.then(function (resp) {
					// console.log("DB Resp ", resp.data.errorMessage);
					if (resp.data.errorType) {
						reject(resp.data.errorMessage);
					}
					resolve(resp);
				})
				.catch(function (err) {
					console.error("Problem with update: ", err);
					reject(err);
				});
		});
	};

	useEffect(() => {
		const url = `https://c99jtoge7d.execute-api.us-east-2.amazonaws.com/dev?procName=GET_INVENTORY_ONHAND_BYID&params=${id}`;

		const request = axios({
			method: "GET",
			url,
		});
		request
			.then((res) => {
				if (!res) {
					alert("No item data found!!!");
				} else {
					// console.log("Data: ", res.data.result[0]);
					setData(res.data.result[0]);
					setQty(res.data.result[0].QTY_ONHAND);
				}
			})
			.catch((err) => {
				console.error(err);
			});
	}, [id]);

	return (
		<div className="item-wrapper">
			<form onSubmit={handleSave} className="form">
				<div className="line">
					<div className="lineElement">
						<span className="label">NDC:</span>
						<span className="dataField">{data.NDC} </span>
					</div>
					<div className="lineElement">
						<span className="label">Generic:</span>
						<span className="dataField">{data.GENERIC} </span>
					</div>
				</div>
				<div className="line">
					<div className="lineElement">
						<span className="label">Date Received:</span>
						<span className="dataField">{data.DATE_RECEIVED} </span>
					</div>
					<div className="lineElement">
						<span className="label">Quantity Received:</span>
						<span className="dataField">
							{data.QTY_RECIVED} <b>{data.UNIT_OF_MESURE} </b>
						</span>
					</div>
				</div>
				<div className="line">
					<div className="lineElement">
						<span className="label">Lot:</span>
						<span className="dataField">{data.LOT} </span>
					</div>
					<div className="lineElement">
						<label className="label">Quantity on hand:</label>
						<div className="formInput">
							<input
								type="text"
								className="form-field"
								value={qty}
								onChange={(e) => setQty(e.target.value)}
							/>
							<b>{data.UNIT_OF_MESURE} </b>
						</div>
					</div>
				</div>
				<div className="save">
					<button type="submit" className="save-button">
						Save
					</button>
				</div>
			</form>
		</div>
	);
}
