import { useState } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import {
	fetchFormula,
	deleteFromula,
} from "../../store/actions/fromula.actions";
import DialogComponent from "../dialog/DialogComponent";

import InputMask from "react-input-mask";
import "./editformula-styles.scss";
// import FormulaMethodList from "../formula-methods-list/FormulaMethodList.component";
// import Formulaingirdlist from "../formula-ingridient-list/FormulaIngirdList.component";
//Material Ui
import DesktopAccessDisabledIcon from "@mui/icons-material/DesktopAccessDisabled";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { Table, TableBody, TableCell, TableRow } from "@mui/material";

export default function EditFormulala() {
	const dispatch = useDispatch();
	var formula = useSelector((state) => state.formula.formula);
	const [ndc, setNdc] = useState("");
	const [open, setOpen] = useState(false);
	const dlgTitle = "Deactivate Formula";

	const handleDelete = (e) => {
		e.preventDefault();
		setOpen(true);
	};

	const updateFormula = () => {
		const procName = "UPDATE_FORMULA_STATUS";
		let params = [formula.id, "INACTIVE"];

		let data = { procName, params };
		const url = "https://c99jtoge7d.execute-api.us-east-2.amazonaws.com/dev/rx";

		// console.log("data==>", JSON.stringify(data));

		return new Promise((resolve, reject) => {
			axios
				.put(url, JSON.stringify(data))
				.then((resp) => {
					resolve(resp);
				})
				.catch((err) => {
					console.error("Problem with update: ", err);
					reject(err);
				});
		});
	};

	const handleFormulaSearch = (e) => {
		e.preventDefault();
		dispatch(deleteFromula());
		dispatch(fetchFormula(ndc));
	};

	const handelSubmit = (e) => {
		e.preventDefault();

		updateFormula()
			.then((resp) => {
				if (resp.data.errorType) {
					alert(`Error deactivating formula: ${resp.data.errorMessage}`);
				}
				alert("Formula was deactivated");
				dispatch(deleteFromula());
			})
			.catch((err) => {
				alert(`Error deactivating ${err}`);
			});
	};
	const handleCancel = () => {
		setNdc("");
		dispatch(deleteFromula());
	};

	return (
		<div className="edit-formula-wrapper">
			<div className="searchWrapper">
				<InputMask
					mask="****9-9999-99"
					value={ndc}
					onChange={(e) => {
						setNdc(e.target.value);
					}}
					className="ndc"
				>
					<input
						className="searchNdc"
						type="tel"
						placeholder=" FORMULA NDC..."
					/>
				</InputMask>

				<button className="srchIcon" onClick={handleFormulaSearch}>
					<SearchOutlinedIcon />
				</button>
			</div>

			<div className="formula-table">
				<span className="table-title">Formula Info:</span>
				<Table aria-label="formula record">
					<TableBody>
						<TableRow>
							<TableCell className="info-column">Formula Name:</TableCell>
							<TableCell className="data-column" align="left">
								{formula.name}
							</TableCell>
						</TableRow>
						<TableRow>
							<TableCell className="info-column">Formula NDC:</TableCell>
							<TableCell className="data-column" align="left">
								{formula.ndc}
							</TableCell>
						</TableRow>
						<TableRow>
							<TableCell className="info-column">
								Conditions to treat:
							</TableCell>
							<TableCell align="left" className="data-column">
								{formula.type}
							</TableCell>
						</TableRow>
						<TableRow>
							<TableCell className="info-column">Formula Dosage:</TableCell>
							<TableCell align="left" className="data-column">
								{formula.dosage}
							</TableCell>
						</TableRow>

						<TableRow>
							<TableCell className="info-column">Milling required:</TableCell>
							<TableCell align="left" className="data-column">
								{formula.milling}
							</TableCell>
						</TableRow>

						<TableRow>
							<TableCell className="info-column">Mixer Settings:</TableCell>
							<TableCell align="left" className="data-column">
								{formula.mixSettings}
							</TableCell>
						</TableRow>

						<TableRow>
							<TableCell className="info-column">
								Weight of compound jar:
							</TableCell>
							<TableCell align="left" className="data-column">
								{formula.jar}g
							</TableCell>
						</TableRow>

						<TableRow>
							<TableCell className="info-column">Estimated BUD:</TableCell>
							<TableCell align="left" className="data-column">
								{formula.bud} days
							</TableCell>
						</TableRow>
						<TableRow>
							<TableCell className="info-column">Storage Conditions:</TableCell>
							<TableCell align="left" className="data-column">
								{formula.storage}
							</TableCell>
						</TableRow>
						<TableRow>
							<TableCell className="info-column">Auxiliary Labels:</TableCell>
							<TableCell align="left" className="data-column">
								{formula.labels}
							</TableCell>
						</TableRow>
						<TableRow>
							<TableCell className="info-column">
								Container used for dispensing:
							</TableCell>
							<TableCell align="left" className="data-column">
								{formula.packing}
							</TableCell>
						</TableRow>
						<TableRow>
							<TableCell className="info-column">
								Description of Final Product:
							</TableCell>
							<TableCell align="left" className="data-column">
								{formula.final}
							</TableCell>
						</TableRow>
						<TableRow>
							<TableCell className="info-column">Quality Control:</TableCell>
							<TableCell align="left" className="data-column">
								{formula.quality}
							</TableCell>
						</TableRow>
					</TableBody>
				</Table>

				{/* <Formulaingirdlist formula={formula} edit={false} />
				<FormulaMethodList formula={formula} edit={false} /> */}
			</div>

			<div className="stepper-buttons">
				<button onClick={handleCancel} className="cancel">
					<CancelOutlinedIcon /> Cancel
				</button>
				<button className="submit" onClick={handleDelete}>
					<DesktopAccessDisabledIcon /> Deactivate Formula
				</button>
			</div>

			<DialogComponent
				open={open}
				setOpen={setOpen}
				dlgTitle={dlgTitle}
				dlgAction={"Deactivate"}
				handleClickOk={handelSubmit}
			/>
		</div>
	);
}
