import { useEffect } from "react";
import axios from "axios";
import "./batchingridtable-styles.scss";
//Material UI
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";

export default function BatchIngridTable({
	formula,
	ingrids,
	setIngrids,
	totalAmount,
	targetArr,
}) {
	const initialValue = 0;

	const reducer = (accumulator, item) => {
		return accumulator + item;
	};

	const HandleAmount = (amount) => {
		if (
			formula.PRODUCT_TYPE === "CAPSULE" ||
			formula.PRODUCT_TYPE === "TABLET"
		) {
			return `${amount} g`;
		} else {
			return `${amount} %`;
		}
	};

	const CalculateWeight = (amount, form, strength, unit_weight, index) => {
		let weight = 0;

		if (totalAmount === 0) {
			return "-";
		} else {
			if (
				formula.PRODUCT_TYPE === "CAPSULE" ||
				formula.PRODUCT_TYPE === "TABLET"
			) {
				weight = totalAmount * amount;
			} else {
				if (form === "TABLET" || form === "CAPSULE") {
					weight = ((totalAmount * (amount * 0.01)) / strength) * unit_weight;
				} else {
					if (ingrids.QSAD === "Y") {
						weight = totalAmount - targetArr.reduce(reducer, initialValue);
					} else {
						weight = totalAmount * (amount * 0.01);
					}
				}
			}
		}
		let target_wght = Math.round(weight * 1000) / 1000;
		ingrids[index].target_wght = target_wght;
		// console.log("Ingrid: ", ingrids[index]);

		return target_wght;
	};

	useEffect(() => {
		let procName = "GET_FORMULA_INGRIDS";
		let params = formula.ID;
		const url = `https://c99jtoge7d.execute-api.us-east-2.amazonaws.com/dev/formula?procName=${procName}&params=${params}`;
		console.log("URL", url);
		axios
			.get(url)
			.then((resp) => {
				console.log("DATA: ", resp.data.result);
				setIngrids(resp.data.result);
			})
			.catch((err) => {
				alert(`Severe error ${procName}; ${err}`);
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [formula.ID]);

	return (
		<div className="batchIngrid-wrapper">
			<div className="ingridHdr">
				<span className="title">Pharmaceutical Ingredients</span>
				<span className="subtitle">
					Target weight for each ingredient will be calculated based on a total
					target batch weight
				</span>
			</div>

			<Table className="ingridTbl" aria-label="formula ingridients">
				<TableHead>
					<TableRow>
						<TableCell className="hdrCell">NDC</TableCell>
						<TableCell className="hdrCell">Generic Name</TableCell>
						<TableCell className="hdrCell">Form</TableCell>
						<TableCell className="hdrCell">Stability considerations</TableCell>
						<TableCell className="hdrCell">Amount</TableCell>
						<TableCell className="hdrCell">Target Weight</TableCell>
						<TableCell className="hdrCell">QSAD</TableCell>
					</TableRow>
				</TableHead>

				<TableBody>
					{ingrids.map((ingridient, index) => {
						return (
							<TableRow key={index}>
								<TableCell className="ingridCell">{ingridient.NDC}</TableCell>
								<TableCell className="ingridCell" align="left" scope="row">
									{ingridient.GENERIC}
								</TableCell>
								<TableCell className="ingridCell" align="left" scope="row">
									{ingridient.FORM}
								</TableCell>
								<TableCell
									className="ingridCell"
									align="left"
									style={{ background: "yellow" }}
								>
									{ingridient.CONSIDIRATIONS}
								</TableCell>

								<TableCell className="ingridCell" align="left">
									{HandleAmount(ingridient.AMOUNT)}
								</TableCell>
								<TableCell align="left" className="ingridCell">
									{CalculateWeight(
										ingridient.AMOUNT,
										ingridient.FORM,
										ingridient.STRENGTH,
										ingridient.UNIT_WGHT,
										index
									)}
								</TableCell>
								<TableCell align="left" className="ingridCell">
									{ingridient.QSAD}
								</TableCell>
							</TableRow>
						);
					})}
				</TableBody>
			</Table>
		</div>
	);
}
