const FormulaActionTypes = {
  DELETE_FORMULA: "DELETE_FORMULA",
  ADD_FORMULA: "ADD_FORMULA",
  FORMULA_DB_ADD: "FORMULA_DB_ADD",
  FORMULA_ERROR: "FORMULA_ERROR",
  FETCH_FORMULA: "FETCH_FORMULA",
  FETCH_FORMULA_START: "FETCH_FORMULA_START",
  FETCH_FORMULA_SUCCESS: "FETCH_FORMULA_SUCCESS",
  FETCH_FORMULA_FAILURE: "FETCH_FORMULA_FAILURE",
  FETCH_FORMULA_NOT_FOUND: "FETCH_FORMULA_NOT_FOUND",
};

export default FormulaActionTypes;
