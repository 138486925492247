import { useState } from "react";
import "./formulaingrids-styles.scss";
import Avatar from "@mui/material/Avatar";
import AddFormulaIngridient from "../add-formula-ingridient/AddFormulaIngridient.coompoent";
import Formulaingirdlist from "../formula-ingridient-list/FormulaIngirdList.component";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

export default function FormulaIngrids({
	nextStep,
	prevStep,
	formula,
	setFormula,
}) {
	const [updt, setUpdt] = useState(0);

	return (
		<div className="formula-wrapper">
			<div className="title">
				<Avatar className="avatar">2</Avatar>
				<div className="subtitle">Formula Ingridients</div>
			</div>
			<AddFormulaIngridient
				updt={updt}
				setUpdt={setUpdt}
				formula={formula}
				setFormula={setFormula}
			/>
			{formula.ingridients.length > 0 ? (
				<Formulaingirdlist
					updt={updt}
					setUpdt={setUpdt}
					formula={formula}
					setFormula={setFormula}
					edit={true}
				/>
			) : null}
			<div className="stepper-buttons">
				<button onClick={prevStep} className="submit">
					<ArrowBackIosIcon /> Back
				</button>
				<button onClick={nextStep} className="submit">
					Next <ArrowForwardIosIcon />
				</button>
			</div>
		</div>
	);
}
