import { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "./add-vendor-styles.scss";
import VendorForm from "../vendor-form/VendorForm.component";

export default function AddVendor() {
	const navigate = useNavigate();
	const [name, setName] = useState("");
	const [address, setAddress] = useState("");
	const [city, setCity] = useState("");
	const [state, setState] = useState("");
	const [zip, setZip] = useState("");
	const [phone, setPhone] = useState("");
	const [email, setEmail] = useState("");
	const [contact, setContact] = useState("");
	const [account, setAccount] = useState("");
	const [terms, setTerms] = useState("");

	const handleSave = async (e) => {
		e.preventDefault();
		var params = [
			name,
			address,
			city,
			state,
			zip,
			phone,
			email,
			contact,
			account,
			terms,
		];
		let procName = "ADD_VENDOR";
		const url = "https://c99jtoge7d.execute-api.us-east-2.amazonaws.com/dev";
		const data = { procName, params };
		axios
			.post(url, JSON.stringify(data))
			.then((resp) => {
				if (
					resp.data.result[0].ROWS_ADDED &&
					resp.data.result[0].ROWS_ADDED === 1
				) {
					// console.log("Resp Data", resp.data.result[0]);
					alert("Vendor was added successfully!");
					navigate("../vendors", { replace: true });
				} else {
					alert(`Error! ${resp.data.result[0]}`);
				}
			})
			.catch((err) => {
				alert(`Error Add: ${err}`);
			});
	};

	return (
		<div className="vendor-wrapper">
			<VendorForm
				edit={false}
				name={name}
				setName={setName}
				address={address}
				setAddress={setAddress}
				city={city}
				setCity={setCity}
				state={state}
				setState={setState}
				zip={zip}
				setZip={setZip}
				phone={phone}
				setPhone={setPhone}
				email={email}
				setEmail={setEmail}
				contact={contact}
				setContact={setContact}
				account={account}
				setAccount={setAccount}
				terms={terms}
				setTerms={setTerms}
				handleSave={handleSave}
			/>
		</div>
	);
}
