import "./methodlist-styles.scss";
// Material Ui
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import {
	Table,
	TableHead,
	TableBody,
	TableCell,
	TableRow,
	IconButton,
} from "@mui/material";

export default function FormulaMethodList({
	updt,
	setUpdt,
	formula,
	setFormula,
	edit,
}) {
	const deleteMethod = (key) => {
		let temp = formula;
		let methods = temp.method;
		methods.splice(key, 1);
		methods.map((m, i) => {
			return (m.method = i + 1);
		});
		temp.method = methods;
		setFormula(temp);
		var cntr = updt + 1;
		setUpdt(cntr);
	};

	return (
		<div className="method-list-wrapper">
			<Table className="method-list">
				<TableHead className="method-hdr">
					<TableRow className="method-hdr-row">
						<TableCell className={edit ? "action" : "action-hidden"}>
							Action
						</TableCell>
						<TableCell className="hdrCell">Sequence</TableCell>
						<TableCell className="hdrCell">Step </TableCell>
					</TableRow>
				</TableHead>

				<TableBody>
					{formula.method
						? formula.method.map((methods, index) => (
								<TableRow key={index} className="method-row">
									<TableCell
										component="th"
										scope="row"
										className={edit ? "action" : "action-hidden"}
									>
										<IconButton
											onClick={() => deleteMethod(index)}
											sx={{ color: "#ff0000" }}
										>
											<DeleteForeverIcon />
										</IconButton>
									</TableCell>
									<TableCell
										className="method-column"
										style={{ width: "20px" }}
									>
										{methods.method}
									</TableCell>
									<TableCell align="left" className="method-column">
										{methods.descr}
									</TableCell>
								</TableRow>
						  ))
						: null}
				</TableBody>
			</Table>
		</div>
	);
}
