import { useState } from "react";
import {
	ForgottenPassword,
	ForgotPasswordVerify,
} from "../../utils/aws-cognito";
import { useNavigate } from "react-router-dom";
import "./forgot-password-styles.scss";
import Logo from "../../assets/img/logo-white.png";

import { SaveOutlined, Visibility, QrCode2 } from "@mui/icons-material";
import { width } from "@mui/system";

export default function ForgotPassword() {
	const navigate = useNavigate();
	const [stage, setStage] = useState(1); // 1=email 2 = reset
	const [email, setEmail] = useState("");
	const [code, setCode] = useState("");
	const [password, setPassword] = useState("");
	const [confirmPassword, setConfPassword] = useState("");
	const [passwordShown, setPasswordShown] = useState(false);

	const togglePassword = () => {
		setPasswordShown(!passwordShown);
	};

	const sendCode = (event) => {
		event.preventDefault();

		// Auth.forgotPassword(email)
		ForgottenPassword(email)
			.then((data) => {
				console.log(data);
				setStage(2);
			})
			.catch((err) => {
				console.error("onFailure:", err);
				alert(`${err.message} ${err.name}`);
			});
	};

	const resetPassword = (event) => {
		event.preventDefault();

		if (password !== confirmPassword) {
			console.error("Passwords are not the same");
			alert("Passwords do not match!");
			return;
		}

		// Auth.forgotPasswordSubmit(email, code, password)
		ForgotPasswordVerify(email, code, password)
			.then((data) => {
				console.log("onSuccess:", data);
				navigate("/login", { replace: true });
			})
			.catch((err) => {
				alert(`${err.message} ${err.name}`);
				console.error("onFailure:", err);
			});
	};

	return (
		<div className="password-wrapper">
			<div className="title">
				<img src={Logo} alt="" className="logo" />
				<span className="title-text">Reset Password</span>
			</div>

			{stage === 1 && (
				<form onSubmit={sendCode} className="form-password">
					<div className="form-input">
						<label style={{ width: "80px" }}>Email: </label>
						<input
							required
							autoFocus
							className="email"
							type="email"
							value={email}
							onChange={(e) => setEmail(e.target.value)}
						/>
					</div>
					<div className="save">
						<button className="save-button" type="submit">
							<QrCode2 className="saveIcon" /> Request code
						</button>
					</div>
				</form>
			)}
			{stage === 2 && (
				<form onSubmit={resetPassword} className="form-password">
					<div className="form-input">
						<label htmlFor="curr-password">Reset code: </label>
						<div className="item">
							<input
								required
								id="curr-password"
								autoFocus
								className="pass-input"
								type={passwordShown ? "text" : "password"}
								value={code}
								onChange={(e) => setCode(e.target.value)}
							/>
							<button className="visIcon" onClick={togglePassword}>
								<Visibility />
							</button>
						</div>
					</div>
					<div className="form-input">
						<label htmlFor="new-password">New password:</label>
						<div className="item">
							<input
								required
								className="pass-input"
								id="new-password"
								type={passwordShown ? "text" : "password"}
								value={password}
								onChange={(e) => setPassword(e.target.value)}
							/>
							<button className="visIcon" onClick={togglePassword}>
								<Visibility />
							</button>
						</div>
					</div>
					<div className="form-input">
						<label htmlFor="conf-password">Confirm password:</label>
						<div className="item">
							<input
								required
								className="pass-input"
								id="conf-password"
								type={passwordShown ? "text" : "password"}
								value={confirmPassword}
								onChange={(e) => setConfPassword(e.target.value)}
							/>
							<button className="visIcon" onClick={togglePassword}>
								<Visibility />
							</button>
						</div>
					</div>
					<div className="save">
						<button className="save-button" type="submit">
							<SaveOutlined className="saveIcon" /> Change password
						</button>
					</div>
				</form>
			)}
		</div>
	);
}
