import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import userReducer from "./user-reducers.js";
import itemReducer from "./item-reducer";
import formulaReducer from "./formula-reducers";

const persistConfig = {
	key: "root",
	storage,
	whitelist: ["user", "item"],
};

const rootReducer = combineReducers({
	user: userReducer,
	item: itemReducer,
	formula: formulaReducer,
});

export default persistReducer(persistConfig, rootReducer);
