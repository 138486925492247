import axios from "axios";
import UserActionTypes from "./user.action.types";

export const fetchUserStart = () => ({
	type: UserActionTypes.FETCH_USER_START,
});

export const fetchUserSuccess = (user) => ({
	type: UserActionTypes.FETCH_USER_SUCCESS,
	payload: user,
});

export const fetchUserStartAsync = (email) => {
	const url = `https://c99jtoge7d.execute-api.us-east-2.amazonaws.com/dev?procName=GET_USER_INFO&params=${email}`;

	return (dispatch) => {
		const request = axios({
			method: "GET",
			url,
		});
		dispatch(fetchUserStart());

		request
			.then((res) => {
				dispatch(fetchUserSuccess(res.data.result[0]));
			})
			.catch((err) => {
				dispatch({
					type: UserActionTypes.FETCH_USER_FAILURE,
					payload: err,
				});
			});
	};
};

export const deleteUser = () => ({
	type: "DELETE_USER",
});
export const addSession = (session) => ({
	type: "ADD_SESSION",
	payload: session,
});
export const deleteSession = (session) => ({
	type: "DELETE_SESSION",
	payload: session,
});
export const sessionError = (error) => ({
	type: "SESSION_ERROR",
	payload: error,
});
export const userError = (error) => ({
	type: "USER_ERROR",
	payload: error,
});

export const AddDbUser = (dbuser) => {
	return (dispatch) => {
		dispatch(fetchUserStart());
		const url = "https://c99jtoge7d.execute-api.us-east-2.amazonaws.com/dev";
		const { email, fName, lName, phone } = dbuser;
		var params = [email, fName, lName, phone];
		let procName = "CREATE_USER";
		let data = { procName, params };
		console.log("User Data: ", data);
		return new Promise((resolve, reject) => {
			axios
				.post(url, JSON.stringify(data))
				.then(function (resp) {
					resolve(resp);
				})
				.catch(function (err) {
					console.error("Problem with Add User: ", err);
					reject(err);
				});
		});
	};
};
