import "./footer-styles.scss";
import { Typography, Link } from "@mui/material";

export default function Footer() {
	return (
		<div className="footer">
			<Typography variant="body2" color="textSecondary" align="center">
				{"Copyright © "}
				<Link color="inherit" href="/">
					Levins Drugs, Inc
				</Link>{" "}
				{new Date().getFullYear()}
				{"."}
			</Typography>
		</div>
	);
}
