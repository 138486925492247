import "./ims-styles.scss";
import imgUrl from "../../assets/img/ims.webp";

export default function ImsHome() {
	return (
		<div className="container">
			<div className="imageWrapper">
				<img src={imgUrl} alt="IMS" />
			</div>
		</div>
	);
}
