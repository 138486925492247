import { useState } from "react";
import {
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	Checkbox,
} from "@mui/material";

export default function CreatePermsList(props) {
	const { data, setData } = props;
	const [checked, setChecked] = useState(0);

	const handleToggle = (index) => () => {
		var newD = data;
		var a = 0;
		newD[index].isChecked = !newD[index].isChecked;
		setData(newD);
		a = checked + 1;
		setChecked(a);
	};

	return (
		<List>
			{data.map((value, index) => {
				const labelId = `menu ${value.resource}`;

				return (
					<ListItem
						key={index}
						role={undefined}
						dense
						onClick={handleToggle(index)}
						button
					>
						<ListItemIcon>
							<Checkbox
								edge="start"
								value={value[index]}
								checked={value.isChecked}
								disableRipple
								inputProps={{ "aria-labelledby": labelId }}
							/>
						</ListItemIcon>
						<ListItemText id={labelId} primary={`${value.resource}`} />
					</ListItem>
				);
			})}
		</List>
	);
}
