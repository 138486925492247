import { useEffect } from "react";
import axios from "axios";
import { BatchIngridRow } from "../batch-ingrid-row/BatchIngirdRow.component";
import "./batchingirs-styles.scss"; // Style sheet
//Material Ui
import {
	Table,
	TableHead,
	TableBody,
	TableRow,
	TableCell,
} from "@mui/material";

export default function BatchIngirds({
	id,
	ingrids,
	setIngrids,
	updCntr,
	setUpdCntr,
	setErr,
}) {
	useEffect(() => {
		let procName = "GET_BATCH_INGRIDS_BYID";
		let params = id;
		const url = `https://c99jtoge7d.execute-api.us-east-2.amazonaws.com/dev/formula?procName=${procName}&params=${params}`;
		console.log("URL", url);
		axios
			.get(url)
			.then((resp) => {
				console.log("DATA: ", resp.data.result);
				setIngrids(resp.data.result);
				console.log("Ingrids: ", resp.data.result);
			})
			.catch((err) => {
				alert(`Severe error ${procName}; ${err}`);
			});
	}, [id]);

	return (
		<div className="batchIngridsWrapper">
			<div className="subheader">
				<span className="title">Pharmaceutical Ingredients</span>
				<span className="subtitle">
					Please enter the physical weight and choose a lot number for each
					ingredient
				</span>
			</div>

			<Table aria-label="Batch ingridients" className="batchTbl">
				<TableHead className="batchHdr">
					<TableRow>
						<TableCell align="left" className="batchHdrCell">
							NDC
						</TableCell>
						<TableCell className="batchHdrCell" align="left">
							Name
						</TableCell>
						<TableCell className="batchHdrCell" align="left">
							Stability considerations
						</TableCell>
						<TableCell className="batchHdrCell" align="left">
							Target Weight
						</TableCell>
						<TableCell className="batchHdrCell" align="left">
							Weigth
						</TableCell>
						<TableCell className="batchHdrCell" align="left">
							LOT
						</TableCell>
						<TableCell className="batchHdrCell" align="left">
							APIS
						</TableCell>
						<TableCell className="batchHdrCell" align="left">
							QSAD
						</TableCell>
						<TableCell className="batchHdrCell" align="left">
							Save
						</TableCell>
					</TableRow>
				</TableHead>

				<TableBody>
					{ingrids.map((ingridient, index) => (
						<BatchIngridRow
							key={index}
							ingridient={ingridient}
							index={index}
							batch_id={id}
							ingrids={ingrids}
							setIngrids={setIngrids}
							updCntr={updCntr}
							setUpdCntr={setUpdCntr}
							setErr={setErr}
						/>
					))}
				</TableBody>
			</Table>
		</div>
	);
}
