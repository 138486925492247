import { PersonAdd, PeopleAlt, VpnKey, LockOpen } from "@mui/icons-material";

const seccategories = [
	{
		id: "Security Dashboard",
		children: [
			{
				id: "Security Home",
				icon: <LockOpen />,
				link: "/users",
				active: true,
				resource: 900,
			},
			{
				id: "Users",
				icon: <PersonAdd />,
				link: "/users/list",
				active: true,
				resource: 901,
			},
			{
				id: "Roles",
				icon: <PeopleAlt />,
				link: "/users/roles",
				active: true,
				resource: 902,
			},
			{
				id: "System Resources",
				icon: <VpnKey />,
				link: "/users/system",
				active: true,
				resource: 903,
			},
		],
	},
];

export default seccategories;
