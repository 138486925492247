import { useState } from "react";
import moment from "moment";
import axios from "axios";
import DialogComponent from "../dialog/DialogComponent";
import "./reciveInv-styles.scss";
import { IconButton, TableRow, TableCell, TextField } from "@mui/material";
import { Save } from "@mui/icons-material";

export default function ReceiveItemRow(params) {
	const [time] = useState(moment());
	const [open, setOpen] = useState(false);
	const dlgTitle = "Ordered Qty is not equal to Qty Received";
	const { row, setUpdt, updt } = params;
	const [rcvd, setRcvd] = useState(0);
	const [invoice, setInvoice] = useState("");
	const [err, setErr] = useState(false);
	const [color, setColor] = useState("");
	const [status] = useState("RECEIVED");
	const [rcvdDate] = useState(time.format("YYYY-MM-DD"));
	const [lot, setLot] = useState("");
	const [xDate, setXDate] = useState("");
	const { ORDR_ID, NDC, QTY, COST, ORDR_DT, VENDOR_ID, PO_ID } = row;

	const handleRsvdChange = (e) => {
		e.preventDefault();

		setRcvd(e.target.value);

		if (parseInt(QTY) !== parseInt(e.target.value)) {
			setErr(true);
			setColor("red");
		} else {
			setErr(false);
			setColor("");
		}
	};

	const handleSave = (e) => {
		e.preventDefault();
		if (parseInt(rcvd) === 0) {
			alert("Quantity received can not be 0");
			return;
		}

		if (parseInt(QTY) !== parseInt(rcvd)) {
			setOpen(true);
		} else {
			handleDBUpdate();
		}
	};

	const handleDBUpdate = () => {
		const url =
			"https://c99jtoge7d.execute-api.us-east-2.amazonaws.com/dev/batch";
		var params = [
			ORDR_ID,
			ORDR_DT,
			status,
			PO_ID,
			COST,
			rcvdDate,
			rcvd,
			lot,
			xDate,
			VENDOR_ID,
			invoice,
		];

		let procName = "UPDATE_ITEM_ORDER";
		let data = { procName, params };

		axios
			.put(url, JSON.stringify(data))
			.then((resp) => {
				// console.log("Resp: ", resp);
				if (resp.data.errorType) {
					alert(`Problem with Update: ${resp.data.errorMessage}`);
				} else {
					alert(`${rcvd} ${NDC} is received`);
					setOpen(false);
					setUpdt(updt + 1);
				}
			})
			.catch((err) => {
				console.error("Problem with Update: ", err);
				alert(`Problem with Update: ${err}`);
			});
	};

	return (
		<>
			<TableRow hover key={row.ORDR_ID} className="table-row">
				<TableCell className="ndc" align="center" style={{ color: color }}>
					{row.NDC}
				</TableCell>
				<TableCell align="left" variant="body" style={{ color: color }}>
					{row.GENERIC}
				</TableCell>

				<TableCell align="left" style={{ color: color }}>
					{row.QTY}
				</TableCell>

				<TableCell align="left">
					<TextField
						margin="normal"
						className="amnt"
						variant="outlined"
						id="rcvd"
						name="rcvd"
						size="small"
						value={rcvd}
						onChange={handleRsvdChange}
						error={err}
						style={{ width: "8ch" }}
					/>
				</TableCell>
				<TableCell component="th" align="left" scope="row">
					<TextField
						margin="normal"
						variant="outlined"
						id="lot"
						name="lot"
						size="small"
						className="input"
						value={lot}
						onChange={(e) => setLot(e.target.value)}
						style={{ width: "15ch" }}
					/>
				</TableCell>
				<TableCell component="th" align="left" scope="row">
					<TextField
						variant="outlined"
						id="xDate"
						type="date"
						size="small"
						className="input"
						value={xDate}
						style={{ width: "20ch", fontSize: "14px" }}
						onChange={(e) => setXDate(e.target.value)}
					/>
				</TableCell>
				<TableCell
					align="left"
					padding="none"
					variant="body"
					style={{ color: color }}
				>
					{row.VENDOR_NAME}
				</TableCell>

				<TableCell component="th" align="left" scope="row">
					<TextField
						variant="outlined"
						id="invoice"
						name="invoice"
						size="small"
						className="input"
						value={invoice}
						onChange={(e) => setInvoice(e.target.value)}
						error={err}
						style={{ width: "15ch" }}
					/>
				</TableCell>
				<TableCell component="th" align="left" scope="row">
					<IconButton
						aria-label="Save"
						color="primary"
						// disabled={disable}
						onClick={handleSave}
					>
						<Save />
					</IconButton>
				</TableCell>
			</TableRow>

			<DialogComponent
				open={open}
				setOpen={setOpen}
				dlgTitle={dlgTitle}
				dlgAction={"Save"}
				id={ORDR_ID}
				handleClickOk={handleDBUpdate}
			/>
		</>
	);
}
