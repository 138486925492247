import { useState } from "react";
import axios from "axios";
import moment from "moment";
import { useNavigate } from "react-router-dom";

import "./addOrderItem-styles.scss";
import InputMask from "react-input-mask";
//Material Ui
import { SearchOutlined } from "@mui/icons-material";

export default function AddOrderItem() {
	const navigate = useNavigate();
	const [time] = useState(moment());
	const [ndc, setNdc] = useState("");
	const [data, setData] = useState("");
	const [id, setId] = useState("");
	const [qty, setQty] = useState("");
	const [orderDate, setOrderDate] = useState("");
	const [po] = useState("");
	const [cost] = useState("");
	const [ordr_dt] = useState("");
	const [recived] = useState("");
	const [vendor] = useState("");

	const getToday = () => {
		let today = new Date();
		const yyyy = today.getFullYear();
		let mm = today.getMonth() + 1; // Months start at 0!
		let dd = today.getDate();

		if (dd < 10) dd = "0" + dd;
		if (mm < 10) mm = "0" + mm;

		return (today = yyyy + "-" + mm + "-" + dd);
	};

	const handleNdcSearch = (e) => {
		e.preventDefault();

		if (ndc.length === 0) {
			alert("NDC is required for search");
			return;
		}
		const url = `https://c99jtoge7d.execute-api.us-east-2.amazonaws.com/dev?procName=GET_ITEMS_BY_NDC&params=${ndc}`;
		const request = axios({
			method: "GET",
			url,
		});
		request
			.then((res) => {
				if (!res) {
					alert("No item data found!!!");
				} else {
					setId(res.data.result[0].ITEM_ID);
					setData(res.data.result[0]);
					setOrderDate(getToday());
					// console.log("Data: ", data);
				}
			})
			.catch((err) => {
				console.error(err);
			});
	};

	const handleSave = async (e) => {
		e.preventDefault();

		if (qty.length === 0) {
			alert("Order quantity is required!");
			return;
		}
		if (orderDate.length === 0) {
			alert("Order date is a required!");
			return;
		}

		const curr_date = time.format("Y-MM-DD");

		if (curr_date > orderDate) {
			alert("Ordr date can not be less than current date!");
			return;
		}

		var params = [id, qty, orderDate, po, cost, ordr_dt, recived, vendor];
		let procName = "ADD_ITEM_REQUEST";
		const url = "https://c99jtoge7d.execute-api.us-east-2.amazonaws.com/dev";
		const data = { procName, params };
		axios
			.post(url, JSON.stringify(data))
			.then((resp) => {
				if (resp.data.errorType) {
					alert(`Error! ${resp.data.errorMessage}`);
				} else {
					alert("Item was added successfully!");
					navigate("../add-req", { replace: true });
				}
			})
			.catch((err) => {
				alert(`Error Add: ${err}`);
			});
	};

	return (
		<div className="order-item-wrapper">
			<form onSubmit={handleNdcSearch} className="search-form">
				<div className="search-title">
					<span className="subtitle">Procurement request</span>
				</div>
				<div className="srch-dtl">
					<div className="search-box">
						<div className="search-item">
							<label htmlFor="ndc" className="srch_date_lbl">
								Search NDC:
							</label>
							<InputMask
								mask="****9-9999-99"
								value={ndc}
								onChange={(e) => setNdc(e.target.value)}
							>
								<input type="text" id="ndc" className="srch-ndc" autoFocus />
							</InputMask>
						</div>
						<button className="search-btn" type="submit">
							<SearchOutlined className="searchIcon" /> Search
						</button>
					</div>
				</div>
			</form>
			<div className="item-order-wrapper">
				<form onSubmit={handleSave} className="form">
					<div className="line">
						<div className="lineElement">
							<span className="label">Generic:</span>
							<span className="dataField">{data.GENERIC} </span>
						</div>
						<div className="lineElement">
							<span className="label">Manufacture:</span>
							<span className="dataField">{data.MANUFACTURE} </span>
						</div>
					</div>
					<div className="line">
						<div className="lineElement">
							<span className="label">Form:</span>
							<span className="dataField">{data.FORM} </span>
						</div>

						<div className="lineElement" style={{ alignContent: "left" }}>
							<span className="label">Package size:</span>
							<span
								className="dataField"
								style={{ width: "50px", marginRight: 0, alignContent: "right" }}
							>
								{data.PACKAGE_SIZE}
							</span>
							<b style={{ marginRight: "60px" }}>{data.UNIT_OF_MESURE} </b>
						</div>
					</div>
					<div className="line">
						<div className="lineElement">
							<label className="label">Quantity to order:</label>
							<div className="formInput">
								<input
									type="number"
									className="dataField"
									value={qty}
									onChange={(e) => setQty(e.target.value)}
								/>
								<b>{data.UNIT_OF_MESURE} </b>
							</div>
						</div>
						<div className="lineElement">
							<label className="label">Order date:</label>
							<div className="formInput">
								<input
									type="date"
									className="dataField"
									value={orderDate}
									onChange={(e) => setOrderDate(e.target.value)}
								/>
							</div>
						</div>
					</div>
					<div className="save-order">
						<button type="submit" className="save-button">
							Order
						</button>
					</div>
				</form>
			</div>
		</div>
	);
}
