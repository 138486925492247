import { useState } from "react";
import "./addformula-styles.scss";
import FormulaForm from "../formula-form/FormulaForm.component";
import DialogComponent from "../dialog/DialogComponent";

//Material Ui
import Avatar from "@mui/material/Avatar";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import CancelIcon from "@mui/icons-material/Cancel";

export default function AddFormula({ nextStep, formula, setFormula }) {
	const [name, setName] = useState(formula.name);
	const [ndc, setNdc] = useState(formula.ndc);
	const [type, setType] = useState(formula.productType);
	const [dosage, setDosage] = useState(formula.dosage);
	const [bud, setBud] = useState(formula.bud);
	const [storage, setStorage] = useState(formula.storage);
	const [labels, setLabels] = useState(formula.labels);
	const [packing, setPacking] = useState(formula.packing);
	const [final, setFinal] = useState(formula.final);
	const [quality, setQuality] = useState(formula.quality);
	const [milling, setMilling] = useState(formula.milling);
	const [treat, setTreat] = useState(formula.type);
	const [freq, setFreq] = useState(formula.freq);
	const [mixSettings, setMixSettings] = useState(formula.mixSettings);
	const [jar, setJar] = useState(formula.jar);
	var lblArray = [];
	//Lables
	const [specially, setSpecially] = useState(formula.specially);
	const [external, setExternal] = useState(formula.external);
	const [refrigerate, setRefigerete] = useState(formula.refrigerate);
	const [shake, setShake] = useState(formula.shake);
	const [sun, setSun] = useState(formula.sun);
	const [rectal, setRectal] = useState(formula.rectal);
	const [vaginal, setVaginal] = useState(formula.vaginal);
	const [poison, setPoison] = useState(formula.poison);
	// Dialog
	const [open, setOpen] = useState(false);
	const dlgTitle = "Cancel";

	const onSubmit = (e) => {
		e.preventDefault();
		var temp = formula;
		if (specially) {
			lblArray.push("Specially made for you");
		}
		if (external) {
			lblArray.push("External use only");
		}
		if (refrigerate) {
			lblArray.push("Refrigerate, do not freeze");
		}
		if (shake) {
			lblArray.push("Shake well before use");
		}
		if (sun) {
			lblArray.push("Sun sensitivity");
		}
		if (rectal) {
			lblArray.push("Rectal use only");
		}
		if (vaginal) {
			lblArray.push("Vaginal use only");
		}
		if (poison) {
			lblArray.push("Poison");
		}

		if (alphanumeric(ndc)) {
			temp.name = name;
			temp.ndc = ndc;
			temp.productType = type;
			temp.dosage = dosage;
			temp.bud = bud;
			temp.storage = storage;
			temp.labels = lblArray;
			temp.packing = packing;
			temp.final = final;
			temp.quality = quality;
			milling ? (temp.milling = "Y") : (temp.milling = "N");
			temp.type = treat;
			temp.freq = freq;
			temp.mixSettings = mixSettings;
			temp.jar = jar;
			temp.specially = specially;
			temp.external = external;
			temp.refrigerate = refrigerate;
			temp.shake = shake;
			temp.sun = sun;
			temp.rectal = rectal;
			temp.vaginal = vaginal;
			temp.poison = poison;

			if (!formula.ingridients) {
				formula.ingridients = [];
			}
			if (!formula.method) {
				formula.method = [];
			}
			setFormula(temp);
			nextStep();
		} else alert("Invalid NDC format");
	};

	const handleCancel = () => {
		setOpen(true);
	};

	const handleCancelConfirm = () => {
		setFormula({});
		setName("");
		setNdc("");
		setType("CREAM");
		setDosage("");
		setBud("");
		setStorage("");
		setLabels([]);
		setPacking("");
		setFinal("");
		setQuality("");
		setMilling(false);
		setTreat("");
		setFreq("");
		setMixSettings("");
		setJar("");
		setSpecially(false);
		setExternal(false);
		setRefigerete(false);
		setShake(false);
		setSun(false);
		setRectal(false);
		setVaginal(false);
		setPoison(false);
		setOpen(false);
	};

	const alphanumeric = (inputtxt) => {
		if (inputtxt) {
			const letterNumber = /^[0-9a-zA-Z]+$/;
			var newStr = inputtxt.replace(/-/g, "");
			if (newStr.match(letterNumber)) {
				return true;
			} else {
				return false;
			}
		}
	};

	return (
		<div className="formula-wrapper">
			<div className="title">
				<Avatar className="avatar">1</Avatar>
				<div className="subtitle">Formula Defenition</div>
			</div>
			<FormulaForm
				ndc={ndc}
				setNdc={setNdc}
				name={name}
				setName={setName}
				type={type}
				setType={setType}
				dosage={dosage}
				setDosage={setDosage}
				bud={bud}
				setBud={setBud}
				storage={storage}
				setStorage={setStorage}
				labels={labels}
				setLabels={setLabels}
				packing={packing}
				setPacking={setPacking}
				final={final}
				setFinal={setFinal}
				quality={quality}
				setQuality={setQuality}
				milling={milling}
				setMilling={setMilling}
				treat={treat}
				setTreat={setTreat}
				freq={freq}
				setFreq={setFreq}
				mixSettings={mixSettings}
				setMixSettings={setMixSettings}
				jar={jar}
				setJar={setJar}
				specially={specially}
				setSpecially={setSpecially}
				external={external}
				setExternal={setExternal}
				refrigerate={refrigerate}
				setRefigerete={setRefigerete}
				shake={shake}
				setShake={setShake}
				sun={sun}
				setSun={setSun}
				rectal={rectal}
				setRectal={setRectal}
				vaginal={vaginal}
				setVaginal={setVaginal}
				poison={poison}
				setPoison={setPoison}
			/>
			<div className="stepper-buttons">
				<button onClick={handleCancel} className="cancel">
					<CancelIcon /> Cancel
				</button>
				<button onClick={onSubmit} className="submit">
					Next <ArrowForwardIosIcon />
				</button>
			</div>
			<DialogComponent
				open={open}
				setOpen={setOpen}
				dlgTitle={dlgTitle}
				dlgAction={"OK"}
				handleClickOk={handleCancelConfirm}
			/>
		</div>
	);
}
