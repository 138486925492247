import { useEffect, useState } from "react";
import axios from "axios";
import Loading from "../loading-component/Loading.component";
import BatchInventoryRow from "../batch-inventory-row/BatchInventoryRow.component";
import "./batchivnentory-styles.scss"; //Style sheet
//Material UI
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";

export default function BatchInventory() {
	const [batchData, setBatchData] = useState([]);
	const [loading, setLoading] = useState(false);
	const [upd, setUpd] = useState(0);

	useEffect(() => {
		setLoading(true);
		const url = `https://c99jtoge7d.execute-api.us-east-2.amazonaws.com/dev/rx?procName=GET_BATCH_INVENTORY`;
		const request = axios({
			method: "GET",
			url,
		});
		request.then(async (res) => {
			if (res.data.result.length === 0) {
				console.log("No Batches found");
			} else {
				// console.log("data: ", res.data.result);
				setBatchData(res.data.result);
				setLoading(false);
			}
		});
	}, [upd]);

	return (
		<div className="batchInventoryWrapper">
			{loading ? (
				<Loading />
			) : (
				<>
					<Table className="batchInvnetoryTbl" aria-label="Batch Inventory">
						<TableHead className="invHdr">
							<TableRow>
								<TableCell className="invHdrCell">Action</TableCell>
								<TableCell className="invHdrCell">Lot</TableCell>
								<TableCell className="invHdrCell">NDC</TableCell>
								<TableCell className="invHdrCell">Name</TableCell>
								<TableCell className="invHdrCell">Rx</TableCell>
								<TableCell className="invHdrCell">Weight</TableCell>
								<TableCell className="invHdrCell">BUD</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{batchData.map((batch, index) => (
								<BatchInventoryRow
									key={index}
									batch={batch}
									index={index}
									upd={upd}
									setUpd={setUpd}
								/>
							))}
						</TableBody>
					</Table>
				</>
			)}
		</div>
	);
}
