import React, { useState, useEffect } from "react";
import axios from "axios";
import Loading from "../loading-component/Loading.component";
import "./batchlinkedrx-styles.scss";
//Style sheet
//Material UI
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";

export default function BatchLinkedRx({ id }) {
	const [rxData, setRxData] = useState([]);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const url = `https://c99jtoge7d.execute-api.us-east-2.amazonaws.com/dev?procName=GET_BATCH_RX_BYID&params=${id}`;

		const request = axios({
			method: "GET",
			url,
		});
		request
			.then((res) => {
				if (!res) {
					alert("No item data found!!!");
				} else {
					// console.log("Rx Data: ", res.data.result);
					setRxData(res.data.result);
					setLoading(false);
				}
			})
			.catch((err) => {
				console.error(err);
				setLoading(false);
			});

		return () => {
			setLoading(false);
		};
	}, [id]);
	return (
		<React.Fragment>
			{loading ? (
				<Loading />
			) : (
				<>
					<Table className="lblBatchTbl">
						<TableHead className="lblHdr">
							<TableRow>
								<TableCell className="lblHdrCell">RX NUMBER</TableCell>
								<TableCell className="lblHdrCell">Actual Weight</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{rxData.map((rx, index) => {
								return (
									<TableRow key={index}>
										<TableCell className="lblCell">{rx.RX}</TableCell>
										<TableCell className="lblCell" align="left">
											{rx.WEIGHT}
										</TableCell>
									</TableRow>
								);
							})}
						</TableBody>
					</Table>
				</>
			)}
		</React.Fragment>
	);
}
