import { useNavigate } from "react-router-dom";
import AddFormulaDB from "../../utils/formulaDBhelper";
import "./formulareview-styles.scss";
import FormulaMethodList from "../formula-methods-list/FormulaMethodList.component";
import Formulaingirdlist from "../formula-ingridient-list/FormulaIngirdList.component";
//Material Ui
import Avatar from "@mui/material/Avatar";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import SaveIcon from "@mui/icons-material/Save";
import { Table, TableBody, TableCell, TableRow } from "@mui/material";

export default function FormulalaReview({ prevStep, formula, setFormula }) {
	const navigate = useNavigate();

	const handelSubmit = (e) => {
		e.preventDefault();
		console.log("===> Add Formula", formula.ingridients);
		if (formula.ingridients.length === 0) {
			return alert("Please add ingridients before saving formula");
		}
		if (formula.method.length === 0) {
			return alert("Please add method before saving formula");
		}

		AddFormulaDB(formula)
			.then((resp) => {
				alert(`Formula ${formula.ndc} formula was added successfully`);
				setFormula({});
				navigate("../list-formula", { replace: true });
			})
			.catch((err) => {
				alert(`Severe error ${err}`);
			});
	};

	return (
		<div className="review-wrapper">
			<div className="title">
				<Avatar className="avatar">4</Avatar>
				<div className="subtitle">Review and save formulation</div>
			</div>

			<div className="formula-info">
				<div className="formula-table">
					<span className="table-title">Master Formulation Record</span>
					<Table aria-label="formula record">
						<TableBody>
							<TableRow>
								<TableCell className="info-column">Formula Name:</TableCell>
								<TableCell className="data-column" align="left">
									{formula.name}
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell className="info-column">Formula NDC:</TableCell>
								<TableCell className="data-column" align="left">
									{formula.ndc}
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell className="info-column">
									Conditions to treat:
								</TableCell>
								<TableCell align="left" className="data-column">
									{formula.type}
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell className="info-column">Formula Dosage:</TableCell>
								<TableCell align="left" className="data-column">
									{formula.dosage}
								</TableCell>
							</TableRow>

							<TableRow>
								<TableCell className="info-column">Milling required:</TableCell>
								<TableCell align="left" className="data-column">
									{formula.milling}
								</TableCell>
							</TableRow>

							<TableRow>
								<TableCell className="info-column">Mixer Settings:</TableCell>
								<TableCell align="left" className="data-column">
									{formula.mixSettings}
								</TableCell>
							</TableRow>

							<TableRow>
								<TableCell className="info-column">
									Weight of compound jar:
								</TableCell>
								<TableCell align="left" className="data-column">
									{formula.jar}g
								</TableCell>
							</TableRow>

							<TableRow>
								<TableCell className="info-column">Estimated BUD:</TableCell>
								<TableCell align="left" className="data-column">
									{formula.bud} days
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell className="info-column">
									Storage Conditions:
								</TableCell>
								<TableCell align="left" className="data-column">
									{formula.storage}
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell className="info-column">Auxiliary Labels:</TableCell>
								<TableCell align="left" className="data-column">
									{formula.labels}
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell className="info-column">
									Container used for dispensing:
								</TableCell>
								<TableCell align="left" className="data-column">
									{formula.packing}
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell className="info-column">
									Description of Final Product:
								</TableCell>
								<TableCell align="left" className="data-column">
									{formula.final}
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell className="info-column">Quality Control:</TableCell>
								<TableCell align="left" className="data-column">
									{formula.quality}
								</TableCell>
							</TableRow>
						</TableBody>
					</Table>
				</div>
				<Formulaingirdlist formula={formula} edit={false} />
				<FormulaMethodList formula={formula} edit={false} />
			</div>

			<div className="stepper-buttons">
				<button onClick={prevStep} className="submit">
					<ArrowBackIosIcon /> Back
				</button>
			</div>

			<div className="center">
				<button className="save" onClick={handelSubmit}>
					<SaveIcon /> Save Formula
				</button>
			</div>
		</div>
	);
}
