import { useState } from "react";
import "./batchpackingrow-styles.scss";
//Material Ui
import { TableRow, TableCell, IconButton, TextField } from "@mui/material";
import { Save, RotateLeft } from "@mui/icons-material";

export default function BatchPackingRow({
	index,
	row,
	length,
	updCntr,
	setUpdtCntr,
	arrayPack,
	setArrayPack,
}) {
	const { WEIGHT } = row;
	const [wght, setWght] = useState(0);
	const [saved, setSaved] = useState(false);
	const [error, setError] = useState(false);

	const addPack = (e) => {
		e.preventDefault();
		let u = updCntr;
		if (wght === 0) {
			alert("Weight is required");
			return;
		}

		let moe = Number(WEIGHT) * 0.1;
		let wgt = Number(wght);

		if (
			(wgt > Number(WEIGHT) && wgt - Number(WEIGHT) > moe) ||
			(wgt < Number(WEIGHT) && Number(WEIGHT) - wgt > moe)
		) {
			alert(`Margin of error is more than 10%`);
			setError(true);
			return;
		} else {
			setError(false);
		}

		let arr = arrayPack;

		if (arrayPack.length === 0 || length > arrayPack.length) {
			let newPack = {
				batchid: row.BATCH_ID,
				batchSeq: index + 1,
				weight: wght,
			};
			arr.push(newPack);
		} else {
			arr[index].weight = wght;
		}
		setArrayPack(arr);
		setUpdtCntr(u + 1);
		setSaved(true);
	};

	const handleReset = (e) => {
		e.preventDefault();
		setSaved(false);
	};

	return (
		<>
			<TableRow key={index} className="packBatchTblRow">
				<TableCell>{index + 1}</TableCell>
				<TableCell>{WEIGHT}g</TableCell>
				<TableCell>
					<TextField
						id="wieght"
						variant="outlined"
						value={wght}
						disabled={saved}
						error={error}
						sx={{ backgroundColor: "#FFFFFF" }}
						onChange={(e) => setWght(e.target.value)}
					/>
				</TableCell>
				<TableCell component="th" align="left" scope="row">
					<IconButton
						aria-label="save"
						color="primary"
						onClick={addPack}
						disabled={saved}
					>
						<Save />
					</IconButton>
					/
					<IconButton
						aria-label="save"
						color="primary"
						onClick={handleReset}
						disabled={!saved}
					>
						<RotateLeft />
					</IconButton>
				</TableCell>
			</TableRow>
		</>
	);
}
