import {
	LocalPharmacyOutlined,
	CalculateOutlined,
	PermMediaOutlined,
	Web,
	AirportShuttleOutlined,
	AdminPanelSettingsOutlined,
} from "@mui/icons-material";

const homecategories = [
	{
		id: "Apps",
		children: [
			{
				id: "Rx Dashboard",
				icon: <LocalPharmacyOutlined />,
				link: "/rx",
				active: true,
				resource: 100,
			},
			{
				id: "Lab Dashboard",
				icon: <CalculateOutlined />,
				link: "/lab",
				resource: 200,
			},

			{
				id: "Inventory Managment",
				icon: <Web />,
				link: "/ims",
				resource: 400,
			},
			{
				id: "Delivery Dashboard",
				icon: <AirportShuttleOutlined />,
				link: "/delivery",
				resource: 700,
			},
			{
				id: "Reporting",
				icon: <PermMediaOutlined />,
				link: "/reports",
				resource: 800,
			},
		],
	},
	{
		id: "System",
		children: [
			{
				id: "Security Setup",
				icon: <AdminPanelSettingsOutlined />,
				link: "/users",
				resource: 900,
			},
		],
	},
];

export default homecategories;
