import { useState, useEffect } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import {
	stableSort,
	getComparator,
	EnhancedTableHead,
} from "../table-sort/TableSort.component";
import "./itemlist-styles.scss";
import DialogComponent from "../dialog/DialogComponent";
import TablePaginationActions from "../table-paginatoin/TablePagination.component";
import SearchNdc from "../search-ndc/SearchNdc.component";
// Material Ui
import { Add, Edit, Delete } from "@mui/icons-material";
import {
	Table,
	TableBody,
	TableCell,
	TableRow,
	TableFooter,
	TablePagination,
	IconButton,
} from "@mui/material";

const headCells = [
	{
		id: "NDC",
		numeric: false,
		disablePadding: false,
		label: "NDC",
	},
	{
		id: "GENERIC",
		numeric: false,
		disablePadding: false,
		label: "Generic",
	},
	{
		id: "FORM",
		numeric: false,
		disablePadding: false,
		label: "Form",
	},
	{
		id: "PACKAGE_SIZE",
		numeric: false,
		disablePadding: false,
		label: "Package Size",
	},
	{
		id: "UNIT_OF_MESURE",
		numeric: false,
		disablePadding: false,
		label: "Measure units",
	},

	{
		id: "LOCATION",
		numeric: false,
		disablePadding: false,
		label: "Location",
	},
	{ id: "ACT", numeric: false, disablePadding: false, label: "Action" },
];

export default function InventoryItemsList() {
	const navigate = useNavigate();
	const [data, setData] = useState([]);
	const [dataAll, setDataAll] = useState([]);
	const [searchInput, setSearchInput] = useState("");
	const [open, setOpen] = useState(false);
	const [deleteSw, setDeleteSw] = useState(false);
	const [orderBy, setOrderBy] = useState("NDC");
	const [order, setOrder] = useState("asc");
	const [id, setId] = useState("");
	const dlgTitle = "Delete Inventory Item";
	const url = `https://c99jtoge7d.execute-api.us-east-2.amazonaws.com/dev?procName=GET_ALL_ITEMS&params=1`;
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(5);

	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === "asc";
		setOrder(isAsc ? "desc" : "asc");
		setOrderBy(property);
	};

	const handleSearchNdc = (e) => {
		e.preventDefault();
		setSearchInput(e.target.value);
		setData(dataAll);
		setRowsPerPage(5);
	};

	const searchItems = (e) => {
		e.preventDefault();
		if (searchInput.length === 13) {
			const filteredData = data.filter((item) => {
				setPage(0);

				return item.NDC.includes(searchInput);
			});
			setData(filteredData);
		}
	};

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		// console.log("Rows per page: ", parseInt(event.target.value, 10));
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const handleEdit = (e, id) => {
		e.preventDefault();
		setId(id);
		navigate(`../items/edit/${id}`, { replace: false });
	};

	const handleDelete = (e, id) => {
		e.preventDefault();
		setId(id);
		setOpen(true);
	};

	const deleteItem = (e, id) => {
		e.preventDefault();

		setOpen(false);
		const url = "https://c99jtoge7d.execute-api.us-east-2.amazonaws.com/dev";
		let procName = "DELETE_INVENTORY_ITEMS";
		let params = [id];
		var itemData = {
			procName,
			params,
		};
		const request = axios({
			method: "POST",
			url,
			data: itemData,
		});
		request
			.then((res) => {
				alert(`Item ${id} delted succesfully`);
				setDeleteSw(true);
			})
			.catch((err) => {
				alert(`Severe error ${err}`);
			});
	};

	useEffect(() => {
		const request = axios({
			method: "GET",
			url,
		});
		request
			.then((res) => {
				if (!res) {
					alert("No item data found!!!");
				} else {
					setData(res.data.result);
					setDataAll(res.data.result);
					// console.log("Item data => ", res.data.result);
				}
			})
			.catch((err) => {
				console.error(err);
			});
	}, [deleteSw]);

	return (
		<div className="listContainer">
			<div>
				<div className="listTitle">
					<SearchNdc
						value={searchInput}
						onChange={handleSearchNdc}
						srchAction={searchItems}
					/>
					<Link to="../items/new" className="link">
						<Add /> Add New
					</Link>
				</div>
				<Table className="table">
					<EnhancedTableHead
						headCells={headCells}
						order={order}
						orderBy={orderBy}
						onRequestSort={handleRequestSort}
					/>
					<TableBody>
						{stableSort(data, getComparator(order, orderBy))
							.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
							.map((i) => (
								<TableRow key={i.ITEM_ID} className="tableRow">
									<TableCell className="ndc">{i.NDC}</TableCell>

									<TableCell align="left" className="generic">
										{i.GENERIC}
									</TableCell>
									<TableCell align="left" className="cell">
										{i.FORM}
									</TableCell>

									<TableCell align="left" className="cell">
										{i.PACKAGE_SIZE}
									</TableCell>
									<TableCell align="left" className="cell">
										{i.UNIT_OF_MESURE}
									</TableCell>
									<TableCell align="left" className="cell">
										{i.LOCATION}
									</TableCell>

									<TableCell align="left" className="cell">
										<IconButton
											aria-label="Edit"
											color="primary"
											onClick={(e) => handleEdit(e, i.ITEM_ID)}
										>
											<Edit />
										</IconButton>

										<IconButton
											aria-label="Delete"
											className="delete"
											onClick={(e) => {
												handleDelete(e, i.ITEM_ID);
											}}
										>
											<Delete />
										</IconButton>
									</TableCell>
								</TableRow>
							))}
					</TableBody>
					<TableFooter>
						<TableRow className="pagination">
							<TablePagination
								rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
								colSpan={3}
								count={data.length}
								rowsPerPage={rowsPerPage}
								page={page}
								SelectProps={{
									inputProps: {
										"aria-label": "rows per page",
									},
									native: true,
								}}
								onPageChange={handleChangePage}
								onRowsPerPageChange={handleChangeRowsPerPage}
								ActionsComponent={TablePaginationActions}
							/>
						</TableRow>
					</TableFooter>
				</Table>
				<DialogComponent
					open={open}
					setOpen={setOpen}
					dlgTitle={dlgTitle}
					dlgAction={"Delete"}
					id={id}
					handleClickOk={deleteItem}
				/>
			</div>
		</div>
	);
}
