const ItemActionTypes = {
  DELETE_ITEM: "DELETE_ITEM",
  ADD_ITEM: "ADD_ITEM",
  SAVE_ITEM: "SAVE_ITEM",
  ITEM_ERROR: "ITEM_ERROR",
  FETCH_ITEM_START: "FETCH_ITEM_START",
  FETCH_ITEM_SUCCESS: "FETCH_ITEM_SUCCESS",
  FETCH_ITEM_FAILURE: "FETCH_ITEM_FAILURE",
};

export default ItemActionTypes;
