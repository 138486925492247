import { useEffect, useState } from "react";
import axios from "axios";
import "./batchingridsummary-styles.scss";
import Loading from "../loading-component/Loading.component";
//Material UI
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";

export default function BatchIngridSummaryTbl({ id }) {
	const [ingrids, setIngrids] = useState("");
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const url = `https://c99jtoge7d.execute-api.us-east-2.amazonaws.com/dev?procName=GET_BATCH_INGRIDS_BYID&params=${id}`;

		const request = axios({
			method: "GET",
			url,
		});
		request
			.then((res) => {
				if (!res) {
					alert("No item data found!!!");
				} else {
					console.log("Ingrid Data: ", res.data.result);
					setIngrids(res.data.result);
					setLoading(false);
				}
			})
			.catch((err) => {
				console.error(err);
				setLoading(false);
			});
		return () => {
			setLoading(false);
		};
	}, [id]);

	return (
		<>
			{loading ? (
				<Loading />
			) : (
				<div className="batchIngrid-wrapper">
					<Table className="ingridTbl" aria-label="formula ingridients">
						<TableHead>
							<TableRow>
								<TableCell className="hdrCell">NDC</TableCell>
								<TableCell className="hdrCell">Generic Name</TableCell>

								<TableCell className="hdrCell">
									Stability considerations
								</TableCell>
								<TableCell className="hdrCell">Amount</TableCell>
								<TableCell className="hdrCell">Target Weight</TableCell>
								<TableCell className="hdrCell">QSAD</TableCell>
							</TableRow>
						</TableHead>

						<TableBody>
							{ingrids.map((ingridient, index) => {
								return (
									<TableRow key={index}>
										<TableCell className="ingridCell">
											{ingridient.NDC}
										</TableCell>
										<TableCell className="ingridCell" align="left" scope="row">
											{ingridient.GENERIC}
										</TableCell>

										<TableCell
											className="ingridCell"
											align="left"
											style={{ background: "yellow" }}
										>
											{ingridient.CONSIDERATIONS}
										</TableCell>

										<TableCell className="ingridCell" align="left">
											{ingridient.WEIGHT}
										</TableCell>
										<TableCell align="left" className="ingridCell">
											{ingridient.TARGET_WEIGHT}
										</TableCell>
										<TableCell align="left" className="ingridCell">
											{ingridient.QSAD}
										</TableCell>
									</TableRow>
								);
							})}
						</TableBody>
					</Table>
				</div>
			)}
		</>
	);
}
