/* eslint-disable array-callback-return */
import axios from "axios";

export default function AddFormulaDB(formula) {
	const url =
		"https://c99jtoge7d.execute-api.us-east-2.amazonaws.com/dev/formula";

	const {
		name,
		ndc,
		productType,
		dosage,
		bud,
		storage,
		labels,
		packing,
		final,
		quality,
		milling,
		ingridients,
		method,
		type,
		freq,
		mixSettings,
		jar,
	} = formula;

	var params = [
		name,
		ndc,
		productType,
		dosage,
		bud,
		storage,
		labels,
		packing,
		final,
		quality,
		milling,
		type,
		freq,
		mixSettings,
		jar,
	];
	let procName = "ADD_MASTER_FORMULA";
	let data = { procName, params };
	// console.log("API Data: ", data);
	return new Promise((resolve, reject) => {
		axios
			.post(url, JSON.stringify(data))
			.then(function (resp) {
				if (resp.data.body === '"Success"' && !resp.data.result) {
					ingridients.map((ingridient) => {
						var qsad = "";
						var apis = "";
						ingridient.qsad ? (qsad = "Y") : (qsad = "N");
						ingridient.apis ? (apis = "Y") : (apis = "N");
						let params = [
							name,
							ingridient.ndc,
							ingridient.amount,
							ingridient.unitOfMesure,
							apis,
							qsad,
							ingridient.consider,
						];
						let procName = "ADD_FORMULA_INGRIDS";
						_AddToFormula(procName, params).catch((err) => reject(err));
					});

					method.map(async (meth) => {
						let params = [name, meth.method, meth.descr];
						let procName = "ADD_FORMULA_METHODS";
						_AddToFormula(procName, params).catch((err) => reject(err));
					});
					alert(`Formula ${name} was added successfully !`);

					resolve(resp);
				} else console.log("Error");
				// alert(JSON.stringify(resp.data.result));
				reject(resp.data.result);
			})
			.catch(function (err) {
				// console.error("Problem with Add: ", err);
				reject(err);
			});
	});
}

const _AddToFormula = (procName, params) => {
	const url =
		"https://c99jtoge7d.execute-api.us-east-2.amazonaws.com/dev/formula";

	const data = { procName, params };
	return new Promise((resolve, reject) => {
		axios
			.post(url, JSON.stringify(data))
			.then((resp) => {
				resolve(resp);
			})
			.catch((err) => {
				reject(err);
			});
	});
};
