import { useState } from "react";
//Material Ui
import {
	Table,
	TableBody,
	TableCell,
	IconButton,
	Collapse,
	TableHead,
	TableRow,
	Box,
	Typography,
} from "@mui/material";
import { KeyboardArrowUp, KeyboardArrowDown } from "@mui/icons-material";

export default function FromulaIngridRow(props) {
	const { row } = props;
	const [open, setOpen] = useState(false);

	return (
		<>
			<TableRow>
				<TableCell>
					<IconButton
						aria-label="expand row"
						size="small"
						onClick={() => setOpen(!open)}
					>
						{open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
					</IconButton>
				</TableCell>
				<TableCell align="left">
					<Typography variant="subtitle1" gutterBottom>
						{row.NAME}
					</Typography>
				</TableCell>
				<TableCell align="left">
					<Typography variant="subtitle1" gutterBottom>
						{row.PROD_NDC}
					</Typography>
				</TableCell>
				<TableCell align="left">
					<Typography variant="subtitle1" gutterBottom>
						{row.PRODUCT_TYPE}
					</Typography>
				</TableCell>
			</TableRow>
			<TableRow>
				<TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
					<Collapse in={open} timeout="auto" unmountOnExit>
						<Box margin={1}>
							<Typography variant="h6" gutterBottom component="div">
								Ingridients
							</Typography>
							<Table size="small" aria-label="purchases">
								<TableHead>
									<TableRow>
										<TableCell>NDC </TableCell>
										<TableCell>Name </TableCell>
										<TableCell>Form </TableCell>
										<TableCell>Amount </TableCell>
										<TableCell>UoM</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{row.ingrids.map((ingridRow) => (
										<TableRow key={ingridRow.NDC}>
											<TableCell component="th" scope="row">
												{ingridRow.NDC}
											</TableCell>
											<TableCell>{ingridRow.GENERIC}</TableCell>
											<TableCell>{ingridRow.FORM} </TableCell>
											<TableCell>{ingridRow.AMOUNT} </TableCell>
											<TableCell>{ingridRow.UNIT_MESURE}</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
						</Box>
					</Collapse>
				</TableCell>
			</TableRow>
		</>
	);
}
