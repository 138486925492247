import "./searchndc-styles.scss";
import { SearchOutlined } from "@mui/icons-material";
import InputMask from "react-input-mask";

export default function SearchNdc({ value, onChange, srchAction }) {
	return (
		<div className="searchWrapper">
			<InputMask mask="****9-9999-99" value={value} onChange={onChange}>
				<input className="searchNdc" type="tel" placeholder=" NDC..." />
			</InputMask>

			<button className="srchIcon" onClick={srchAction}>
				<SearchOutlined />
			</button>
		</div>
	);
}
