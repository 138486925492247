import { useState } from "react";
import "./batchlabelrow-styles.scss"; //Style sheet
import { TableRow, TableCell } from "@mui/material";

export default function BatchLabelRow({ row, labelArr, index, rxData }) {
	const [rx, setRx] = useState("");

	const handleRowSave = (e) => {
		e.preventDefault();
		setRx(e.target.value);
		let arr = {};
		arr.id = labelArr[index].id;
		arr.seq = labelArr[index].seq;
		arr.weight = labelArr[index].weight;
		arr.rx = e.target.value;
		labelArr[index] = arr;
		// console.log("Label Array", labelArr);
	};

	return (
		<>
			<TableRow key={row.id}>
				<TableCell className="batchLblCell">{row.seq}</TableCell>
				<TableCell className="batchLblCell">{row.weight}</TableCell>
				<TableCell className="batchLblCell">
					<select
						className="lblSelect"
						native
						id="rx"
						value={rx}
						onChange={handleRowSave}
					>
						<option aria-label="None" value="" />
						{rxData.map((row, i) => (
							<option key={i} value={row.RXNUM}>
								{row.RXNUM}
							</option>
						))}
					</select>
				</TableCell>
			</TableRow>
		</>
	);
}
