import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { UpdateBatch } from "../../utils/BatchHelper";
import "./confirmbatchdialog-styles.scss"; //Style sheet
//Material Ui
import LibraryAddCheckIcon from "@mui/icons-material/LibraryAddCheck";
import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Button,
	TextField,
} from "@mui/material";

export default function ConfirmBatchDialog({ id }) {
	const navigate = useNavigate();
	const [open, setOpen] = useState(false);
	const [name, setName] = useState("");
	var user = useSelector((state) => state.user.user);

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const handleSave = () => {
		if (name.length > 0) {
			let procName = "ADD_BATCH_USERID";
			let params = [id, "CERTIFIED", user.EMAIL];
			UpdateBatch(procName, params)
				.then((resp) => {
					if (!resp.data.errorMessage) {
						UpdateBatchStatus();
						setOpen(false);
						navigate(`/lab`);
					}
				})
				.catch((err) => {
					alert(`Severe Db error ${err}`);
				});
		} else {
			alert("NAME IS REQUIRED!!! COMPOUNDING PHARMACIST MUST CERTIFY BATCH");
		}
	};

	const UpdateBatchStatus = () => {
		let procName = "UPDATE_BATCH_STATUS";
		let params = [id, "COMPLETE"];
		UpdateBatch(procName, params)
			.then((resp) => {
				console.log("Resp: ", resp.data.result[0].sql_error);
				if (!resp.data.errorMessage) {
					alert("Batch completed, record is save in the DB");
					navigate(`../batch-complete`, { replace: true });
				}
			})
			.catch((err) => {
				alert(`Severe Db error ${err}`);
			});
	};

	return (
		<div>
			<Button
				type="submit"
				variant="contained"
				color="primary"
				onClick={handleClickOpen}
				startIcon={<LibraryAddCheckIcon />}
				className="submit"
			>
				Certify batch
			</Button>
			<Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby="form-dialog-title"
			>
				<DialogTitle id="form-dialog-title">Certify</DialogTitle>
				<DialogContent>
					<DialogContentText>
						I (the compounding pharmacist) confirm that this batch was
						compounded in accordance to the standards set forth by Levin’s Drugs
						Inc. The standards define specific chemical ingredients, methods and
						laboratory equipment to be utilized, and the minimum acceptance
						levels prior to compound being introduced to the patient.
					</DialogContentText>

					<TextField
						autoFocus
						margin="dense"
						id="name"
						required
						value={name}
						onChange={(e) => setName(e.target.value)}
						label="Full Name"
						type="text"
						fullWidth
					/>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose} color="primary">
						Cancel
					</Button>
					<Button type="submit" onClick={handleSave} color="primary">
						Certify
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
}
