import "./labhome-styles.scss";
import imgUrl from "../../assets/img/lab-home.jpg";

export default function LabHome() {
	return (
		<div className="container">
			<div className="imageWrapper">
				<img src={imgUrl} alt="LAB" />
			</div>
		</div>
	);
}
