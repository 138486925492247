import { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate, Link } from "react-router-dom";
import "./vendor-list-styles.scss";
import DialogComponent from "../dialog/DialogComponent";
import TablePaginationActions from "../table-paginatoin/TablePagination.component";
import { Add, Edit, Delete } from "@mui/icons-material";
import {
	Table,
	TableBody,
	TableCell,
	TableRow,
	TableHead,
	TableFooter,
	TablePagination,
	IconButton,
} from "@mui/material";

export default function VendorList() {
	const navigate = useNavigate();
	const [data, setData] = useState([]);
	const [open, setOpen] = useState(false);
	const [deleteSw, setDeleteSw] = useState(false);
	const [id, setId] = useState("");
	const dlgTitle = "Delete Vendor";
	const url = `https://c99jtoge7d.execute-api.us-east-2.amazonaws.com/dev?procName=GET_ALL_VENDORS&params=1`;
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(5);

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const handleEdit = (e, id) => {
		e.preventDefault();
		setId(id);
		navigate(`../vendor/edit/${id}`, { replace: false });
	};

	const handleDelete = (e, id) => {
		e.preventDefault();
		setId(id);
		setOpen(true);
	};

	const deleteVendor = (e, id) => {
		e.preventDefault();

		setOpen(false);
		const url = "https://c99jtoge7d.execute-api.us-east-2.amazonaws.com/dev";
		let procName = "DELETE_VENDOR";
		let params = [id];
		var VendorData = {
			procName,
			params,
		};
		const request = axios({
			method: "POST",
			url,
			data: VendorData,
		});
		request
			.then((res) => {
				alert(`Vendor ${id} delted succesfully`);
				setDeleteSw(true);
			})
			.catch((err) => {
				alert(`Severe error ${err}`);
			});
	};

	useEffect(() => {
		const request = axios({
			method: "GET",
			url,
		});
		request
			.then((res) => {
				if (!res) {
					alert("No user data found!!!");
				} else {
					setData(res.data.result);
					console.log("user data => ", data);
				}
			})
			.catch((err) => {
				console.error(err);
			});
	}, [deleteSw]);

	return (
		<div className="listContainer">
			<div className="listTitle">
				<Link to="../vendor/new" className="link">
					<Add /> Add New
				</Link>
			</div>
			<Table className="table" aria-label="Vendors">
				<TableHead className="header">
					<TableRow className="headerRow">
						<TableCell className="headerCell">Vendor </TableCell>
						<TableCell className="headerCell">Phone </TableCell>
						<TableCell className="headerCell">Email </TableCell>
						<TableCell className="headerCell">Contact</TableCell>
						<TableCell className="headerCell">Account</TableCell>
						<TableCell className="headerCell">Terms </TableCell>
						<TableCell className="headerCell">Action </TableCell>
					</TableRow>
				</TableHead>

				<TableBody>
					{data
						.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)

						.map((v) => (
							<TableRow key={v.VENDOR_ID} className="tableRow">
								<TableCell className="cell">{v.VENDOR_NAME}</TableCell>
								<TableCell align="left" className="cell">
									{v.VENDOR_PHONE}
								</TableCell>
								<TableCell align="left" className="cell">
									{v.VENDOR_EMAIL}
								</TableCell>
								<TableCell align="left" className="cell">
									{v.VENDOR_CONTACT}
								</TableCell>
								<TableCell align="left" className="cell">
									{v.VENDOR_CUST_ACCOUNT}
								</TableCell>
								<TableCell align="left" className="cell">
									{v.VENDOR_TERMS}
								</TableCell>

								<TableCell className="action">
									<IconButton
										aria-label="Edit"
										color="primary"
										onClick={(e) => handleEdit(e, v.VENDOR_ID)}
									>
										<Edit />
									</IconButton>

									<IconButton
										aria-label="Delete"
										className="delete"
										onClick={(e) => {
											handleDelete(e, v.VENDOR_ID);
										}}
									>
										<Delete />
									</IconButton>
								</TableCell>
							</TableRow>
						))}
				</TableBody>
				<TableFooter>
					<TableRow className="pagination">
						<TablePagination
							rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
							colSpan={3}
							count={data.length}
							rowsPerPage={rowsPerPage}
							page={page}
							SelectProps={{
								inputProps: {
									"aria-label": "rows per page",
								},
								native: true,
							}}
							onPageChange={handleChangePage}
							onRowsPerPageChange={handleChangeRowsPerPage}
							ActionsComponent={TablePaginationActions}
						/>
					</TableRow>
				</TableFooter>
			</Table>
			<DialogComponent
				open={open}
				setOpen={setOpen}
				dlgTitle={dlgTitle}
				dlgAction={"Delete"}
				id={id}
				handleClickOk={deleteVendor}
			/>
		</div>
	);
}
