import { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { addItem } from "../../store/actions/item.actions";
import PoItemRow from "../po-item-row/PoItemRow.component";
import "./add-po-styles.scss"; //Style sheet
// Materail Ui
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import {
	Table,
	TableHead,
	TableBody,
	TableRow,
	TableCell,
	Typography,
} from "@mui/material";

export default function AddPo() {
	const [time] = useState(moment());
	const navigate = useNavigate();
	var item = useSelector((state) => state.item);
	const dispatch = useDispatch();
	const [data, setData] = useState([]);
	const [vendor, setVendor] = useState("");
	const [poId, setPoId] = useState("");
	const [row, setRow] = useState("");
	const [poDate] = useState(time.format("YYYY-MM-DD"));
	const [order_status] = useState("ORDERED");
	const [ulot] = useState("");
	const [xdate] = useState("");
	var [itemArray, setItemArray] = useState([]);
	const [dt_recived] = useState("");
	const [invoice] = useState("");
	const [recived] = useState(0);
	var [orderData, setOrderData] = useState([]);
	const refObj = item.itemIds.reduce((a, v) => ({ ...a, [v]: v }), {});
	const [deleteSw, setDeleteSw] = useState(false);
	const [poTotal, setPoTotal] = useState("");
	const [removePo, setRemovePo] = useState(false);

	const hanldeRemovePoItem = (e, id) => {
		e.preventDefault();
		item.itemIds = item.itemIds.filter((data) => data !== id);
		orderData = orderData.filter((order) => order.ORDR_ID !== id);
		itemArray = itemArray.filter((item) => item.id !== id);
		dispatch(addItem(item));
		setRemovePo(true);
		handlePoTotal(itemArray);
		setDeleteSw(true);
	};

	const handleDeleteItem = (e, id) => {
		e.preventDefault();
		const url =
			"https://c99jtoge7d.execute-api.us-east-2.amazonaws.com/dev/item";
		let procName = "DELETE_ORDER_ITEM";
		let params = [id];
		var ItemData = {
			procName,
			params,
		};

		const request = axios({
			method: "POST",
			url,
			data: ItemData,
		});
		request
			.then(() => {
				setDeleteSw(true);
				alert(`Order Item was ${id} deleted successfully`);
			})
			.catch((err) => {
				alert(`Error! During DB delete ${err} `);
			});
	};

	const handlePoTotal = (arr) => {
		let total;
		let sum;

		total = (item) => {
			return item.qty * item.cost;
		};

		if (removePo) {
			sum = (prev, next) => {
				return next - prev;
			};
		} else {
			sum = (prev, next) => {
				return prev + next;
			};
		}
		if (arr.length > 0) {
			if (item.itemIds.length !== 1) {
				setPoTotal(arr.map(total).reduce(sum));
			} else {
				setPoTotal(arr[0].qty * arr[0].cost);
			}
		}
	};

	function ccyFormat(num) {
		return `${num.toFixed(2)}`;
	}

	const handleVendorChange = (e) => {
		e.preventDefault();
		setVendor(e.target.value);
		let dt = time.format("YMD");
		let arr = data.find((r) => r.VENDOR_ID.toString() === e.target.value);
		setRow(arr);
		setPoId(`LD-${dt}${e.target.value}`);
	};

	const handleProcessPo = (e) => {
		e.preventDefault();

		if (!poId) {
			alert("Please select vendor!");
			return;
		}

		if (itemArray.length === orderData.length) {
			itemArray.map((u) =>
				handleDBUpdate(u.id, u.cost)
					.then(() => {
						alert(`Item ${u.id} was added to PO ${poId}`);
						navigate("../add-items-po", { replace: true });
					})
					.catch((err) => {
						alert(`Severe error ${err} while processing`);
					})
			);
		} else {
			alert("Please make sure that the COST is populated for all items in PO");
		}
	};

	const handleDBUpdate = (uid, ucost) => {
		const url =
			"https://c99jtoge7d.execute-api.us-east-2.amazonaws.com/dev/batch";
		var params = [
			uid,
			poDate,
			order_status,
			poId,
			ucost,
			dt_recived,
			recived,
			ulot,
			xdate,
			vendor,
			invoice,
		];

		let procName = "UPDATE_ITEM_ORDER";
		let data = { procName, params };

		console.log("Data: ", data);

		return new Promise((resolve, reject) => {
			axios
				.put(url, JSON.stringify(data))
				.then((resp) => {
					console.log("Resp: ", resp);
					resp.data.errorType ? reject(resp.data.errorMessage) : resolve(resp);
				})
				.catch((err) => {
					console.error("Problem with Update: ", err);
					reject(err);
				});
		});
	};

	useEffect(() => {
		const request = axios({
			method: "GET",
			url: `https://c99jtoge7d.execute-api.us-east-2.amazonaws.com/dev?procName=GET_ALL_VENDORS&params=1`,
		});
		request
			.then((res) => {
				if (!res) {
					alert("No vendor data found!!!");
				} else {
					setData(res.data.result);
				}
			})
			.catch((err) => {
				console.error(err);
			});
	}, []);

	useEffect(() => {
		let unmounted = false;
		let source = axios.CancelToken.source();

		const request = axios({
			method: "GET",
			cancelToken: source.token,
			url: `https://c99jtoge7d.execute-api.us-east-2.amazonaws.com/dev?procName=GET_REQUESTED_ITEMS&params=1`,
		});
		request
			.then((res) => {
				if (!unmounted) {
					if (!res) {
						alert("No Order item data found!!!");
					} else {
						setOrderData(
							res.data.result.filter((r) => refObj.hasOwnProperty(r.ORDR_ID))
						);
					}
				}
			})
			.catch((e) => {
				if (!unmounted) {
					alert(`Get DB ${e.message}`);

					if (axios.isCancel(e)) {
						console.log(`request cancelled:${e.message}`);
					} else {
						console.log("another error happened:" + e.message);
					}
				}
			});

		return function () {
			unmounted = true;
			source.cancel("Cancelling in cleanup");
		};
	}, [deleteSw, refObj]);

	return (
		<div className="po-wrapper">
			<div className="title">
				<div className="vendor-select">
					<label htmlFor="vendor-select">Choose vendor:</label>

					<select
						id="vendor-select"
						className="select"
						value={vendor}
						onChange={(e) => handleVendorChange(e)}
					>
						<option value="">--Please choose a vendor --</option>
						{data.map((row) => (
							<option key={row.VENDOR_ID} value={row.VENDOR_ID}>
								{row.VENDOR_NAME}
							</option>
						))}
					</select>
				</div>
			</div>

			{vendor.length > 0 ? (
				<div className="vendor-po-wrapper">
					<div className="title">
						<span className="subtitle">Purchase Order</span>

						<AddShoppingCartIcon />
					</div>
					<div className="vendor-detail">
						<div className="line">
							<div className="lineElement">
								<span className="label">PO:</span>
								<span className="dataField">{poId} </span>
							</div>
							<div className="lineElement">
								<span className="label">PO date:</span>
								<span className="dataField">{poDate} </span>
							</div>

							<div className="lineElement">
								<span className="label">Vendor phone:</span>
								<span className="dataField">{row.VENDOR_PHONE} </span>
							</div>
							<div className="lineElement">
								<span className="label">Vendor email:</span>
								<span className="dataField">{row.VENDOR_EMAIL} </span>
							</div>
							<div className="lineElement">
								<span className="label">Vendor contact:</span>
								<span className="dataField">{row.VENDOR_CONTACT} </span>
							</div>

							<div className="lineElement">
								<span className="label">Account:</span>
								<span className="dataField">{row.VENDOR_CUST_ACCOUNT} </span>
							</div>

							<div className="lineElement">
								<span className="label">Terms:</span>
								<span className="dataField">{row.VENDOR_TERMS} </span>
							</div>
							<div className="lineElement">
								<span className="label"></span>
								<span className="dataField"> </span>
							</div>
						</div>
					</div>
				</div>
			) : null}

			<Table className="table">
				<TableHead className="headerRow">
					<TableRow>
						<TableCell>NDC</TableCell>
						<TableCell>Generic</TableCell>
						<TableCell>Qty</TableCell>
						<TableCell>Cost</TableCell>
						<TableCell>Total</TableCell>
						<TableCell>Status</TableCell>
						<TableCell>Action</TableCell>
						<TableCell></TableCell>
					</TableRow>
				</TableHead>

				<TableBody className="tBody">
					{orderData.map((v) => (
						<PoItemRow
							key={v.ORDR_ID}
							id={v.ORDR_ID}
							ndc={v.NDC}
							generic={v.GENERIC}
							qty={v.QTY}
							status={v.STATUS}
							itemArray={itemArray}
							setItemArray={setItemArray}
							handlePoTotal={handlePoTotal}
							handleDeleteItem={handleDeleteItem}
							hanldeRemovePoItem={hanldeRemovePoItem}
						/>
					))}

					<TableRow>
						<TableCell rowSpan={5} />
						<TableCell colSpan={2}>
							<Typography
								style={{ fontSize: 16, fontWeight: 700, fontStyle: "italic" }}
							>
								PO Total
							</Typography>
						</TableCell>
						<TableCell
							align="right"
							style={{ fontSize: 16, fontWeight: 700, fontStyle: "italic" }}
						>
							${ccyFormat(Number(poTotal))}
						</TableCell>
					</TableRow>
				</TableBody>
			</Table>

			<div className="center">
				<button className="process" onClick={handleProcessPo}>
					Process PO
				</button>
			</div>
		</div>
	);
}
