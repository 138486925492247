import FormulaActionTypes from "../actions/formula.action.types";

const initialState = {
	formula: {
		id: 0,
		name: "",
		ndc: "COMP",
		productType: "",
		dosage: "",
		bud: "0",
		storage: "",
		labels: "",
		packing: "",
		final: "",
		quality: "",
		milling: "N",
		type: "",
		freq: "",
		ingridients: [],
		method: [],
		consider: [],
		errorMsg: null,
		isFetching: false,
		isSuccess: false,
		isFound: false,
	},
};

const formulaReducer = (state = initialState, action) => {
	let formula = initialState.formula;
	switch (action.type) {
		case FormulaActionTypes.FETCH_FORMULA_START:
			return {
				...state,
				isFatching: true,
			};
		case FormulaActionTypes.FETCH_FORMULA_SUCCESS:
			return {
				formula: action.payload,
				isFatching: false,
				isSuccess: true,
				isFound: true,
			};
		case FormulaActionTypes.FETCH_FORMULA_FAILURE:
			return {
				formula,
				errorMsg: action.payload,
				isFatching: false,
				isSuccess: false,
				isFound: false,
			};
		case FormulaActionTypes.FETCH_FORMULA_NOT_FOUND:
			return {
				formula,
				errorMsg: action.payload,
				isFatching: false,
				isSuccess: false,
				isFound: false,
			};

		case FormulaActionTypes.DELETE_FORMULA:
			return {
				formula: initialState.formula,
			};

		case FormulaActionTypes.ADD_FORMULA:
			return {
				formula: action.payload,
			};

		default:
			return state;
	}
};

export default formulaReducer;
