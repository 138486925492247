/* eslint-disable array-callback-return */
import axios from "axios";

export const BatchDbAdd = (batch) => {
	const url =
		"https://c99jtoge7d.execute-api.us-east-2.amazonaws.com/dev/batch";

	const { id, userid, totalAmount, bud, notes, stamp, ingrids, rxArr } = batch;

	var params = [id, userid, totalAmount, bud, notes, stamp];

	let procName = "ADD_BATCH";
	let data = { procName, params };
	return new Promise((resolve, reject) => {
		axios
			.post(url, JSON.stringify(data))
			.then(async function (resp) {
				if (resp.data.body === '"Success"') {
					const batchid = await _getSequence(id);
					ingrids.map((ingridient) => {
						const { NDC, target_wght, weight, lot } = ingridient;
						let params = [batchid, NDC, target_wght, weight, lot];
						let procName = "ADD_BATCH_INGRIDS";
						_AddToBatch(procName, params);
					});
					rxArr.map((r) => {
						const { rx, weight } = r;
						let params = [batchid, rx, weight];
						let procName = "ADD_BATCH_RX";
						_AddToBatch(procName, params);
					});
					// alert(`Batch ${batchid} was added successfully`);
					resolve(resp);
				} else reject(`Severe DB Error - Batch Add ${resp}`);
			})
			.catch(function (err) {
				console.error("Problem with Add: ", err);
				reject(`Severe DB error ${err}`);
			});
	});
};

export const _getSequence = (id) => {
	const url = `https://c99jtoge7d.execute-api.us-east-2.amazonaws.com/dev/batch?procName=GET_BATCH_SEQ&params=${id}`;

	return new Promise((resolve, reject) => {
		axios
			.get(url)
			.then(function (resp) {
				// console.log("resp seq: ", resp.data.result[0].ID);
				resolve(resp.data.result[0].ID);
			})
			.catch(function (err) {
				console.error("Problem with Get SEQ: ", err);
				reject(err);
			});
	});
};

const _AddToBatch = (procName, params) => {
	const url =
		"https://c99jtoge7d.execute-api.us-east-2.amazonaws.com/dev/batch";

	const data = { procName, params };
	console.log("data==>", JSON.stringify(data));
	return new Promise((resolve, reject) => {
		axios
			.post(url, JSON.stringify(data))
			.then(function (resp) {
				// console.log("resp: ", resp.data.errorMessage);
				resp.data.errorMessage ? reject(resp.data.errorMessage) : resolve(resp);
			})
			.catch(function (err) {
				console.error("Problem with Add: ", err);
				reject(err);
			});
	});
};

export const UpdateBatch = (procName, params) => {
	const url =
		"https://c99jtoge7d.execute-api.us-east-2.amazonaws.com/dev/batch";

	const data = { procName, params };
	console.log("data==>", JSON.stringify(data));
	return new Promise((resolve, reject) => {
		axios
			.post(url, JSON.stringify(data))
			.then(function (resp) {
				// console.log("resp: ", resp.data.errorMessage);
				if (resp.data.errorMessage) {
					reject(resp.data.errorMessage);
				}
				if (resp.data.result[0].sql_error) {
					reject(`${resp.data.result[0].sql_error} for ${procName}`);
				}

				resolve(resp);
			})
			.catch(function (err) {
				console.error("Problem with Add: ", err);
				reject(err);
			});
	});
};

export const UpdateBatchUid = (batch_id, uid, column) => {
	const _buildParams = () => {
		let params = [];
		console.log("USER: ", uid);
		// millid, packid, labelid, rphid
		switch (column) {
			case "MILL_ID":
				params = [batch_id, "MILED", uid];
				break;
			case "PACK_ID":
				params = [batch_id, "PACKED", uid];
				break;
			case "LABEL_ID":
				params = [batch_id, "LABELED", uid];
				break;
			case "RPH_ID":
				params = [batch_id, "APPROVED", uid];
				break;
			default:
				alert(`Wrog Value: ${column}`);
				break;
		}
		return params;
	};

	return new Promise((resolve, reject) => {
		let params = _buildParams();
		let procName = "ADD_BATCH_USERID";
		UpdateBatch(procName, params)
			.then((resp) => {
				if (resp.data.errorMessage) {
					reject(resp.data.errorMessage);
				}
				if (resp.data.result[0].sql_error) {
					reject(`${resp.data.result[0].sql_error} for ${procName}`);
				}

				resolve(resp);
			})
			.catch((err) => reject(err));
	});
};
