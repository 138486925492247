import { useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { BatchDbAdd } from "../../utils/BatchHelper";
import InputMask from "react-input-mask";
import BatchRxRecords from "../batch-rx-record/BatchRxRecords.component";
import BatchMethodTable from "../batch-method-table/BatchMethodTable.component";
import BatchIngridTable from "../batch-ingrid-table/BatchIngridTable.component";
import "./createbatch-styles.scss"; //Stylesheet
//Material Ui
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import AddCircleIcon from "@mui/icons-material/AddCircle";
export default function CreateBatch() {
	const [formula, setFormula] = useState({});
	const user = useSelector((state) => state.user.user);
	const [bud, setBud] = useState("");
	const [ndc, setNdc] = useState("");
	const [totalAmount, setTotalAmount] = useState(0);
	const [notes, setNotes] = useState("");
	const [totalTarget] = useState([]);
	// var targetArr = [];
	const [setFeched] = useState(false);
	const [ingrids, setIngrids] = useState([]);
	const [rxArr, setRxArr] = useState([]);

	const addDays = (days) => {
		var date = new Date();
		date.setDate(date.getDate() + days);
		return date.toLocaleDateString();
	};

	const formatedTimestamp = () => {
		const d = new Date();
		const date = d.toISOString().split("T")[0];
		const time = d.toTimeString().split(" ")[0];
		return `${date} ${time}`;
	};

	// const handleTotalChange = (e) => {
	// 	e.preventDefault();
	// 	setTotalAmount(e.target.value);
	// 	if (formula.length > 0) {
	// 		if (
	// 			formula.PRODUCT_TYPE !== "CAPSULE" ||
	// 			formula.PRODUCT_TYPE !== "TABLET"
	// 		) {
	// 			// formula.ingridients.map((ingirid) => {
	// 			ingrids.map((ingirid) => {
	// 				var weight = 0;
	// 				var amount = ingirid.AMOUNT;
	// 				var strength = ingirid.STRENGTH;
	// 				var unit_weight = ingirid.UNIT_WEIGHT;
	// 				var total = e.target.value;

	// 				if (ingirid.FORM === "TABLET" || ingirid.FORM === "CAPSULE") {
	// 					weight = ((total * (amount * 0.01)) / strength) * unit_weight;
	// 				} else {
	// 					weight = total * (amount * 0.01);
	// 				}
	// 				if (ingirid.QSAD !== "Y") {
	// 					targetArr.push(Math.round(weight * 1000) / 1000);
	// 				}
	// 			});
	// 		}
	// 	}
	// 	setTotalTarget(targetArr);
	// };

	const handleFormulaSearch = (e) => {
		e.preventDefault();
		setRxArr([]);
		setTotalAmount(0);
		setFormula([]);
		setIngrids([]);
		setNotes("");
		const url =
			"https://c99jtoge7d.execute-api.us-east-2.amazonaws.com/dev/formula?procName=GET_FORMULA&params=" +
			encodeURIComponent(ndc);

		const request = axios({
			method: "GET",
			url,
		});
		request.then(async (res) => {
			if (res.data.result.length === 0) {
				alert(`Formula with NDC ${ndc} was not found`);
			} else {
				console.log("FORMULA: ", res.data.result[0]);
				setFormula(res.data.result[0]);
				setBud(addDays(parseInt(res.data.result[0].ESTIMATED_BUD)));
				setFeched(true);
			}
		});
	};

	const handleSave = (e) => {
		e.preventDefault();
		if (totalAmount > 0) {
			if (rxArr.length > 0) {
				let id = formula.ID;
				let userid = user.EMAIL;
				let stamp = formatedTimestamp();

				let batch = {
					id,
					userid,
					totalAmount,
					bud,
					notes,
					stamp,
					ingrids,
					rxArr,
				};
				// console.log("Batch: ", batch);

				// console.log("Rx Arr", rxArr);
				BatchDbAdd(batch)
					.then(() => {
						setNdc("");
						setFormula([]);
						setBud("");
						setRxArr([]);
						setTotalAmount(0);
						setNotes("");
						alert("Batch was added successfully");
					})
					.catch((err) => {
						alert(err);
					});
			} else {
				alert("Atleast one RX has to be assigned to a batch");
			}
		} else {
			alert("Total Batch weight is required!");
		}
	};

	return (
		<div className="batch-wrapper">
			<div className="searchWrapper">
				<InputMask
					mask="****9-9999-99"
					value={ndc}
					onChange={(e) => {
						setNdc(e.target.value);
					}}
					className="ndc"
				>
					<input
						className="searchNdc"
						type="tel"
						placeholder="FORMULA NDC..."
					/>
				</InputMask>

				<button className="srchIcon" onClick={handleFormulaSearch}>
					<SearchOutlinedIcon />
				</button>
			</div>
			<div className="formula-header">
				<span className="formulaName">{formula.NAME}</span>
				<div className="infoType">
					<span className="formula-info">Treat: {formula.TYPE}</span>
					<span className="formula-info">Type: {formula.PRODUCT_TYPE}</span>
					<span className="formula-info">
						Final product: {formula.DESCR_FINAL_PRODUCT}
					</span>
				</div>

				<div className="batch-input">
					<span className="formula-info">
						Total Batch Amount: <span className="infoColor">{totalAmount}</span>
					</span>
					<span className="formula-info">
						Batch Exparation Date: <span className="infoColor">{bud}</span>
					</span>
				</div>

				<div className="batch-input">
					<label htmlFor="notes">Batch Notes:</label>
					<textarea
						type="text"
						className="batch-notes"
						cols="95"
						rows="4"
						id="notes"
						value={notes}
						onChange={(e) => {
							setNotes(e.target.value);
						}}
					/>
				</div>
			</div>
			<div className="batchInfo">
				<BatchRxRecords
					rxArr={rxArr}
					setRxArr={setRxArr}
					setTotalAmount={setTotalAmount}
				/>
				<BatchMethodTable formula={formula} />
				<BatchIngridTable
					formula={formula}
					ingrids={ingrids}
					setIngrids={setIngrids}
					totalAmount={totalAmount}
					targetArr={totalTarget}
				/>
			</div>
			<div className="center">
				<button className="save" onClick={handleSave}>
					<AddCircleIcon /> Create batch
				</button>
			</div>
		</div>
	);
}
