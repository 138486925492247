import { useState } from "react";
import "./poitemrow.styles.scss";
// Material Ui
import { Clear, Delete } from "@mui/icons-material";
import {
	TableCell,
	TableRow,
	InputAdornment,
	TextField,
	IconButton,
} from "@mui/material";

export default function PoItemRow({
	id,
	ndc,
	generic,
	qty,
	status,
	itemArray,
	setItemArray,
	handlePoTotal,
	handleDeleteItem,
	hanldeRemovePoItem,
}) {
	const [itmTotal, setItmTotal] = useState("");
	const [cost, setCost] = useState("");

	const handleCostChange = (e) => {
		e.preventDefault();
		setCost(e.target.value);
		let cost = e.target.value;
		let arr = itemArray;
		const objIndex = arr.findIndex((obj) => obj.id === id);

		objIndex === -1
			? arr.push({ id, ndc, generic, qty, cost, status })
			: (arr[objIndex].cost = cost);

		// console.log("Array: ", arr);
		handlePoTotal(arr);
		setItemArray(arr);
		setItmTotal(qty * cost);
	};

	return (
		<TableRow className="table-row">
			<TableCell className="ndc">{ndc}</TableCell>
			<TableCell className="cell">{generic}</TableCell>
			<TableCell className="cell">{qty}</TableCell>

			<TableCell className="cell">
				<TextField
					margin="normal"
					className="amount"
					variant="outlined"
					id="cost"
					name="cost"
					size="small"
					value={cost}
					InputProps={{
						startAdornment: <InputAdornment position="start">$</InputAdornment>,
					}}
					onChange={handleCostChange}
				/>
			</TableCell>
			<TableCell className="cell">
				<TextField
					className="amount"
					margin="normal"
					variant="outlined"
					id="total"
					name="total"
					size="small"
					value={itmTotal}
					InputProps={{
						startAdornment: <InputAdornment position="start">$</InputAdornment>,
					}}
					disabled
				/>
			</TableCell>

			<TableCell className="cell">{status}</TableCell>

			<TableCell className="cell">
				<IconButton
					aria-label="Delete"
					className="delete"
					onClick={(e) => handleDeleteItem(e, id)}
				>
					<Delete />
				</IconButton>
			</TableCell>
			<TableCell className="cell">
				<IconButton
					aria-label="Delete"
					className="remove"
					onClick={(e) => hanldeRemovePoItem(e, id)}
				>
					<Clear />
				</IconButton>
			</TableCell>
		</TableRow>
	);
}
