import "./formula-form.styles.scss";
import InputMask from "react-input-mask";
import { IOSSwitch } from "../switch/IOSSwitch.component.jsx";
import Divider from "@mui/material/Divider";
import FormLabel from "@mui/material/FormLabel";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

export default function FormulaForm(params) {
	const {
		ndc,
		setNdc,
		name,
		setName,
		type,
		setType,
		dosage,
		setDosage,
		bud,
		setBud,
		storage,
		setStorage,
		// labels,
		// setLabels,
		packing,
		setPacking,
		final,
		setFinal,
		quality,
		setQuality,
		milling,
		setMilling,
		treat,
		setTreat,
		freq,
		setFreq,
		mixSettings,
		setMixSettings,
		jar,
		setJar,
		specially,
		setSpecially,
		external,
		setExternal,
		refrigerate,
		setRefigerete,
		shake,
		setShake,
		sun,
		setSun,
		rectal,
		setRectal,
		vaginal,
		setVaginal,
		poison,
		setPoison,
	} = params;

	return (
		<>
			<form className="formula-form">
				<div className="form-line">
					<div className="formInput">
						<label>PrimeRx NDC:</label>
						<InputMask
							mask="COMP9-9999-99"
							value={ndc}
							onChange={(e) => setNdc(e.target.value)}
						>
							<input type="text" required className="ndc" autoFocus />
						</InputMask>
					</div>

					<div className="formInput">
						<label>Formula Name:</label>
						<input
							type="text"
							className="form-field"
							value={name}
							required
							style={{ textTransform: "uppercase" }}
							onChange={(e) => setName(e.target.value)}
						/>
					</div>
				</div>
				<div className="selectCntrl">
					<label htmlFor="type-select">Type of compound:</label>

					<select
						id="type-select"
						className="select"
						value={type}
						required
						onChange={(e) => setType(e.target.value)}
					>
						<option value="">--Choose compound type--</option>
						<option value={"CREAM"}>Cream</option>
						<option value={"OINTMENT"}>Ointment</option>
						<option value={"GEL"}>Gel</option>
						<option value={"LOTION"}>Lotion</option>
						<option value={"PASTE"}>Paste</option>
						<option value={"FOAM"}>Foam</option>
						<option value={"SHAMPOO"}>Shampoo</option>
						<option value={"POWDER"}>Powder</option>
						<option value={"SPRAY"}>Spray</option>
						<option value={"NAILLACQUER"}>Nail lacquer</option>
						<option value={"LIQUID"}>Liquid</option>
						<option value={"CAPSULE"}>Capsule</option>
						<option value={"TABLET"}>Tablet</option>
						<option value={"LOZENGES"}>Lozenges</option>
						<option value={"TROCHE"}>Troche</option>
						<option value={"SUPPOSITORY"}>Suppository</option>
						<option value={"SUSPENSION"}>Suspension</option>
						<option value={"SYRUP"}>Syrup</option>
					</select>
				</div>

				<div className="selectCntrl">
					<label htmlFor="bud-select">Beyond use day:</label>

					<select
						id="bud-select"
						className="select"
						value={bud}
						required
						onChange={(e) => setBud(e.target.value)}
					>
						<option value="">--Choose BUD--</option>
						<option value={"14"}>14 Days </option>
						<option value={"30"}>30 Days </option>
						<option value={"35"}>35 Days </option>
						<option value={"60"}>60 Days </option>
						<option value={"90"}>90 Days </option>
						<option value={"180"}>180 Days </option>
					</select>
				</div>

				<div className="selectCntrl">
					<label htmlFor="storage">Storage:</label>

					<select
						className="select"
						id="storage"
						required
						value={storage}
						onChange={(e) => setStorage(e.target.value)}
					>
						<option value="">--Choose storage--</option>
						<option value={"Room temperature"}>Room temperature </option>
						<option value={"Refrigerate"}>Refrigerate </option>
					</select>
				</div>
				<div className="selectCntrl">
					<label htmlFor="freq">Frequency: </label>

					<select
						className="select"
						id="freq"
						value={freq}
						required
						onChange={(e) => setFreq(e.target.value)}
					>
						<option value="">--Choose frequency--</option>
						<option value={"QD"}>Apply ONCE a day </option>
						<option value={"BID"}>Apply TWICE a day </option>
						<option value={"TID"}>Apply THREE times a day </option>
						<option value={"QID"}>Apply FOUR times a day</option>
						<option value={"UD"}>Apply as prescribed </option>
						<option value={"PRN"}>Apply as needed </option>
						<option value={"Q4H"}>Apply every 4 hours </option>
						<option value={"Q6H"}>Apply every 6 hours </option>
						<option value={"QOD"}>Apply every other day</option>
						<option value={"TIW"}>Apply TWICE a WEEK </option>
						<option value={"BIW"}>Apply EVERY OTHER WEEK </option>
					</select>
				</div>
				<div className="formInput">
					<label>SIG:</label>
					<input
						type="text"
						className="form-field"
						rows={3}
						value={dosage}
						required
						onChange={(e) => setDosage(e.target.value)}
					/>
				</div>
				<div className="formInput">
					<label>Milling required:</label>
					<IOSSwitch
						checked={milling}
						onChange={(e) => setMilling(e.target.checked)}
						name="Milling"
					/>
				</div>
				<div className="formInput">
					<label>Mixer settings:</label>
					<input
						type="text"
						className="form-field"
						rows={3}
						value={mixSettings}
						required
						onChange={(e) => setMixSettings(e.target.value)}
					/>
				</div>
				<div className="formInput">
					<label>Weight of compound + jar:</label>
					<input
						type="text"
						required
						className="form-field"
						id="jar"
						value={jar}
						name="jar"
						onChange={(e) => setJar(e.target.value)}
					/>
				</div>
				<div className="selectCntrl">
					<label htmlFor="packing">Packaging: </label>

					<select
						className="select"
						id="packing"
						name="packing"
						value={packing}
						required
						onChange={(e) => setPacking(e.target.value)}
					>
						<option value="">--Choose container size--</option>
						<option value={"Small/medium/large pump bottle"}>
							Small/medium/large pump bottle
						</option>
						<option value={"Small pump bottle - 50ml"}>
							Small pump bottle - 50ml
						</option>
						<option value={"Medium pump bottle - 104ml"}>
							Medium pump bottle - 104ml
						</option>
						<option value={"Large pump bottle - 154ml"}>
							Large pump bottle - 154ml
						</option>
						<option value={"Amber glass bottle  - 30ml"}>
							Amber glass bottle - 30ml
						</option>
						<option value={"Blue glass bottle - 60ml"}>
							Blue glass bottle - 60ml
						</option>
						<option value={"White foam bottle - 100ml"}>
							White foam bottle 100ml
						</option>
						<option value={"Amber plastic bottle - 120ml"}>
							Amber plastic bottle - 120ml
						</option>
						<option value={"Amber plastic bottle - 240ml"}>
							Amber plastic bottle - 240ml
						</option>
					</select>
				</div>
				<div className="selectCntrl">
					<label htmlFor="treat">Type of Compound: </label>

					<select
						className="select"
						id="treat"
						required
						value={treat}
						onChange={(e) => setTreat(e.target.value)}
					>
						<option value="">--Choose type--</option>
						<option value={"PAIN"}>Pain</option>
						<option value={"FUNGAL NAIL"}>Fungal Nail</option>
						<option value={"TOPICAL FUNGAL"}>Topical Fungal</option>
						<option value={"DRY SKIN"}>Topical Dry or Itchy Skin</option>
						<option value={"BLEACHING"}>Bleaching</option>
						<option value={"ACNE"}>Acne</option>
						<option value={"TINEA"}>TINEA</option>
						<option value={"ROSACEA"}>Rosacea</option>
						<option value={"DERMATOLOGY"}>Dermotology</option>
						<option value={"HAIR LOSS"}>Hair Loss</option>
						<option value={"PSORIASIS"}>Psoriasis</option>
						<option value={"WART"}>Wart</option>
						<option value={"HEMORRHOID"}>Hemorrhoid</option>
						<option value={"ORAL"}>Oral</option>
					</select>
				</div>
				<div className="formInput">
					<label htmlFor="final">Final Product:</label>
					<input
						type="text"
						required
						className="form-field"
						id="final"
						value={final}
						name="final"
						onChange={(e) => setFinal(e.target.value)}
					/>
				</div>
				<div className="formInput">
					<label htmlFor="quality">Quality Control: </label>
					<input
						type="text"
						required
						className="form-field"
						id="quality"
						value={quality}
						name="quality"
						onChange={(e) => setQuality(e.target.value)}
					/>
				</div>
				<Divider className="divider" />
				<FormControl className="aux-wrapper">
					<FormLabel component="legend" className="legend">
						Auxilary labels
					</FormLabel>
					<FormGroup>
						<div className="left">
							<FormControlLabel
								control={
									<Checkbox
										// className={classes.aux}
										checked={specially}
										onChange={(e) => setSpecially(e.target.checked)}
										name="specially"
									/>
								}
								label="Specially made for you"
							/>
							<FormControlLabel
								control={
									<Checkbox
										// className={classes.aux}
										checked={external}
										onChange={(e) => setExternal(e.target.checked)}
										name="external"
									/>
								}
								label="External use only"
							/>

							<FormControlLabel
								control={
									<Checkbox
										// className={classes.aux}
										checked={refrigerate}
										onChange={(e) => setRefigerete(e.target.checked)}
										name="refrigerate"
									/>
								}
								label="Refrigerate, do not freeze"
							/>

							<FormControlLabel
								control={
									<Checkbox
										// className={classes.aux}
										checked={shake}
										onChange={(e) => setShake(e.target.checked)}
										name="shake"
									/>
								}
								label="Shake well before use"
							/>
						</div>
						<div className="right">
							<FormControlLabel
								control={
									<Checkbox
										// className={classes.aux}
										checked={sun}
										onChange={(e) => setSun(e.target.checked)}
									/>
								}
								label="Sun sensitivity"
							/>

							<FormControlLabel
								control={
									<Checkbox
										// className={classes.aux}
										checked={rectal}
										onChange={(e) => setRectal(e.target.checked)}
									/>
								}
								label="Rectal use only"
							/>

							<FormControlLabel
								control={
									<Checkbox
										// className={classes.aux}
										checked={vaginal}
										onChange={(e) => setVaginal(e.target.checked)}
									/>
								}
								label="Vaginal use only"
							/>

							<FormControlLabel
								control={
									<Checkbox
										// className={classes.aux}
										checked={poison}
										onChange={(e) => setPoison(e.target.checked)}
									/>
								}
								label="Poison"
							/>
						</div>
					</FormGroup>
				</FormControl>
			</form>
		</>
	);
}
