import { useState } from "react";
import { useDispatch } from "react-redux";
import "./userform.scss";
import imgUrl from "../../assets/img/usermages.png";
import { CreateAwsUser } from "../../utils/aws-cognito";
import { AddDbUser } from "../../store/actions/user.actions";

function UserForm() {
	const dispatch = useDispatch();
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [confPassword, setConfPassword] = useState("");
	const [fName, setFname] = useState("");
	const [lName, setLname] = useState("");
	const [phone, setPhone] = useState("");

	const handleSave = async (e) => {
		e.preventDefault();
		confPassword !== password
			? alert("Password and Confirm Password don't match")
			: await CreateAwsUser(email, password)
					.then(async (data) => {
						console.log("New User: ", data);
						let dbuser = { email, fName, lName, phone };
						await dispatch(AddDbUser(dbuser))
							.then(() => {
								alert("User is added, success!");
								setEmail("");
								setPassword("");
								setFname("");
								setLname("");
								setPhone("");
								setConfPassword("");
							})
							.catch((err) => {
								alert("error: ", JSON.stringify(err));
							});
					})
					.catch((err) => {
						alert("error: ", JSON.stringify(err));
					});
	};

	return (
		<div className="userForm">
			<div className="left">
				<img src={imgUrl} alt="user" />
			</div>
			<div className="right">
				<form className="form" onSubmit={handleSave}>
					<div className="formInput">
						<label>Email</label>
						<input
							type="email"
							value={email}
							onChange={(e) => setEmail(e.target.value)}
							required
						/>
					</div>

					<div className="formInput">
						<label>Phone number</label>
						<input
							type="text"
							value={phone}
							required
							onChange={(e) => setPhone(e.target.value)}
						/>
					</div>

					<div className="formInput">
						<label>Password</label>
						<input
							type="password"
							value={password}
							onChange={(e) => setPassword(e.target.value)}
							required
						/>
					</div>

					<div className="formInput">
						<label>Confirm password</label>
						<input
							type="password"
							value={confPassword}
							required
							onChange={(e) => setConfPassword(e.target.value)}
						/>
					</div>

					<div className="formInput">
						<label>First Name</label>
						<input
							type="text"
							value={fName}
							label="First Name"
							required
							onChange={(e) => setFname(e.target.value)}
						/>
					</div>

					<div className="formInput">
						<label>Last Name Name</label>
						<input
							type="text"
							value={lName}
							required
							onChange={(e) => setLname(e.target.value)}
						/>
					</div>

					<div className="save">
						<button type="submit">Save</button>
					</div>
				</form>
			</div>
		</div>
	);
}

export default UserForm;
