import { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";
import { useNavigate, useParams } from "react-router-dom";
import { SaveOutlined } from "@mui/icons-material";

import "./edititem-styles.scss"; //Style Sheet

export default function EditItemOrder() {
	let navigate = useNavigate();
	const { id } = useParams();
	const [data, setData] = useState({});
	const [recDate, setRecDate] = useState("");
	const [recQty, setRecQty] = useState("");
	const [ndc, setNdc] = useState("");

	const dateValid = (date) => {
		return moment(date).format("YYYY-MM-DD");
	};

	const handleSave = async (e) => {
		e.preventDefault();
		let status = "RECEIVED";
		let date = recDate;

		if (recQty === "0") {
			status = "ORDERED";
			date = "";
		}

		var params = [id, status, date, recQty];
		let procName = "UPDATE_ORDER_ITEM_BY_ID";
		const url =
			"https://c99jtoge7d.execute-api.us-east-2.amazonaws.com/dev/batch";
		const data = { procName, params };
		console.log("Update: ", data);
		axios
			.put(url, JSON.stringify(data))
			.then((resp) => {
				console.log("Resp: ", resp);
				if (resp.data.errorType) {
					alert(`DB Error occured ${resp.data.errorMessage}`);
				} else {
					alert(`Item ${ndc} was updated successfully!`);
					navigate("../po-history");
				}
			})
			.catch((err) => {
				alert(`Error Add: ${err}`);
			});
	};

	useEffect(() => {
		const url = `https://c99jtoge7d.execute-api.us-east-2.amazonaws.com/dev?procName=GET_ORDER_ITEM_BY_ID&params=${id}`;

		const request = axios({
			method: "GET",
			url,
		});
		request
			.then((res) => {
				if (!res) {
					alert("No item data found!!!");
				} else {
					// console.log("Data: ", res.data.result[0]);
					setData(res.data.result[0]);
					setRecDate(dateValid(res.data.result[0].DATE_RECEIVED));

					setRecQty(res.data.result[0].QTY_RECIVED);
					setNdc(res.data.result[0].NDC);
				}
			})

			.catch((err) => {
				console.error(err);
			});
	}, [id]);

	return (
		<div className="item-wrapper">
			<div className="item-details">
				<p>
					<span>NDC: </span> {data.NDC}
				</p>
				<p>{data.GENERIC}</p>
				<p>
					<span>Date Ordered: </span>
					{dateValid(data.ORDR_DT)}
				</p>
				<p>
					{" "}
					<span>Ordered Quantity: </span>
					{data.QTY}
				</p>
			</div>
			<div className="item-updt">
				<div className="detail">
					<label htmlFor="dateRec" className="dtl-label">
						Date received:{" "}
					</label>
					<input
						className="rec-dtl-dt"
						type="date"
						id="dateRec"
						value={recDate}
						onChange={(e) => setRecDate(e.target.value)}
					/>
				</div>
				<div className="detail">
					<label htmlFor="recQty" className="dtl-label">
						Quantity received:
					</label>
					<input
						className="rec-dtl"
						type="nubmer"
						id="recQty"
						value={recQty}
						onChange={(e) => setRecQty(e.target.value)}
					/>
				</div>
			</div>
			<div className="save-box">
				<button className="saveBtn" onClick={handleSave}>
					<SaveOutlined className="saveIcon" /> Save
				</button>
			</div>
		</div>
	);
}
