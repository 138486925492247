import { useState, useEffect } from "react";
import "./formula-styles.scss"; // Style sheet
import { useDispatch } from "react-redux";
import { deleteFromula } from "../../store/actions/fromula.actions";
import AddFormula from "../add-formula/AddFormula.component";
import FormulaIngrids from "../formula-ingrids/FormulaIngrids.component";

import "./formula-styles.scss"; // Style sheet
//Material UI
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import FormulaMethods from "../formula-methods/FormulaMethods.component";
import FormulalaReview from "../fromula-review/FormulaReview.component";

function getSteps() {
	return [
		"Formula Defenition",
		"Formula Ingridients",
		"Formula Methods",
		"Formula Review",
	];
}

export default function CreateMasterFormula() {
	const dispatch = useDispatch();
	const [activeStep, setActiveStep] = useState(0);
	const steps = getSteps();
	const [formula, setFormula] = useState({});

	const handleNext = () => {
		setActiveStep((prevActiveStep) => prevActiveStep + 1);
	};

	const handleBack = () => {
		setActiveStep((prevActiveStep) => prevActiveStep - 1);
	};

	const handleReset = () => {
		setActiveStep(0);
	};

	const getStepContent = (stepIndex) => {
		switch (stepIndex) {
			case 0:
				return (
					<AddFormula
						nextStep={handleNext}
						formula={formula}
						setFormula={setFormula}
					/>
				);
			case 1:
				return (
					<FormulaIngrids
						nextStep={handleNext}
						prevStep={handleBack}
						formula={formula}
						setFormula={setFormula}
					/>
				);
			case 2:
				return (
					<FormulaMethods
						nextStep={handleNext}
						prevStep={handleBack}
						formula={formula}
						setFormula={setFormula}
					/>
				);
			case 3:
				return (
					<FormulalaReview
						prevStep={handleBack}
						activeStep={activeStep}
						formula={formula}
						setFormula={setFormula}
					/>
				);
			default:
				return "Unknown stepIndex";
		}
	};

	useEffect(() => {
		dispatch(deleteFromula());
	});

	return (
		<div className="step-wrapper">
			<Stepper activeStep={activeStep} alternativeLabel>
				{steps.map((label) => (
					<Step key={label}>
						<StepLabel>{label}</StepLabel>
					</Step>
				))}
			</Stepper>

			<div>
				{activeStep === steps.length ? (
					<div>
						<span className="instructions">All steps completed</span>
						<Button onClick={handleReset}>Reset</Button>
					</div>
				) : (
					<div>
						<span className="instructions">{getStepContent(activeStep)}</span>
					</div>
				)}
			</div>
		</div>
	);
}
