import "./home.scss";
import NavBar from "../../components/navbar/NavBar.component";
import SideBar from "../../components/sidebar/SideBar.component";
import levinsLogo from "../../assets/img/levins logo.jpg";
import Footer from "../../components/footer/Footer.component";
import { IdleTimerContainer } from "../../components/idle-time/IdleTimerContainer.js";

function HomePage({ source }) {
	return (
		<IdleTimerContainer>
			<div className="home">
				<SideBar source={source} />
				<div className="homeContainer">
					<NavBar />
					<div className="top">
						<span className="title">AexsysRx</span>
						<span className="subtitle">
							Where Innovative Science Enhances Patient Care
						</span>
					</div>
					<div className="img-wrapper">
						<img src={levinsLogo} alt="" className="logo-image" />
					</div>
					<Footer />
				</div>
			</div>
		</IdleTimerContainer>
	);
}

export default HomePage;
