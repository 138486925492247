import { useState } from "react";
import axios from "axios";
import InputMask from "react-input-mask";
import "./item-purchase-styles.scss"; //Style Sheet
import {
	stableSort,
	getComparator,
	EnhancedTableHead,
} from "../table-sort/TableSort.component";
import { ccyFormat, GrandTotal } from "../grand-total/GrandTotal.component";
import { BarChrt } from "../../components/chart/Chart.component";

//Material Ui
import { Table, TableBody, TableRow, TableCell } from "@mui/material";
import { SearchOutlined } from "@mui/icons-material";

const headCells = [
	{
		id: "VENDOR_NAME",
		numeric: false,
		disablePadding: false,
		label: "Vendor",
	},
	{
		id: "PO_ID",
		numeric: false,
		disablePadding: false,
		label: "Purchase Order",
	},
	{
		id: "STATUS",
		numeric: false,
		disablePadding: false,
		label: "Status",
	},
	{
		id: "ORDR_DT",
		numeric: false,
		disablePadding: false,
		label: "Date Ordered",
	},

	{
		id: "DATE_RECEIVED",
		numeric: false,
		disablePadding: false,
		label: "Date Received",
	},

	{
		id: "QTY_RECIVED",
		numeric: false,
		disablePadding: false,
		label: "Qty",
	},
	{ id: "COST", numeric: false, disablePadding: false, label: "Cost" },
	{
		id: "Total Cost",
		numeric: false,
		disablePadding: false,
		label: "Total Cost",
	},
];

export default function ItemPurchaseHistory() {
	const [startDt, setStartDt] = useState("");
	const [endDt, setEndDt] = useState("");
	const [ndc, setNdc] = useState("");
	const [data, setData] = useState([]);
	const [loader, setLoader] = useState(true);
	const [orderBy, setOrderBy] = useState("DATE_RECEIVED");
	const [order, setOrder] = useState("asc");
	const [chartData, setChartData] = useState({});

	const handleSearch = (e) => {
		e.preventDefault();
		setLoader(false);

		if (startDt > endDt) {
			alert("From date can not be less than To date");
			return;
		}
		getReportData()
			.then((resp) => {
				console.log("Resp: ", resp);
				setLoader(true);
				setData(resp);
				setChartData(buidChartData(resp));
			})
			.catch((err) => {
				alert(`Problem with data: ${err}`);
			});
	};

	const getReportData = () => {
		const params = `${ndc}'%2C'${startDt}'%2C'${endDt}`;
		const url = `https://c99jtoge7d.execute-api.us-east-2.amazonaws.com/dev/rx?procName=GET_ITEM_HIST_BY_DATE_RANGE_NDC&params=${params}`;
		const request = axios({
			method: "GET",
			url,
		});

		return new Promise((resolve, reject) => {
			request
				.then((resp) => {
					if (resp.data.result.length === 0) {
						// alert("No order data is found!!!");
						reject("No order data is found!!!");
					} else {
						resolve(resp.data.result);
					}
				})
				.catch((err) => {
					reject(err);
				});
		});
	};

	const buidChartData = (dataArray) => {
		return dataArray.map((el) => ({
			DateRecived: el.DATE_RECEIVED,
			Quantity: el.QTY_RECIVED,
		}));
	};

	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === "asc";
		setOrder(isAsc ? "desc" : "asc");
		setOrderBy(property);
	};

	return (
		<div className="history-wrapper">
			<form onSubmit={handleSearch} className="search-form">
				<div className="search-title">
					<span className="subtitle">
						Please choose NDC and a date range to run this report
					</span>
				</div>
				<div className="srch-dtl">
					<div className="srch-box">
						<div className="srchItem">
							<label htmlFor="ndc" className="srch_date_lbl">
								Search NDC:
							</label>
							<InputMask
								mask="****9-9999-99"
								value={ndc}
								onChange={(e) => setNdc(e.target.value)}
							>
								<input type="text" id="ndc" className="srch-ndc" autoFocus />
							</InputMask>
						</div>
						<div className="srchItem">
							<label htmlFor="dt-from" className="srch_date_lbl">
								From date:
							</label>
							<input
								type="date"
								id="dt-from"
								className="date-in"
								value={startDt}
								onChange={(e) => setStartDt(e.target.value)}
							/>
						</div>
						<div className="srchItem">
							<label htmlFor="dt-to" className="srch_date_lbl">
								To date:
							</label>
							<input
								type="date"
								className="date-in"
								id="dt-to"
								value={endDt}
								onChange={(e) => setEndDt(e.target.value)}
							/>
						</div>
					</div>
					<div className="search-box">
						<button className="srchBtn" type="submit">
							<SearchOutlined className="searchIcon" /> Search
						</button>
					</div>
				</div>
			</form>
			{data[0] ? (
				<div className="table-wrapper">
					<p className="subtitle">
						Purchase history for: {data[0].NDC} -- {data[0].GENERIC}
					</p>
					<Table className="table">
						<EnhancedTableHead
							checkbox={true}
							headCells={headCells}
							order={order}
							orderBy={orderBy}
							onRequestSort={handleRequestSort}
							className="headerRow"
						/>
						<TableBody>
							{stableSort(data, getComparator(order, orderBy)).map(
								(row, index) => {
									const labelId = `enhanced-table-checkbox-${index}`;

									return (
										<TableRow
											hover
											role="checkbox"
											tabIndex={-1}
											key={row.ORDR_ID}
										>
											<TableCell padding="checkbox"></TableCell>

											<TableCell align="left" className="cell">
												{row.VENDOR_NAME}
											</TableCell>
											<TableCell align="left" className="cell">
												{row.PO_ID}
											</TableCell>
											<TableCell align="left" className="cell">
												{row.STATUS}
											</TableCell>
											<TableCell align="left" className="cell">
												{row.ORDR_DT}
											</TableCell>

											<TableCell align="left" className="cell">
												{row.DATE_RECEIVED}
											</TableCell>

											<TableCell align="left" className="cell">
												{row.QTY_RECIVED}
											</TableCell>
											<TableCell align="left" className="cell">
												{ccyFormat(row.COST)}
											</TableCell>
											<TableCell align="left" className="cell">
												{ccyFormat(row.COST * row.QTY_RECIVED)}
											</TableCell>
										</TableRow>
									);
								}
							)}
							{data[0] ? <GrandTotal total={data} /> : null}
						</TableBody>
					</Table>
					<div className="charts">
						<BarChrt
							data={chartData}
							dataKeyX="DateRecived"
							dataKeyY="Quantity"
							aspect={4 / 1}
							title="Order History"
						/>
					</div>
				</div>
			) : null}
		</div>
	);
}
