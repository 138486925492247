import { useState, useEffect } from "react";
import axios from "axios";
import {
	stableSort,
	getComparator,
	EnhancedTableHead,
} from "../table-sort/TableSort.component";
import "./reciveInv-styles.scss"; //Style sheet
import TablePaginationActions from "../table-paginatoin/TablePagination.component";
import ReceiveItemRow from "./ReceiveItemRow.component";
//Material Ui
import {
	Table,
	TableBody,
	TableRow,
	TableFooter,
	TablePagination,
} from "@mui/material";

const headCells = [
	{ id: "NDC", numeric: false, disablePadding: false, label: "NDC" },
	{
		id: "GENERIC",
		numeric: false,
		disablePadding: false,
		label: "Generic Name",
	},
	{ id: "QTY", numeric: false, disablePadding: false, label: "Ordered" },
	{
		id: "RCV QTY",
		numeric: false,
		disablePadding: false,
		label: "Received",
	},
	{ id: "LOT", numeric: false, disablePadding: false, label: "Lot" },
	{
		id: "EXP_DATE",
		numeric: false,
		disablePadding: false,
		label: "Exparation Date",
	},
	{
		id: "VENDOR_NAME",
		numeric: false,
		disablePadding: false,
		label: "Vendor",
	},
	{
		id: "INVOICE",
		numeric: false,
		disablePadding: false,
		label: "Invoice",
	},
	{
		id: "ACTION",
		numeric: false,
		disablePadding: false,
		label: "SAVE",
	},
];

export default function ReceiveInventory() {
	const [data, setData] = useState([]);
	const [order, setOrder] = useState("asc");
	const [orderBy, setOrderBy] = useState("GENERIC");
	const [updt, setUpdt] = useState(0);
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(-1);

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === "asc";
		setOrder(isAsc ? "desc" : "asc");
		setOrderBy(property);
	};

	useEffect(() => {
		const url = `https://c99jtoge7d.execute-api.us-east-2.amazonaws.com/dev?procName=GET_ORDERED_ITEMS&params=1`;
		const request = axios({
			method: "GET",
			url,
		});
		request
			.then((res) => {
				if (!res) {
					alert("No order data found!!!");
				} else {
					setData(res.data.result);
				}
			})
			.catch((err) => {
				console.error(err);
			});
	}, [updt]);

	return (
		<div className="rec-ivnentory-wrapper">
			<Table className="table">
				<EnhancedTableHead
					checkbox={false}
					headCells={headCells}
					order={order}
					orderBy={orderBy}
					onRequestSort={handleRequestSort}
					// className="headerRow"
				/>
				<TableBody>
					{stableSort(data, getComparator(order, orderBy))
						.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
						.map((row, index) => {
							const labelId = `enhanced-table-checkbox-${index}`;

							return (
								<ReceiveItemRow
									key={row.ORDR_ID}
									row={row}
									labelId={labelId}
									updt={updt}
									setUpdt={setUpdt}
								/>
							);
						})}
				</TableBody>

				<TableFooter style={{ width: "100%" }}>
					<TableRow className="pagination">
						<TablePagination
							rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
							colSpan={3}
							count={data.length}
							rowsPerPage={rowsPerPage}
							page={page}
							SelectProps={{
								inputProps: {
									"aria-label": "rows per page",
								},
								native: true,
							}}
							onPageChange={handleChangePage}
							onRowsPerPageChange={handleChangeRowsPerPage}
							ActionsComponent={TablePaginationActions}
						/>
					</TableRow>
				</TableFooter>
			</Table>
		</div>
	);
}
