import { useEffect, useState } from "react";
import axios from "axios";
import "./batch-method-styles.scss";
//Material UI
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";

export default function BatchMethodTable(params) {
	const { formula } = params;
	const [methods, setMethods] = useState([]);

	useEffect(() => {
		let procName = "GET_FORMULA_METHODS";
		let params = formula.ID;
		const url = `https://c99jtoge7d.execute-api.us-east-2.amazonaws.com/dev/formula?procName=${procName}&params=${params}`;
		axios
			.get(url)
			.then((resp) => {
				setMethods(resp.data.result);
			})
			.catch((err) => {
				alert(`Severe error ${procName}; ${err}`);
			});
	}, [formula.ID]);

	return (
		<div className="batchMethod-wrapper">
			<div className="subtitle">
				<span> Batch Methods:</span>
			</div>

			<Table className="methodTbl" aria-label="formula method">
				<TableHead>
					<TableRow className="methodHdr">
						<TableCell className="methodHdrCell">Seq</TableCell>
						<TableCell className="methodHdrCell">Step</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{methods.map((method, index) => (
						<TableRow key={index}>
							<TableCell className="methodCell">{method.STEP}</TableCell>
							<TableCell className="methodCell">{method.DESCR}</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
		</div>
	);
}
