import { BrowserRouter, Routes, Route } from "react-router-dom";
import ProtectedRoute from "./ProtectedRoute";
import { useSelector } from "react-redux";
import SinglePage from "../pages/single/SinglePage";
import Page404 from "../pages/page404/Page404";
import LoginPage from "../pages/login/LoginPage";
import ForgotPassword from "../components/forgot-password/ForgotPassword.component";
import HomePage from "../pages/home/HomePage.jsx";
import SecurityHome from "../components/security/SecurityHome.component";
import ListUsers from "../components/list-users/ListUsers.component";
import UserForm from "../components/user-form/UserForm.component";
import UserDetail from "../components/user-details/UserDetail.component";
import UserChangePassword from "../components/user-change-password/UserChangePassword.component";
import ListRoles from "../components/user-roles/ListRoles.component";
import CreateRole from "../components/user-role-new/CreateRole.component";
import SystemResources from "../components/sustem-resources/SystemResources.component";
import CreateSystemResource from "../components/create-resource/CreateSystemResource.component";
import EditRole from "../components/user-role-edit/EditRole.component";
import ImsHome from "../components/ims-home/ImsHome.component";
import InventoryItemsList from "../components/inventory-items/InventoryItemsList.component";
import AddInventoryItem from "../components/add-inventory-item/AddInventoryItem.component";
import EditInventoryItem from "../components/edit-invetory-item/EditInventoryItem.component";
import VendorList from "../components/list-vendors/VendorList.component";
import AddVendor from "../components/add-vendor/AddVendor.component";
import EditVendor from "../components/edit-vendor/EditVendor.component";
import OnHandInventory from "../components/onhand-inventory/OnHandInventory.component";
import AdjustInventory from "../components/adjust-inventory/AdjustInventory.component";
import PoHistory from "../components/po-history/PoHistory.component";
import EditItemOrder from "../components/edit-item-order/EditItemOrder.component";
import ItemPurchaseHistory from "../components/purchase-history/ItemPurchaseHistory.component";
import VendorOrderHistory from "../components/vendor-history/VendorOrderHistory.component";
import Requisitionlist from "../components/requisition-list/RequisitionList.component";
import AddOrderItem from "../components/add-order-item/AddOrderItem.component";
import AddPo from "../components/add-po/AddPo.component";
import AddItemsToPo from "../components/add-po-items/AddItemsToPo.component";
import ReceiveInventory from "../components/recive-inventory/ReceiveInventory.component";
import LabHome from "../components/lab-home/LabHome.component";
import CreateMasterFormula from "../components/master-formula/CreateMasterFormula.component";
import ListFormula from "../components/list-formulas/ListFormula.components";
import EditFormulala from "../components/edit-formula/EditFormula.component";
import CreateBatch from "../components/create-batch/CreateBatch.component";
import CompoundingQueue from "../components/compounding-queue/CompoundingQueue.component";
import CompoundBatch from "../components/compound-batch/CompoundBatch.component";
import MillBatch from "../components/batch-milling-queue/MillBatch.component";
import PackBatchQueue from "../components/batch-pack-queue/PackBatchQueue.component";
import PackBatch from "../components/batch-pack/PackBatch.component";
import BatchLabelList from "../components/batch-label-list/BatchLabelList.component";
import BatchRxLabel from "../components/batch-label-rx/BatchRxLabel.component";
import BatchCompleteQueue from "../components/batch-complete-queue/BatchCompleteQueue.compoent";
import CompleteBatch from "../components/batch-complete/CompleteBatch.component";
import BatchReportByDt from "../components/batch-report-bydt/BatchReportByDt.component";
import BatchInventory from "../components/batch-inventory/BatchInventory.component";

export default function AppRouter() {
	const user = useSelector((state) => state.user.user);
	return (
		<>
			<BrowserRouter>
				<Routes>
					<Route index element={<LoginPage />} />
					<Route path="login" element={<LoginPage />} />
					<Route path="forgot-password" element={<ForgotPassword />} />
					<Route element={<ProtectedRoute user={user} />}>
						<Route path="home" element={<HomePage source="HOME" />} />
						<Route
							path="edit/user-password"
							element={
								<SinglePage title={"Change password"} source="SEC">
									<UserChangePassword />
								</SinglePage>
							}
						/>
						<Route path="users">
							<Route
								index
								element={
									<SinglePage title={"Setup users and roles"} source="SEC">
										<SecurityHome />
									</SinglePage>
								}
							/>
							<Route
								path="list"
								element={
									<SinglePage title={"List all users"} source="SEC">
										<ListUsers />
									</SinglePage>
								}
							/>
							<Route
								path="edit/:userid"
								element={
									<SinglePage title={"User"} source="SEC">
										<UserDetail />
									</SinglePage>
								}
							/>

							<Route
								path="new"
								element={
									<SinglePage title={"Add new user"} source="SEC">
										<UserForm />
									</SinglePage>
								}
							/>
							<Route
								path="roles"
								element={
									<SinglePage title={"List user roles"} source="SEC">
										<ListRoles />
									</SinglePage>
								}
							/>
							<Route
								path="roles/new"
								element={
									<SinglePage title={"Create new role"} source="SEC">
										<CreateRole />
									</SinglePage>
								}
							/>
							<Route
								path="roles/edit/:id"
								element={
									<SinglePage title={"Edit role"} source="SEC">
										<EditRole />
									</SinglePage>
								}
							/>
							<Route
								path="system"
								element={
									<SinglePage title={"Controled system resources"} source="SEC">
										<SystemResources />
									</SinglePage>
								}
							/>
							<Route
								path="system/new"
								element={
									<SinglePage title={"Create system resources"} source="SEC">
										<CreateSystemResource />
									</SinglePage>
								}
							/>
						</Route>
						<Route path="ims">
							<Route
								index
								element={
									<SinglePage title={"Manage Inventory"} source="IMS">
										<ImsHome />
									</SinglePage>
								}
							/>
							<Route
								path="add-req"
								element={
									<SinglePage title={"Requisition list"} source="IMS">
										<Requisitionlist />
									</SinglePage>
								}
							/>
							<Route
								path="add-req-item"
								element={
									<SinglePage title={"Add order item "} source="IMS">
										<AddOrderItem />
									</SinglePage>
								}
							/>
							<Route
								path="add-items-po"
								element={
									<SinglePage title={"Add items to PO"} source="IMS">
										<AddItemsToPo />
									</SinglePage>
								}
							/>
							<Route
								path="add-po"
								element={
									<SinglePage title={"Create purchase order"} source="IMS">
										<AddPo />
									</SinglePage>
								}
							/>
							<Route
								path="rec-inventory"
								element={
									<SinglePage title={"Receive inventory"} source="IMS">
										<ReceiveInventory />
									</SinglePage>
								}
							/>

							<Route
								path="items"
								element={
									<SinglePage title={"Inventory Items"} source="IMS">
										<InventoryItemsList />
									</SinglePage>
								}
							/>
							<Route
								path="items/new"
								element={
									<SinglePage title={"Add Inventory Item"} source="IMS">
										<AddInventoryItem />
									</SinglePage>
								}
							/>
							<Route
								path="items/edit/:id"
								element={
									<SinglePage title={"Edit Inventory Item"} source="IMS">
										<EditInventoryItem />
									</SinglePage>
								}
							/>
							<Route
								path="vendors"
								element={
									<SinglePage title={"Vendors"} source="IMS">
										<VendorList />
									</SinglePage>
								}
							/>
							<Route
								path="vendor/new"
								element={
									<SinglePage title={"Add vendor"} source="IMS">
										<AddVendor />
									</SinglePage>
								}
							/>
							<Route
								path="vendor/edit/:id"
								element={
									<SinglePage title={"Edit vendor"} source="IMS">
										<EditVendor />
									</SinglePage>
								}
							/>
							<Route
								path="items/qty"
								element={
									<SinglePage title={"On-hand inventory"} source="IMS">
										<OnHandInventory />
									</SinglePage>
								}
							/>
							<Route
								path="items/qty/edit/:id"
								element={
									<SinglePage title={"Adjust inventory"} source="IMS">
										<AdjustInventory />
									</SinglePage>
								}
							/>
							<Route
								path="po-history"
								element={
									<SinglePage title={"Purchse History"} source="IMS">
										<PoHistory />
									</SinglePage>
								}
							/>
							<Route
								path="edit-history/:id"
								element={
									<SinglePage title={"Adjust Item Order History"} source="IMS">
										<EditItemOrder />
									</SinglePage>
								}
							/>
							<Route
								path="item-history"
								element={
									<SinglePage title={"Purchse history"} source="IMS">
										<ItemPurchaseHistory />
									</SinglePage>
								}
							/>
							<Route
								path="vendor-history"
								element={
									<SinglePage title={"Vendor order history"} source="IMS">
										<VendorOrderHistory />
									</SinglePage>
								}
							/>
						</Route>
						<Route path="lab">
							<Route
								index
								element={
									<SinglePage title={"Lab dashboard"} source="LAB">
										<LabHome />
									</SinglePage>
								}
							/>
							<Route
								path="add-batch"
								element={
									<SinglePage title={"Create Batch"} source="LAB">
										<CreateBatch />
									</SinglePage>
								}
							/>
							<Route
								path="compound-queue"
								element={
									<SinglePage title={"Compounding queue"} source="LAB">
										<CompoundingQueue />
									</SinglePage>
								}
							/>
							<Route
								path="compound-batch/:id"
								element={
									<SinglePage title={"Compound batch"} source="LAB">
										<CompoundBatch />
									</SinglePage>
								}
							/>
							<Route
								path="mill-batch"
								element={
									<SinglePage title={"Mill batch"} source="LAB">
										<MillBatch />
									</SinglePage>
								}
							/>
							<Route
								path="batch-pack"
								element={
									<SinglePage title={"Pack batch queue"} source="LAB">
										<PackBatchQueue />
									</SinglePage>
								}
							/>
							<Route
								path="pack-batch/:id"
								element={
									<SinglePage title={"Pack batch"} source="LAB">
										<PackBatch />
									</SinglePage>
								}
							/>
							<Route
								path="batch-label"
								element={
									<SinglePage title={"Label batch"} source="LAB">
										<BatchLabelList />
									</SinglePage>
								}
							/>
							<Route
								path="label-batch/:id"
								element={
									<SinglePage title={"Label batch"} source="LAB">
										<BatchRxLabel />
									</SinglePage>
								}
							/>
							<Route
								path="batch-complete"
								element={
									<SinglePage title={"Complete batch queue"} source="LAB">
										<BatchCompleteQueue />
									</SinglePage>
								}
							/>
							<Route
								path="batch-cmplt/:id"
								element={
									<SinglePage title={"Complete and certify batch"} source="LAB">
										<CompleteBatch />
									</SinglePage>
								}
							/>
							<Route
								path="batch-inventory"
								element={
									<SinglePage title={"Batch Inventory"} source="LAB">
										<BatchInventory />
									</SinglePage>
								}
							/>
							<Route
								path="add-formula"
								element={
									<SinglePage title={"Create Formula"} source="LAB">
										<CreateMasterFormula />
									</SinglePage>
								}
							/>
							<Route
								path="edit-formula"
								element={
									<SinglePage title={"Deactivate formula"} source="LAB">
										<EditFormulala />
									</SinglePage>
								}
							/>
							<Route
								path="list-formula"
								element={
									<SinglePage title={"List all formulas"} source="LAB">
										<ListFormula />
									</SinglePage>
								}
							/>
							<Route
								path="batch-rptdate"
								element={
									<SinglePage title={"Daily batch report"} source="LAB">
										<BatchReportByDt />
									</SinglePage>
								}
							/>
						</Route>
					</Route>

					<Route path="*" element={<Page404 />} />
				</Routes>
			</BrowserRouter>
		</>
	);
}
