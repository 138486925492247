import React from "react";
import ReactDOM from "react-dom";
// import App from "./App";
import AppRouter from "./routes/AppRouter";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "./store/store";
// import { IdleTimerContainer } from "../src/components/idle-time/IdleTimerContainer";
// MUI Date picker

ReactDOM.render(
	// <React.StrictMode>

	<Provider store={store}>
		<PersistGate persistor={persistor}>
			{/* <IdleTimerContainer> */}
			<AppRouter />
			{/* </IdleTimerContainer> */}
		</PersistGate>
	</Provider>,

	// </React.StrictMode>,
	document.getElementById("root")
);
