import { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import "./requisition-list-styles.scss"; //Style List
import {
	stableSort,
	getComparator,
	EnhancedTableHead,
	EnhancedTableToolbar,
} from "../table-sort/TableSort.component";
import TablePaginationActions from "../table-paginatoin/TablePagination.component";
import DialogComponent from "../dialog/DialogComponent";

//Material Ui
import {
	Table,
	TableBody,
	TableRow,
	TableCell,
	TableFooter,
	TablePagination,
	Checkbox,
} from "@mui/material";

import { Add } from "@mui/icons-material";

const headCells = [
	{ id: "NDC", numeric: false, disablePadding: false, label: "NDC" },
	{
		id: "GENERIC",
		numeric: false,
		disablePadding: false,
		label: "Generic Name",
	},
	{
		id: "PACKAGE_SIZE",
		numeric: true,
		disablePadding: false,
		label: "Package Size",
	},
	{ id: "QTY", numeric: true, disablePadding: false, label: "On Order" },

	{
		id: "STATUS",
		numeric: false,
		disablePadding: false,
		label: "Status",
	},
	{ id: "ORDR_DT", numeric: false, disablePadding: false, label: "Order Date" },
];

export default function Requisitionlist() {
	const [data, setData] = useState([]);
	const [open, setOpen] = useState(false);
	const [deleteSw, setDeleteSw] = useState(false);
	const dlgTitle = "Delete Order Item";
	const [order, setOrder] = useState("asc");
	const [orderBy, setOrderBy] = useState("GENERIC");
	const [selected, setSelected] = useState([]);
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === "asc";
		setOrder(isAsc ? "desc" : "asc");
		setOrderBy(property);
	};

	const handleClick = (event, name, status) => {
		if (status === "REQUEST") {
			const selectedIndex = selected.indexOf(name);
			let newSelected = [];

			if (selectedIndex === -1) {
				newSelected = newSelected.concat(selected, name);
			} else if (selectedIndex === 0) {
				newSelected = newSelected.concat(selected.slice(1));
			} else if (selectedIndex === selected.length - 1) {
				newSelected = newSelected.concat(selected.slice(0, -1));
			} else if (selectedIndex > 0) {
				newSelected = newSelected.concat(
					selected.slice(0, selectedIndex),
					selected.slice(selectedIndex + 1)
				);
			}

			setSelected(newSelected);
		}
	};

	const isSelected = (id) => selected.indexOf(id) !== -1;

	const handleDelete = (e) => {
		e.preventDefault();
		setOpen(true);
	};

	const deleteItem = (e) => {
		e.preventDefault();

		setOpen(false);
		selected.map((d) => {
			deleteItemDB(d)
				.then((resp) => {
					setDeleteSw(true);
					alert(`Order Item was ${d} deleted successfully`);
				})
				.catch((err) => {
					alert(`Error! During DB delete ${err} `);
				});
		});
	};
	const deleteItemDB = (id) => {
		const url =
			"https://c99jtoge7d.execute-api.us-east-2.amazonaws.com/dev/item";
		let procName = "DELETE_ORDER_ITEM";
		let params = [id];
		var ItemData = {
			procName,
			params,
		};

		return new Promise((resolve, reject) => {
			const request = axios({
				method: "POST",
				url,
				data: ItemData,
			});
			request
				.then((res) => {
					resolve(res);
				})
				.catch((err) => {
					reject(err);
				});
		});
	};

	useEffect(() => {
		const url = `https://c99jtoge7d.execute-api.us-east-2.amazonaws.com/dev?procName=GET_REQUESTED_ITEMS&params=1`;
		const request = axios({
			method: "GET",
			url,
		});
		request
			.then((res) => {
				if (!res) {
					alert("No user data found!!!");
				} else {
					setData(res.data.result);
				}
			})
			.catch((err) => {
				console.error(err);
			});
	}, [deleteSw]);

	return (
		<div className="table-wrapper">
			<div className="table-title">
				<Link to="../add-req-item" className="link">
					<Add /> Add New
				</Link>
			</div>
			<EnhancedTableToolbar
				numSelected={selected.length}
				title="Requesitions"
				handleDelete={handleDelete}
			/>
			<Table className="req-table">
				<EnhancedTableHead
					checkbox={true}
					headCells={headCells}
					order={order}
					orderBy={orderBy}
					onRequestSort={handleRequestSort}
				/>
				<TableBody className="tableBody">
					{stableSort(data, getComparator(order, orderBy))
						.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
						.map((row, index) => {
							const isItemSelected = isSelected(row.ORDR_ID);
							const labelId = `enhanced-table-checkbox-${index}`;

							let color = "#2a4e9d";
							let edit = false;
							if (row.STATUS === "ORDERED") {
								color = "rgba(8, 116, 17, 0.83)";
								edit = true;
							} else {
								if (row.STATUS === "ERROR") {
									color = "red";
									edit = true;
								}
							}

							return (
								<TableRow
									hover
									onClick={(event) =>
										handleClick(event, row.ORDR_ID, row.STATUS)
									}
									role="checkbox"
									aria-checked={isItemSelected}
									tabIndex={-1}
									key={row.ORDR_ID}
									selected={isItemSelected}
									className="tableRow"
								>
									<TableCell padding="checkbox">
										<Checkbox
											checked={isItemSelected}
											inputProps={{ "aria-labelledby": labelId }}
											disabled={edit}
										/>
									</TableCell>

									<TableCell
										component="th"
										id={labelId}
										scope="row"
										padding="none"
										variant="body"
										align="center"
										className="ndc"
										style={{ color: color, fontWeight: 700 }}
									>
										{row.NDC}
									</TableCell>
									<TableCell
										align="left"
										padding="none"
										variant="body"
										style={{
											color: color,
											fontWeight: 700,
											textTransform: "uppercase",
										}}
									>
										{row.GENERIC}
									</TableCell>
									<TableCell
										align="center"
										padding="none"
										variant="body"
										style={{ color: color, fontWeight: 700 }}
									>
										{row.PACKAGE_SIZE} {row.UNIT_OF_MESURE}
									</TableCell>
									<TableCell
										align="center"
										padding="none"
										variant="body"
										style={{ color: color, fontWeight: 700 }}
									>
										{row.QTY}
									</TableCell>

									<TableCell
										align="center"
										padding="none"
										variant="body"
										style={{ color: color, fontWeight: 700 }}
									>
										{row.STATUS}
									</TableCell>
									<TableCell
										align="center"
										padding="none"
										variant="body"
										style={{ color: color, fontWeight: 700 }}
									>
										{row.ORDR_DT}
									</TableCell>
								</TableRow>
							);
						})}
				</TableBody>
				<TableFooter className="table-footer">
					<TableRow className="pagination">
						<TablePagination
							rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
							colSpan={3}
							count={data.length}
							rowsPerPage={rowsPerPage}
							page={page}
							SelectProps={{
								inputProps: {
									"aria-label": "rows per page",
								},
								native: true,
							}}
							onPageChange={handleChangePage}
							onRowsPerPageChange={handleChangeRowsPerPage}
							ActionsComponent={TablePaginationActions}
						/>
					</TableRow>
				</TableFooter>
			</Table>
			<DialogComponent
				open={open}
				setOpen={setOpen}
				dlgTitle={dlgTitle}
				dlgAction={"Delete"}
				id={selected}
				handleClickOk={deleteItem}
			/>
		</div>
	);
}
