import ItemActionTypes from "./item.action.types";
import axios from "axios";

export const fetchItemStart = () => ({
  type: ItemActionTypes.FETCH_ITEM_START,
});

export const fetchItemSuccess = (item) => ({
  type: ItemActionTypes.FETCH_ITEM_SUCCESS,
  payload: item,
});

export const deleteItem = (item) => ({
  type: "DELETE_ITEM",
  payload: item,
});

export const addItem = (item) => ({
  type: "ADD_ITEM",
  payload: item,
});

export const saveItem = async (params) => {
  const url = "https://c99jtoge7d.execute-api.us-east-2.amazonaws.com/dev/item";

  const procName = "ADD_INVENTRORY_ITEM";
  var itemData = {
    procName,
    params,
  };

  return (dispatch) => {
    const request = axios({
      method: "POST",
      url,
      data: itemData,
    });
    dispatch(fetchItemStart());
    request
      .then((res) => {
        dispatch(fetchItemSuccess(res.data[0]));
      })
      .catch((err) => {
        dispatch({
          type: ItemActionTypes.FETCH_ITEM_FAILURE,
          payload: err.response.data.error,
        });
      });
  };
};

export const ItemError = (error) => ({
  type: "ITEM_ERROR",
  payload: error,
});
